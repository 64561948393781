import { getEvents } from "@/views/wedding-provider/apis";

export const state = () => ({
    isLoading: false,
    errors: {},
    errorMessage: null,
    events : null,
    eventListe: [],
    eventSelect:null
})

export const actions = {
    async getAllEvents({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getEvents(params);
            console.log('store',response)
            commit('setEvents', response);
            response.forEach(element => {
                if(element.select==true){
                    commit('setEventSelect', element)
                    console.log('eventSelect ',element)
                }
                // console.log('store',element)
            });
            // console.log('eventSelect ',response.eventSelect)
            commit('setLoading', false);
        } catch (error) {
            commit('setLoading', false);
            commit('setEvents', []);
            commit('setErrors', error.errors);
            throw error.errors
        }
    },

}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setErrorMessage(state, value) {
        state.errorMessage = value
    },
    setEvents(state, value) {
        state.eventListe = value
    },setEventSelect(state, value) {
        state.eventSelect = value
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}