<template>
    <div v-if="backgroundStyle" style="width:300px;">
        <a :href="link" target="_blank">
            <div class="card">
                <div class="header" :style="backgroundStyle">
                    <small class="header-content">
                        <div v-html="title" ></div>
                    </small>
                </div>
            </div>
        </a>
    </div>
</template>

<style scoped>
    .header {
        position:relative;
        /* min-height: 150px; */
        /* background-image: linear-gradient(rgba(0, 0, 0, .550), rgba(0, 0, 0, .550)), url('https://placeimg.com/640/480/any?3'); */
        background-repeat:no-repeat;
        background-size:cover;
        width:100%;
        height:250px;
        padding-left:1px;
        padding-right:1px;
    }

    .header-content {
        position:absolute;
        bottom:0;
        left:0;
        color:#fff;
        font-size:15px;
        text-align:left;
        font-weight:500;
        padding:20px;
    }
</style>

<script>
    export default {
        name: 'BlogArticleItem',
        props: ['title', 'image', 'link'],
        computed: {
            backgroundStyle: function() {
                return "background-image:linear-gradient(rgba(0,0,0,.550), rgba(0,0,0,.550)),url(" + this.image + ");";
            }
        }
    }
</script>