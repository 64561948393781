<template>
  <div class="flex flex-col bg-white">
    <div class="flex">
      <div class="px-3 py-1">
        <price-item label="Prix Min." :value="provider.min_price"></price-item>
      </div>
      <div class="px-3 py-1">
        <price-item label="Prix Max." :value="provider.max_price"></price-item>
      </div>
    </div>
    <div v-html="provider.description">
    </div>
  </div>
</template>

<script>
import PriceItem from "@/views/wedding-provider/components/price-item";

export default {
  name: "description-component",
  components: {PriceItem},
  props: ['provider','description'],
}
</script>

<style scoped>

</style>