<template>
  <div>
    <div class="flex w-full">
      <banner-component
        @clear-filters-on-banner="onClearFilters"
        @town-filter-selected-on-banner="onTownFilterSelected"
        @service-type-filter-selected-on-banner="onServiceTypeFilterSelected"
        :is-display-search-inputs="true"
      ></banner-component>
    </div>
    <div class="container-fluid mx-auto px-4">
      <div class="flex justify-between items-center pt-8 border-b pb-4">
        <div class="flex">{{ providers.total }} Prestataires inscrits</div>
        <div class="hidden md:flex">
          <!-- <input type="text" class="form-control" v-model="searchTxt" @keypress="onSearchSubmit(searchTxt)" @blur="onSearchSubmit(searchTxt)" name="" placeholder="Trouvez une boutique" id=""> -->
          <!-- <i class="fa fa-search"></i> -->
          <form role="search" @submit.prevent="onSearchSubmit(searchTxt)">
            <!-- <label for="search">Search for stuff</label> -->
            <input
              id="search"
              type="search"
              placeholder="    Trouver une boutique ici..."
              v-model="searchTxt"
              @keyup.enter="onSearchSubmit(searchTxt)"
              required
            />
            <button type="submit" style="color: #b71c1c">
              Rechercher <i class="fa fa-search"></i>
            </button>
          </form>
          <!-- <div class="flexbox">
            <div class="search">
              <div>
                <input type="text"  placeholder="Trouvez une boutique. . ." required>
              </div>
            </div>
          </div> -->
          <pill-component
            icon_color_class="text-red-800"
            bg_color_class="bg-white border-gray-200 border"
            icon="fa-solid fa-th"
            :action="() => setIsList(false)"
            text="Mosaique"
          ></pill-component
          >&nbsp;
          <pill-component
            icon_color_class="text-red-800"
            bg_color_class="bg-white border-gray-200 border"
            icon="fa-solid fa-list"
            :action="() => setIsList(true)"
            text="Liste"
          ></pill-component>
        </div>
        <div class="flex md:hidden">
          <pill-component
            icon_color_class="text-red-800"
            bg_color_class="bg-white border-gray-200 border"
            icon="fa-solid fa-th"
            :action="onMobileFiltersPanelClick"
            text="Filtres"
          ></pill-component
          >&nbsp;
        </div>
      </div>
      <div class="flex flex-row w-full">
        <div class="hidden md:flex md:w-1/3 lg:w-1/5">
          <filter-list-component
            :currentServiceTypeId="currentServiceTypeIdSelected"
            :currentTownId="currentTownId"
            @search-submit="onSearchSubmit"
            @town-filter-selected-on-list="onTownFilterSelected"
            @service-type-filter-selected-on-list="onServiceTypeFilterSelected"
            @price-filter-selected-on-list="onPriceFilterSelected"
            @review-filter-selected-on-list="onReviewFilterSelected"
          />
        </div>
        <div class="flex flex-col w-full pt-5 h-fit md:2/3 lg:w-4/5 pl-2">
          <div class="flex w-full md:hidden" v-if="isDisplayMobileFilters">
            <filter-list-component
              :isOpen="false"
              :currentServiceTypeId="currentServiceTypeIdSelected"
              :currentTownId="currentTownId"
              @search-submit="onSearchSubmit"
              @town-filter-selected-on-list="onTownFilterSelected"
              @service-type-filter-selected-on-list="
                onServiceTypeFilterSelected
              "
              @price-filter-selected-on-list="onPriceFilterSelected"
              @review-filter-selected-on-list="onReviewFilterSelected"
            />
          </div>
          <div class="md:hidden">
            <form role="search" @submit.prevent="onSearchSubmit(searchTxt)">
              <input
                id="search"
                type="search"
                v-model="searchTxt"
                @keyup.enter="onSearchSubmit(searchTxt)"
                placeholder="   Trouver une boutique ici..."
                required
              />
              <button type="submit">
                Rechercher <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
          <div class="pt-1 pb-2" v-if="!isLoading">
            <card-list-component
              v-if="providers.data && providers.data.length > 0"
              @on-favorite-toggle="onFavorite"
              :data="providers.data"
              :is-list="isList"
              :addbudget="budgetId"
            ></card-list-component>
          </div>
          <div class="flex justify-center items-center" v-else>
            Chargement des données ... <moonloader :size="'35px'" />
          </div>
          <div
            class="flex justify-center"
            v-if="providers.data && providers.data.length === 0"
          >
            Aucun prestataire associé à cette entrée...
          </div>
          <div class="py-2">
            <pagination-component
              v-if="pagination && providers.data && providers.data.length > 0"
              :pagination="pagination"
              @paginate="onChangePage"
            ></pagination-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";

import FilterListComponent from "@/views/wedding-provider/components/filter/filter-list";
import PillComponent from "@/views/wedding-provider/components/pill/pill";
import { PROVIDER_LIST } from "@/views/wedding-provider/mocks/providers";
import { scrollToTop } from "@/views/wedding-provider/utils";
import { useMeta } from "vue-meta";
import { mapActions, mapState } from "vuex";
export default {
  name: "provider-index-new",
  setup() {
    useMeta({
      title:
        "Jours de Joie - Prestataire de services - Mariage a Abidjan- prestataire de mariage",
      titleTemplate: "Robe de mariage, costume de mariage, lieu d'évènement,",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "keywords",
          content:
            "bride, business, couple, directory, groom, listing, login, map, marketing, realwedding, registration, rsvp, vendor, wedding, wedding planner,Agence d’organisation de mariage en Côte d’Ivoire, wedding planner à abidjan,robe de mariage, event planner a abidjan,gateau de mariage,coiffure de mariage, prestation de mariage, prestataire de mariage,mariage a abidjan",
        },
        {
          name: "description",
          content:
            " Vous organisez un mariage ? vous recherchez un traiteur , une robe ou un costume de mariage ou une location de salle ? Trouvez tous les prestataires de mariage à Abidjan et dans toute la côte d'ivoire joursdejoie.com,Agence d’organisation de mariage en Côte d’Ivoire, Organisation de Mariage à Abidjan, event planner, wedding planner,prestatation de mariage et prestataire de mariage à abidjan",
        },
      ],
    });
  },

  components: { FilterListComponent, PillComponent },
  layout: "default",
  data() {
    return {
      cards: PROVIDER_LIST,
      isList: false,
      currentPage: 1,
      searchTxt: "",
      user:localStorage.getItem("user"),
      isDisplayMobileFilters: false,
      currentTownId: null,
      currentServiceTypeId: null,
      budgetId: this.$route.params.budgetId,
      filters: {
        min_price: 0,
        max_price: 0,
        mark: 0,
      },
      favList: [],
    };
  },
  computed: {
    ...mapState("providers", {
      isLoading: "isLoading",
      providers: "providers",
      currentServiceTypeIdSelected: "currentServiceTypeIdSelected",
      currentTownIdSelected: "currentTownIdSelected",
    }),
    ...mapState({
      isAuthLoading: "isLoading",
      user: "user",
    }),

    pagination() {
      if (!this.providers) {
        return null;
      }
      return {
        currentPage: this.providers.current_page,
        from: this.providers.from,
        to: this.providers.to,
        total: this.providers.total,
        lastPage: this.providers.last_page,
        perPage: this.providers.per_page,
      };
    },
  },
  async mounted() {
    let userconnect = JSON.parse(this.user);
    this.currentTownId = this.currentTownIdSelected;
    if (this.currentServiceTypeIdSelected) {
      this.currentServiceTypeId = this.currentServiceTypeIdSelected;
      var filters = {
        page: this.providers.current_page,
        service_type_id: this.currentServiceTypeIdSelected,
        town_id: this.currentTownIdSelected,
      };
    } else {
      this.currentServiceTypeId = this.$route.params.service_type_id;
      var filters = {
        page: this.providers.current_page,
        service_type_id: this.currentServiceTypeId,
        town_id: this.currentTownIdSelected,
      };
    }

    // const filters = {
    //   page: this.providers.current_page,
    //   service_type_id: this.currentServiceTypeIdSelected,
    //   town_id: this.currentTownIdSelected,
    // }

    await this.getProviders(filters);
    if (userconnect) {
      await this.getFavorites({
        customer_id: userconnect.customer.id,
        ids_only: 0,
      });
    }

    await this.getServiceTypes();

    await this.getTowns();

    // if(userconnect && !this.isAuthLoading) {

    // }
  },
  methods: {
    ...mapActions({
      getServiceTypes: "serviceTypes/getServiceTypes",
      getTowns: "towns/getTowns",
      getProviders: "providers/getProviders",
      getFavorites: "favorites/getFavorites",
      updateFavorite: "favorites/updateFavorite",
    }),
    setIsList(isList) {
      this.isList = isList;
    },
    editFilters(key, value) {
      this.filters[key] = value;
    },
    toggleDisplayFilters() {
      this.displayFilters = !this.displayFilters;
    },
    async onChangePage(page) {
      const filters = {
        page: page,
        service_type_id: this.currentServiceTypeId,
        town_id: this.currentTownId,
      };
      await this.getProviders(filters);
      scrollToTop(window);
    },
    async onSearchSubmit(search) {
      this.searchTxt = search;
      console.log(this.searchTxt);
      await this.getProviders({ search });
      scrollToTop(window);
    },
    async onTownFilterSelected(townId) {
      if (this.currentTownId === townId) {
        this.currentTownId = null;
        await this.getProviders();
      } else {
        this.currentTownId = townId;
        await this.getProviders({
          service_type_id: this.currentServiceTypeId,
          town_id: this.currentTownId,
          min_price: this.filters.min_price,
          max_price: this.filters.max_price,
          mark: this.filters.mark,
        });
      }
    },
    async onServiceTypeFilterSelected(serviceTypeId) {
      if (this.currentServiceTypeId === serviceTypeId) {
        this.currentServiceTypeId = null;

        console.log(
          this.currentServiceTypeId,
          this.currentServiceTypeIdSelected
        );
        await this.getProviders();
      } else {
        this.currentServiceTypeId = serviceTypeId;
        this.currentServiceTypeId = serviceTypeId;
        await this.getProviders({
          service_type_id: this.currentServiceTypeId,
          town_id: this.currentTownId,
          min_price: this.filters.min_price,
          max_price: this.filters.max_price,
          mark: this.filters.mark,
        });
      }
    },
    async onPriceFilterSelected(prices) {
      const hasAlreadySetPrices =
        {
          min_price: this.filters.min_price,
          max_price: this.filters.max_price,
        } === prices;
      if (hasAlreadySetPrices) {
        this.editFilters("min_price", 0);
        await this.getProviders();
      } else {
        this.editFilters("min_price", prices.min_price);
        this.editFilters("max_price", prices.max_price);
        await this.getProviders({
          service_type_id: this.currentServiceTypeId,
          town_id: this.currentTownId,
          min_price: this.filters.min_price,
          max_price: this.filters.max_price,
          mark: this.filters.mark,
        });
      }
    },
    async onReviewFilterSelected(mark) {
      const hasAlreadySetPrices = this.filters.mark === mark;
      if (hasAlreadySetPrices) {
        this.editFilters("mark", 0);
        await this.getProviders();
      } else {
        this.editFilters("mark", mark);
        await this.getProviders({
          service_type_id: this.currentServiceTypeId,
          town_id: this.currentTownId,
          min_price: this.filters.min_price,
          max_price: this.filters.max_price,
          mark: this.filters.mark,
        });
      }
    },
    onClearFilters(type) {
      if (type === "town") {
        this.currentTownId = null;
      } else if (type === "serviceType") {
        this.currentServiceTypeId = null;
      } else {
        this.currentTownId = null;
        this.currentServiceTypeId = null;
      }
      this.getProviders();
    },
    async onFavorite(providerId) {
      let userconnect = JSON.parse(this.user);
      await this.updateFavorite({
        favorite_ids: [providerId],
      });
      await this.getFavorites({
        customer_id: userconnect.customer.id,
        ids_only: 0,
      });
    },
    onMobileFiltersPanelClick() {
      this.isDisplayMobileFilters = !this.isDisplayMobileFilters;
    },
  },
};
</script>
<style scoped>
:root {
  --rad: 0.7rem;
  --dur: 0.3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #57bd84;
  --font-fam: "Lato", sans-serif;
  --height: 5rem;
  --btn-width: 15rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}

/* body {background: var(--color-dark); display: flex; align-items: center; justify-content: center; min-height: 100vh } */
/* html { box-sizing: border-box; height: 100%; font-size: 10px; } *, *::before, *::after { box-sizing: inherit; } */

form {
  position: relative;
  width: 20rem;
  background: rgb(243, 243, 243);
  border-radius: 15px;
}
input,
button {
  height: var(--height);
  font-family: var(--font-fam);
  border: 0;
  color: var(--color-dark);
  font-size: 14px;
}
input[type="search"] {
  outline: 0;
  width: 100%;
  background: var(--color-light);
  padding: 0.3rem;
  border-radius: var(--rad);
  appearance: none;
  transition: all var(--dur) var(--bez);
  transition-property: width, border-radius;
  z-index: 1;
  position: relative;
}
button {
  display: block;
  position: absolute;
  top: 20%;
  bottom: 50%;
  right: 3%;
  width: var(--btn-width);
  font-weight: bold;
  background: var(--color-brand);
  border-radius: 0 var(--rad) var(--rad) 0;
}
input:not(:placeholder-shown) {
  border-radius: var(--rad) 0 0 var(--rad);
  width: calc(100% - var(--btn-width));
  + button {
    display: block;
  }
}
label {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
</style>
