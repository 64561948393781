<template>
  <div class="">
    <BannerPackage @search-package="filterPackages" :is-display-search-inputs="true" />

    <!-- <PackagesList/> -->
    <div class="container row">
      <div class="col-md-3 mt-5">
        <FilterPackage @filter-group="filterPackages" />
        <!-- card.// -->
      </div>
      <div class="col-md-9">
        <ListPackage v-if="packages != null" :packages="packages.data" />

        <!-- <div class="row justify-content-center">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#">Previous</a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
        </div> -->
        <div class="mt-3">
          <pagination-component
              v-if="pagination && packages.data && packages.data.length > 0"
              :pagination="pagination"
              @paginate="paginationPackages"
            ></pagination-component>
        </div>

        <spinner v-if="packages == null" />
      </div>
    </div>

    <!-- <div class="container">
      <div class="row row-cols-4">
        <div class="col-md-4">Column</div>
        <div class="col-md-4">Column</div>
        <div class="col-md-4">Column</div>
        <div class="col-md-4">Column</div>
        <div class="col">Column</div>
        <div class="col">Column</div>
        <div class="col">Column</div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";
import BannerPackage from "../components/banner-package.vue";
import ListPackage from "../components/card-package/card-package-list.vue";
import FilterPackage from "../components/filter/filter-package.vue";

export default {
  data: function () {
    return {
      provider: {},
      star: 0,
      note: {
        wedding_provider_id: this.$route.params.id,
        recommendation_mark: 0,
        quality_price_ratio_mark: 0,
        professionalism_mark: 0,
      },
      packages: null,
      paginate_index: {
        per_page:"12",
        page:"1"
      },
      filterPackage:null
    };
  },
  mounted() {
    this.getPackages();
  },
  components: {
    BannerPackage,
    ListPackage,
    FilterPackage,
  },
  computed:{
    pagination() {
      if (!this.packages) {
        return null;
      }
      return {
        currentPage: this.packages.current_page,
        from: this.packages.from,
        to: this.packages.to,
        total: this.packages.total,
        lastPage: this.packages.last_page,
        perPage: this.packages.per_page,
      };
    }
  },
  methods: {
    // getPackages() {
    //   this.isLoading = true;
    //   let app = this;
    //   // console.log("filterPackages", {...filter,...this.paginate_index});

    //   this.$http
    //     .get(
    //       PROVIDER.DEV_URL +
    //         API_PREFIX +
    //         API_VERSION +
    //         "get-package-wedding-providers?per_page=1&page=1"
    //     )
    //     .then((response) => {
    //       this.packages = response.data.data;
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log("error", error.response.data);
    //       this.isLoading = false;
    //     });
    // },
    replace(url, params) {
      // Crée un objet URL à partir de l'URL de base
      const urlObj = new URL(url);

      // Ajoute ou remplace les paramètres dans l'URL
      for (const [key, value] of Object.entries(params)) {
        urlObj.searchParams.set(key, value);
      }

      // Retourne l'URL mise à jour
      return urlObj.toString();
    },
    filterPackages(filter){
      this.filterPackage = filter;
      this.getPackages();
    },

    getPackages(filter) {

      this.isLoading = true;
      let app = this;
      const urlFinale = this.replace(
        PROVIDER.DEV_URL +
          API_PREFIX +
          API_VERSION +
          `get-package-wedding-providers`,
        {...app.filterPackage,...app.paginate_index}
      );
      console.log(urlFinale);

      this.$http
        .get(urlFinale)
        .then((response) => {
          this.packages = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    paginationPackages(pag) {
      this.paginate_index.page = pag;
      this.getPackages();

    },
  },
};
</script>
<style></style>
