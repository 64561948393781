<template>
<div class="flex flex-wrap">
  <div class="flex flex-row" v-for="(page, index) in pages" :key="index">
    <router-link :to="page.path" class="text-xs text-gray-500 hover:text-red-800 text-uppercase" >
      {{ page.label }} &nbsp;
    </router-link>
  </div> 
</div>
</template>

<script>
export default {
  name: "BreadcrumbComponent",
  props: {
    pages: {
      type: Array,
      required: true,
    }
  }
}
</script>
