<template>
  <div>

    <mode-choice-component v-if="step==1"/>

    <!-- <model-choice-component/> -->

    <!-- <joycard-builder-component/> -->

    <!-- <message-builder-component
      v-if="step == 2"
      :channel="channel"
      @return-to-channel-choice="returnToChannelChoice"
      @go-to-design-maker="goToDesignMaker"
      @go-to-target-choice="goToTargetChoice"
    /> -->

    <design-maker-component
      v-if="step == 2"
      :message="message"
      @return-to-message-builder="returnToMessageBuilder"
      @go-to-target-choice="goToTargetChoice"
      @go-to-message-summary="goToMessageSummary"
    />

    <target-choice-component
      v-if="step == 3"
      :message="message"
      @return-to-design-maker="returnToDesignMaker"
      @return-to-message-builder="returnToMessageBuilder"
      @go-to-message-summary="goToMessageSummary"
    />

    <channel-choice-component
      v-if="step == 4"
      @go-to-message-builder="goToMessageBuilder"
    />

    <message-summary-component
      v-if="step == 5"
      :message="message"
      :targets="targets"
      @return-to-target-choice="returnToTargetChoice"
      @go-to-payment="goToPayment"
    />

    <payment-component
      v-if="step == 6"
      :message="message"
      @return-to-message-summary="returnToMessageSummary"
      @message-sent="messageSent"
    />

  </div>
  
</template>

<script>
import { useMeta } from 'vue-meta';
export default {
  name: "Create",
  setup () {
    useMeta({
      title: "Création d’une Joy Card, Faire-Parts Numériques de Mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Commander des faire-parts en Côte d’Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, commander des faire-parts en Côte d’Ivoire, prestataire de mariage, comment choisir le design de ses faire-parts, envoyer ses faire-parts par mail, faire-parts digital, invitation de mariage",
        },
      ],
    })
  },

  data: function() {
    return {
      step: 1,
      channel: null,
      message: null,
      customerChannel:null,
      targets: null,
      messageSend:{}
    };
  },
  mounted () {
    
  },
  methods: {
    returnToChannelChoice: function() {
      let app = this;
      app.step = 1;
    },
    goToMessageBuilder: function(channel) {
      let app = this;
      app.channel = channel;
      app.step = 2;
    },
    returnToMessageBuilder: function() {
      let app = this;
      app.step = 2;
    },
    goToDesignMaker: function(message) {
      let app = this;
      app.message = message;
      app.step = 3;
    },
    returnToDesignMaker: function() {
      let app = this;
      app.step = 3;
    },
    goToTargetChoice: function(message) {
      let app = this;
      app.step = 4;
      app.message = message;
      
    },
    returnToTargetChoice: function() {
      let app = this;
      app.step = 4;
      app.message = app.message; 
    },
    goToMessageSummary: function(message,targets) {
      let app = this;
      app.step = 5;
      app.message = message;
      this.targets = targets;
    },
    returnToMessageSummary: function() {
 
      this.step = 5;
    },
    goToPayment: function(message) {
      let app = this;
      this.message = message;
      app.step = 6;
    },
    messageSent: function(message) {
      let app = this;
      this.message = message;
      this.step = 6;
      this.message = message;
      app.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
      );
    },
  },
};
</script>
