<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br /><br />
      <div class="design-maker-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="CHOISSISEZ VOTRE DESIGN" gender="F" />
          </div>
        </div>
        <div class="row" style="position:relative;z-index:1;" v-if="layouts">
          <div
            :style="
              'position:absolute;z-index:-1;top:-50%;bottom:0;left:85%;right:0;background: url(/images/communication/card.png) no-repeat;background-size:200px;background-repeat:no-repeat;opacity:0.2;'
            "
          ></div>
          <div class="col-md-6">
            <div class="form-group">
              <!-- <label for="">Design du message</label> -->
              <!-- <select v-model="layout" @change="onLayoutsChange(layout)" class="form-control">
                                <option :value="null"></option>
                                <option v-for="(layout, key) in layouts" :key="key" :value="layout">
                                    {{ layout.name }}
                                </option>
                            </select> -->
              <div class="row text-center">
                <div
                  class="col-md-3 col-sm-3"
                  v-for="(layout, key) in layouts"
                  :key="key"
                >
                  <label :for="key">
                    <img
                      :for="key"
                      :src="base_url + layout.preview_image"
                      class="img-thumbnail rounded"
                      alt=""
                    />
                    <input
                      @change="onLayoutsChange(layout)"
                      :value="layout"
                      type="radio"
                      name="layout"
                      :id="key"
                    />&nbsp;
                    <label :for="key" style="font-size:10px;">{{
                      layout.name
                    }}</label>
                  </label>
                </div>
              </div>
            </div>
            <div class="card-footer pb-0 pt-3">
                <!-- <jw-pagination :items="exampleItems" :styles="customStyles" @changePage="onChangePage" :labels="defaultLabels"></jw-pagination> -->
            </div>
          </div>
          <div class="col-md-6" v-if="message && message != null">
            <div
              v-if="message.layout && message.layout != null"
              class="form-group"
            >
              <!-- <label for="">Aperçu du design</label><br> -->
              <div
                v-if="
                  message.layout.preview_image == null ||
                    message.layout.preview_image == ''
                "
                class="alert alert-info"
              >
                <div class="text-center">
                  Ce design n'a pas de preview.
                </div>
              </div>
              <div v-else>
                <img
                  :src="base_url + message.layout.preview_image"
                  style="width:100%;height:auto;"
                  alt=""
                />
              </div>
            </div>
            <div class="form-group" v-else>
              <!-- <label for="">Aperçu du design</label><br> -->
              <div class="alert alert-info text-center">
                Aucun design selectionné !
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12" style="text-align: center;">
                        <button class="default-button" v-if="layout != null" data-toggle="modal" data-target="#exampleModalCenter">
                            <i class="fa fa-eye"></i> Aperçu
                        </button>
                        <button class="default-button" disabled v-else>
                            <i class="fa fa-eye"></i> Aperçu
                        </button>
                    </div> -->

          <div class="col-md-12">
            <button
              class="secondary-button pull-left"
              @click="returnToMessageBuilder"
            >
              <i class="fa fa-chevron-left" style="color:#fff;"></i> PRÉCÉDENT
            </button>

            <div v-if="layout != null && message != null">
              <div v-if="message.channel && message.channel != null">
                <button
                  class="primary-button pull-right"
                  v-if="message.channel.code == 'facebook'"
                  @click="goToMessageSummary"
                >
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>

                <button
                  class="primary-button pull-right"
                  v-else
                  @click="goToTargetChoice"
                >
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>
              </div>
            </div>

            <button class="primary-button pull-right" disabled v-else>
              SUIVANT <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button>
          </div>
        </div>
        <div class="row" v-else>
          Chargement en cours ..
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.design-maker-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}

.dm-container {
  position: relative;
  text-align: center;
  /* padding: 100px; */
  /* color: white; */
} 

/* Centered text */
.dm-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: cursive;
  font-size: 20px;
}
.pagination li a{
  color:yellow
}


</style>

<script>
import { API_PREFIX, API_VERSION, COMMUNICATION } from "@/config";
import axios from "axios";
import { defineComponent } from 'vue';

const customStyles = {
 
    a: {
            cursor: 'pointer',
            padding: '6px 12px',
            display: 'block',
            float: 'left'
        }

};

const defaultLabels = {
        first: 'Début',
        last: 'Fin',
        previous: 'Précédent',
        next: 'Suivant'
    };

export default defineComponent({
  name: "DesignMakerComponent",
  props: ["message"],
  data: function() {
    return {
      exampleItems:null,
      layouts: null,
      base_url: COMMUNICATION.DEV_URL,
      customerChannels: null,
      customerChannel: null,
      layout: null,
      customStyles,
      defaultLabels,
    };
  },
  watch: {
    // à chaque fois que le message change, cette fonction s'exécutera
    message: function() {
      if (this.message != null && this.message.channel != null) {
        this.getChannels();
      }
    }
  },
  mounted () {
    
    // this.getChannels()
    this.getLayouts()
    
  },
  methods: {
    returnToMessageBuilder: function() {
      this.$emit("return-to-message-builder");
    },
    goToTargetChoice: function() {
      this.$emit("go-to-target-choice", this.message);
    },
    goToMessageSummary() {
      this.message.targets = [];
      this.message.peopleCount = 0;
      this.message.tab = [];
      // this.message.customerChannel = this.customerChannel;
      this.$emit("go-to-message-summary", this.message);
    },
     getChannels() {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(function(response) {
          app.customerChannels = response.data;
          
          app.customerChannels.forEach(item => {
            if (item.id == app.message.channel.id) {
              app.customerChannel = item;
              app.message.customerChannel = app.customerChannel;
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getLayouts: function() {
      let app = this;
      axios
        .get(COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "designs",{
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          })
        .then(function(response) {
          app.layouts = response.data;
          app.exampleItems = [...response.data].map((lay,index) => ({id : lay.id , preview_image : lay.preview_image,name:lay.name, image:lay.image }));
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    onLayoutsChange: function(layout) {
      this.layout = layout;
      this.message.layout = layout;
    },
    onChangePage(pageOfItems) {
      this.layouts = pageOfItems;
    }
  }
});
</script>
