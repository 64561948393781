<template>
  <div style="width: 100%;background-color: #fff;">
    <form
      style="margin: 0 auto;height: 100vh;background-image: url('/images/backgrounds/auth/auth.png');background-size: contain;z-index: -999;"
      class="p-5"
      @submit.prevent="onSubmit"
    >
      <div style="background-color: #fff;z-index: 999;" class="pb-3">
        <div class="col-md-12">
          <div style="text-align: center;" class="pt-5 pb-4">
            <h3 class="font-weight-light">Connexion</h3>
          </div>
        </div>
        <div class="col-md-12" v-if="error == true">
          <div class="alert alert-danger text-center">
            <strong>Oups...</strong> Adresse email et/ou Mot de passe
            incorrecte(s).
          </div>
        </div>
        <div class="col-md-12" v-if="messageError.status == false">
          <div class="alert alert-danger text-center">
            <strong>Oups...</strong> {{messageError.message}}
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group">
            <input
              type="email"
              class="auth-input col-12"
              placeholder="Adresse email"
              v-model="user.email"
              required
            />
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="auth-input col-11"
              placeholder="Mot de passe"
              v-model="user.password"
              required
            />
            <button
              class="col-1 btn btn-sm btn-link"
              type="button"
              @click="passwordInputManagement"
            >
              <i v-if="showPassword" class="fa fa-eye-slash"></i>
              <i v-else class="fa fa-eye"></i>
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group form-check text-right pb-2">
            <a
              @click="goToResetPassword"
              href="javascript:void(0);"
              style="text-decoration: none;"
            >
              <i class="fa fa-lock text-primary" aria-hidden="true"></i>&nbsp;
              <span class="link font-weight-light">Mot de passe oublié ?</span>
            </a>
          </div>
        </div>
        
        <div class="col-md-12 text-center">
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 10px;"
              v-if="isLoading == false"
            >
              CONNEXION
            </button>
            <div class="text-center" v-else>
              <a
                href="javascript:void(0);"
                class="btn-social"
                >
              <moonloader :size="'35px'"/>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <a
            href="javascript:void(0);"
            @click="connectFacebook"
            class="btn-social btn-facebook"
            ><i class="fa fa-facebook"></i
          >
          </a>
        </div>
        <div class="col-md-12 pt-2 text-center">
          Pas encore de compte ?
          <a
            href="javascript:void(0);"
            @click="goToRegister"
            class="text-primary m-l-5"
            ><b>Inscrivez-vous</b></a
          >
        </div>
        <div class="col-md-12 pt-1 text-center" style="font-size: 11px;">
          Ou retourner à la
          <a href="javascript:void(0);" @click="$router.push('/')">
            <span class="text-primary" style="font-weight: 900;">
              Page d'accueil
            </span>
          </a>
        </div>
      </div>
    </form>
  </div>
  <!-- </div> -->
  <!-- </div> -->
  <!-- <form class="form-horizontal form-material m-3 p-3 row" style="background-color:#fff;" @submit.prevent="onSubmit">
        <div class="col-md-12 text-center">
            <a href="javascript:void(0)">
                <img src="/images/old_img/logo.png" alt="" />
            </a>
        </div>
        <div class="col-md-12">
            <div style="text-align: center;" class="m-t-20 m-b-20">
                <h3>Connexion</h3>
            </div>
        </div>
        <div class="col-md-12" v-if="error == true">
            <div class="alert alert-danger text-center">
                Oups... Email / Mot de passe incorrect.
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group m-t-10">
                <input class="form-control" type="email" v-model="user.email" placeholder="Adresse email" required>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <input class="form-control" type="password" v-model="user.password" placeholder="Mot de passe" required>
            </div>
        </div>
        <div class="col-md-12">
            <div class="custom-control custom-checkbox">
                <a href="javascript:void(0)" @click="goToResetPassword" class="text-dark pull-right">
                    <i class="fa fa-lock m-r-5"></i> Mot de passe oublié ?
                </a> 
            </div>     
        </div>
        <div class="col-md-12 text-center m-t-20">
            <div class="form-group">
                <button 
                    style="background-color:#b71c1c;color:#fff;" 
                    class="btn btn-lg btn-block text-uppercase btn-rounded"
                    v-if="isLoading == true" disabled
                >
                    <spinner size="small" line-fg-color="#fff" />
                </button>
                <button 
                    style="background-color:#b71c1c;color:#fff;" 
                    class="btn btn-lg btn-block text-uppercase btn-rounded" 
                    type="submit" v-else
                >
                    Connexion
                </button>
            </div>
        </div>
        <div class="col-md-12 text-center">
            <a href="javascript:void(0);" @click="connectFacebook" class="btn-social btn-facebook"><i class="fa fa-facebook"></i></a>
        </div>
        <div class="col-md-12 form-group m-b-0 p-t-5 text-center">
            Pas encore de compte ? <a href="javascript:void(0);" @click="goToRegister" class="text-primary m-l-5"><b>Inscrivez-vous</b></a>
        </div>
    </form> -->
</template>

<script>
export default {
  name: "Login",
  data: function() {
    return {
      user: {
        email: null,
        password: null,
      },
      messageError:{
        status:true,
        message:null
      },
      error: false,
      isLoading: false,
      status: null,
      social: {
        grant_type: "social",
        client_id: 2,
        client_secret: "jHgvL5xHIGvxU9ltY5dewowAuLTxTnpODbw08AYX",
        provider: "facebook",
        access_token: null,
      },
      showPassword: false,
    };
  },
  components: {
    //
  },
  mounted: function() {
    // FB.getLoginStatus(function(response) {
      
    // });
  },
  methods: {
    passwordInputManagement() {
      this.showPassword = !this.showPassword;
    },
    // connectFacebook: function() {
    //   var app = this;
    //   FB.login(
    //     function(response) {
          
    //       if (response.status == "connected") {
    //         app.loginFacebook(
    //           response.authResponse.accessToken,
    //           response.authResponse.userID
    //         );
    //       }
    //     },
    //     {
    //       scope: "public_profile,email",
    //     }
    //   );
    // },
    loginFacebook: function(accessToken, userId) {
      var app = this;
      app.social.access_token = accessToken;
      app.social.userId = userId;
      this.$store
        .dispatch("loginSocial", this.social)
        .then((response) => {
          if (
            document.referrer.indexOf(
              location.protocol + "//" + location.host
            ) === 0
          ) {
            window.location.href = document.referrer;
          } else {
            
            // window.location.href =
            //   "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances";

              window.location.href =
              "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref";
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
          }
        });
    },
    goToRegister() {
      this.$router.push("/inscription");
    },
    onSubmit() {
      this.isLoading = true;
      this.$store
        .dispatch("login", this.user)
        .then((dd) => {
          console.log(
            dd
          );
          this.$store
            .dispatch("get_customer_info")
            .then(() => {
              this.isLoading = false;
              this.error = false;
              if (
                document.referrer.indexOf(
                  location.protocol + "//" + location.host
                ) === 0
              ) {
                window.location.href = document.referrer;
              } else {
                window.location.href = "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances";
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.error = true;
            });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;

          if (error.response) {
            if (error.response.data.success == false) {
              this.error = true;
            }
          }else{
            this.messageError.status = error.success
            this.messageError.message = error.message
          }
        });
    },
    goToResetPassword: function() {
      this.$router.push("/mot-de-passe-oublie");
    },
    redirectProvider(){
      window.location.href = "https://wedding-provider-space.paraclet-bk.com/connexion";
      // this.$router.replace("/https://wedding-provider-space.paraclet-bk.com/connexion")
    }
  },
};
</script>
