<template>
  <div class="flex py-2 w-full" v-if="globalMark">
    <div class="flex flex-col justify-center items-center w-full">
      <div class="text-5xl font-semibold">{{ globalMark }}</div>
      <div class="flex">
        <review-stars-component :is-shown-mode="true" :mark="globalMark" :stars="getStars(globalMark) "></review-stars-component>
      </div>
    </div>
<!--    <div class="hidden md:flex md:flex-col md:w-1/4">-->
<!--      <div>Professionnalisme</div>-->
<!--      <div class="flex flex-col">-->
<!--        <review-stars-component :count="18" :mark="5"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="4"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="3"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="2"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="1"></review-stars-component>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="hidden md:flex md:flex-col md:w-1/4">-->
<!--      <div>Rapport Qualité / Prix</div>-->
<!--      <div class="flex flex-col">-->
<!--        <review-stars-component :count="18" :mark="5"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="4"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="3"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="2"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="1"></review-stars-component>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="hidden md:flex md:flex-col md:w-1/4">-->
<!--      <div>A recommander ?</div>-->
<!--      <div class="flex flex-col">-->
<!--        <review-stars-component :count="18" :mark="5"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="4"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="3"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="2"></review-stars-component>-->
<!--        <review-stars-component :count="0" :mark="1"></review-stars-component>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {COMPONENT_STAR_TYPES} from "@/views/wedding-provider/types";
export default {
  name: "ReviewMarkListComponent",
  props: ['globalMark'],
  data() {
    return {
      mark: 0,
    }
  },
  watch: {
    globalMark: function (val) {
      this.mark = val ? Number(val) : 0;
    }
  },
  methods:{
    getStars(currentMark) {
      
      const stars = [];
      let roundMark = 0;
      if (currentMark) {
        roundMark = Number(currentMark);
      }
      for (let i = 1; i <= 5; i++) {
        if (i <= roundMark) {
          stars.push({ type: COMPONENT_STAR_TYPES.FULL });
        } else {
          stars.push({ type: COMPONENT_STAR_TYPES.EMPTY });
        }
      }
      return stars;
    },
  }
}
</script>
