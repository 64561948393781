<template>
  <div class="bg-container-restricted-committees">
        <Bar />  
    <div class="container p-3" style="padding: 25px;">
      <big-title label="COMITÉ RESTREINT" />
      <br />
      <p class="text-center">
        Comment s’entourer de personnes efficaces qui sauront rehausser<br />
        l’une des journées les plus cruciales de sa vie ? <br />
        Telle est la question que les fiancés doivent se poser à <br />
        l’étape de la constitution de leur comité restreint.
      </p>
      <div class="text-center">
        <p class="collapse" id="collapseRC">
          <strong>Le comité restreint</strong> est composé de l’ensemble des
          personnes qui seront aux petits soins des mariés ; <br />
          ce sont des personnes essentielles à la célébration de votre mariage
          :<br />
          - Les témoins - Le bestman - La dame de compagnie <br />
          - Le ou la Président(e) du Comité d’Organisation<br /><br />
          <strong>Le saviez-vous :</strong> <br />
          Dans l’antiquité romaine, un cortège d’une dizaine de jeunes gens,<br />
          généralement au nombre de 10, accompagnaient les mariés à l’église.<br />
          Tout ce petit monde, de même taille, même âge, étaient vêtus à
          l’identique des mariés. <br />
          Le but ? Berner les mauvais esprits, ou autres éconduits, qui
          voudraient<br />
          s’attaquer aux futurs époux avant que le prêtre ne scelle leur
          destin.<br />
          Ainsi, ces vils démons ne pourraient plus distinguer les mariés de
          leurs proches !
        </p>
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseRC"
          role="button"
          aria-expanded="false"
          aria-controls="collapseRC"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <br />
      <div v-if="isLoading == true">
        <spinner  /><br /><br />
      </div>
      <div
        class=""
        v-else-if="user.email_verified_at != null && customer.dob != null"
      >
        <div
          v-if="isLoading == false && customer != null && rcommittees != null"
        >
          <div class="col-md-12 alert alert-danger text-center"  v-if="rcommittees.length == 0">
            Il n'y a pas de parametre pour cet type d'évènement
          </div>
          <div v-for="(item, key) in rcommittees" :key="key">
            <restricted-committee-component
              :rcommitteeProps="item"
              @update-rcommittee="updateRestrictedCommittee"
              :index="key"
            />
          </div>
        </div>
        <div v-else>
          <spinner  /><br /><br />
        </div>
      </div>
      <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
      <center>
        <button @click="goToPrevious" class="default-button">PRECEDENT</button
        >&nbsp;
        <button @click="goToNext" class="default-button">SUIVANT</button>
      </center>
    </div>
  </div>
</template>

<style scoped>
.bg-container-restricted-committees {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-restricted-committees .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-restricted-committees::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/restricted-committees.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import RestrictedCommitteeComponent from "@/components/nuptial-notebook/RestrictedCommitteeComponent";
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import store from "@/store";
import axios from "axios";
import { useMeta } from 'vue-meta';
export default {
  name: "CustomerRestrictedCommittees",
  setup () {
    useMeta({
      title:
        "Carnet Nuptial - Comité restreint - Les personnes clés pour la célébration de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Planifier son mariage en Côte d'Ivoire",
        },
        {
          name: "keywords",
          content:
          "Carnet nuptial, mariage en Côte d’Ivoire, Dame de compagnie,organisation de mariage,organiser son mariage, prestataire de mariage, prestataire de service,  comment choisir sa dame de compagnie, comment choisir ses témoin, témoin du marié, témoin de la mariée, marraine, PCO, comité d’organisation du mariage",
        },
      ],
    })
  },
  components: {
    RestrictedCommitteeComponent,
    Bar,
  },
  data: function() {
    return {
      showModal:false,
      isLoading: false,
      rcommittees: null,
      rcommitteeForm: null,
      user: JSON.parse(store.state.user),
      customer: {dob:null},
      textFullDisplay: false,
    };
  },
  mounted: function() {
    this.getCustomerInfo()
      .then((customer) => {
        this.getRestrictedCommittees(customer.current_event.id);
      })
      .catch(() => {});

    this.show();
  },
  methods: {
    goToPrevious: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref"
      );
    },
    goToNext: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/budgets"
      );
    },
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            console.log('restric :  ',response.data)
            this.customer = response.data;
            // if (!this.customer.user.email_verified_at) {
            //   this.$router.push('/inscription-a-terminer/'+2);
            // }
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getRestrictedCommittees: function(eventId) {
      console.log('Event ID : ',eventId)
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "restricted-committees-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          
          this.isLoading = false;
          this.rcommittees = response.data;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    updateRestrictedCommittee: function(rcommittee) {
      this.isLoading = true;
      let rcommitteeForm = new FormData();
      rcommitteeForm.append("event_id", rcommittee.event_id);
      rcommitteeForm.append(
        "restricted_committee_id",
        rcommittee.restricted_committee_id
      );
      rcommitteeForm.append("civility", rcommittee.civility);
      rcommitteeForm.append("last_name", rcommittee.last_name);
      rcommitteeForm.append("given_names", rcommittee.given_names);
      rcommitteeForm.append("email", rcommittee.email);
      rcommitteeForm.append("phone_number_1", rcommittee.phone_number_1);
      rcommitteeForm.append("phone_number_2", rcommittee.phone_number_2);
      rcommitteeForm.append("image", null);

      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "restricted-committees/events",
          rcommitteeForm,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Informations enregistrées avec succès !  !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
          });
          this.getRestrictedCommittees(this.customer.current_event.id);
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;

          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de la recuperation des informations !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>
