<template>
    <div
      class="container w-full h-[20rem] d-flex col-md-12 justify-content-center align-items-center background-bk-image"
    >
      <div class="" style="position: relative; top: 1%">
        <div class="flex flex-col py-2 items-center text-white">
          <div class="md:flex uppercase">
            Découvrez nos meilleurs packages !
          </div>
          <div
            class="flex text-xl text-center font-semibold px-4 pt-8 md:px-0 md:pt-0"
          >
            Trouvez les meilleures offres de nos prestataires
          </div>
          <div class="d-flex container-fluid w-100 h-100" v-if="isDisplaySearchInputs==true">
            <div class="col-md-12">
              <div class="search d-flex justify-content-between">
                <input v-model="search"
                  class="search_input"
                  type="text"
                  name=""
                  placeholder="Recherchez ici ..."
                />
                <a href="#" class="search_icon" @click="searchPackage"><i class="fa fa-search"></i></a>
              </div>
            </div>
            
          </div>
          <!-- <div class="d-flex align-items-center mt-2">
              <div class="dropdown">
                <button
                  class="btn btn-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Catégories
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </div>
            </div> -->
          <!-- <div class="container">
            
          </div> -->
        </div>
      </div>
      <!-- <img src="../assets/images/rectangle_1.png" class="img-fluid" style="width:100%;height:100%;display:block;position:absolute"/> -->
    </div>
</template>

<style scoped>
.background-bk-image {
  background-image: url("@/views/wedding-provider/assets/images/rectangle_1.png");
  background-size: cover;
  background-position: center;
  /* position: absolute; */
  /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
}

.search {
  margin-bottom: auto;
  margin-top: auto;
  height: 70px;
  background-color: #fff;
  border-radius: 40px;
  padding: 10px;
}

.search_input {
  color: grey;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  margin-top: 5px;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
}

.search .search_input {
  padding: 0 10px;
  width: auto;
  caret-color: grey;
  transition: width 0.4s linear;
}

.search:hover > .search_icon {
  background: white;
  color: #fff;
}

.search_icon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: grey;
  background-color: black;
}

a:link {
  text-decoration: none;
}
</style>

<script>
import { arraySlice } from "@/views/wedding-provider/utils";

export default {
  name: 'banner-package',
  props: ['isDisplaySearchInputs'],
  data: () => ({
    search: null,

  }),
  mounted() {

  },
  methods: {
    searchPackage(){
      this.$emit('search-package',{
        search: this.search
      });
    }

    
  }
}
</script>