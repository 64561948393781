import { showMeAPI } from "@/views/wedding-provider/apis";

export const state = () => ({
    token: localStorage.getItem('token') || null,
    // user: null,
})

export const getters = {
    isAuthenticated(state) {
        return state.token !== null
    },

}

export const actions = {
    async showMe({ commit }, params = {}) {
        try {
            const response = await showMeAPI(params)
            commit('setUser', response.data)
        } catch (e) {
            console.log(e)
            commit('setErrors', e)
        }
    },
    async logout({ commit }) {
        commit('setToken', null)
    }
}

export const mutations = {
    async setToken(state, value) {
        try {
            await localStorage.removeItem('token')
            if (value) {
                await localStorage.setItem('token', value)
            }
            state.token = value
        } catch (e) {
            console.log(e)
            await localStorage.removeItem('token')
            state.token = null
        }
    },
    async setUser(state, value) {
        state.user = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setMedias(state, value) {
        state.medias = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}