<template>
  <div class="container no-print" style="position:relative;z-index:1;">
    <div class="row pb-4" style="min-height:300px;background-color:#fff;">
      <iframe
        class="mj-w-res-iframe"
        data-w-type="embedded"

        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://x4g7z.mjt.lu/wgt/x4g7z/uip/form?c=37e07818"
        width="100%"
        
        style="min-height:350px;"
      >
      </iframe>
    </div>
  </div>
</template>
<style scoped>
.newsletter input {
  box-shadow: none !important;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857;
  outline: medium none;
  padding: 0 9px;
  width: 100%;
}
.btn-submit {
  background: #b71c1c none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  text-transform: uppercase;
}
</style>
<script>
import { API_PREFIX, API_VERSION, CONTACT } from "@/config";
import axios from "axios";

export default {
  name: "newsletter",
  data() {
    return {
      newsletter_email: ""
    };
  },

  methods: {
    newsletter(value) {
      let app = this;
      // this.$validator.validateAll("newsletter-form").then(valid => {
      //   if (valid) {
          // app.$Progress.start();
          axios
            .post(CONTACT.DEV_URL + API_PREFIX + API_VERSION + "newsletter", {
              email: app.newsletter_email,
              sexe: value
            })
            .then(function(response) {
              // app.$Progress.finish();
              app.$toasted.success("Votre demande a été prise en compte");
            })
            .catch(function(error) {
              console.log(error);
            });
      //   }
      // });
    }
  }
};
</script>
