import { AUTH_BASE_URL, requestConfig } from "@/views/wedding-provider/utils";
import axios from 'axios';

export async function showMeAPI(params) {
    const config = requestConfig(params);
    try {
        const response = await axios.get(`${AUTH_BASE_URL}/api/user`, config);
        return response.data;
    } catch (error) {
        if (error.response.data) { throw error.response.data }
        throw { message: 'Internal server error', errors: null };
    }
}