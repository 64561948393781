export const PROVIDER_LIST = [
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Studio Arnaud Chapelle',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/87d/ef67/400/312/w/5878/-/1655906917.png.webp',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Chateau de Pramenoux',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/9b4/adb9/400/312/w/746444/-/1655816068.jpg.webp',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Château de Vaugr.',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/845/0343/400/312/w/746444/-/1656331018.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Agnieszka Penasso',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/cd4/c5f1/400/312/w/11959/-/1639594431.jpg.webp',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Les Maisons du voyage',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Printemps Mariage',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/29c/4d90/400/312/w/548191/-/1487762851.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Studio Arnaud Chapelle',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/34f/b521/400/312/w/548191/-/1487762759.jpg.webp',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Chateau de Pramenoux',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Château de Vaugr.',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Agnieszka Penasso',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/3d8/6827/400/312/w/14312/-/1587895979.jpg.webp',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Les Maisons du voyage',
  },
  {
    photos: [
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/dab/f1f1/400/312/w/471369/-/1627050785.jpeg',
      },
      {
        path: 'https://asset1.zankyou.com/images/wervice-card/af5/77bf/400/312/w/471369/-/1595778483.jpg',
      },
    ],
    name: 'Printemps Mariage',
  },
]
