import { BASE_URL, requestConfig } from "@/views/wedding-provider/utils";
import axios from 'axios';

export async function getQuoteRequestsAPI(params = {}) {
    const config = requestConfig(params);
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-quote-requests-client`, config);
        return response.data;
    } catch (error) {
        console.log("===> getQuoteRequests error", error);
    }
}

export async function storeQuoteRequestAPI(data, params = {}) {
    const config = requestConfig(params);
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/wedding-provider-quote-requests`, data, config);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}