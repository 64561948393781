<template>
  <div>
    <vue3-progress-bar></vue3-progress-bar>
    <Header></Header>
    <Nav @search="search"></Nav>
    <Breadcrumb></Breadcrumb>
    <div class="wrapper shadow" id="wrapper" style="">
      <router-view></router-view>
      <div
        style="background-color:white; height:80px;position:relative;z-index:5;"
      ></div>
    </div>
    <div class="bg-container-labulle">
      <hr style="box-shadow:1px 3px 5px gray;" />
      <div class="h1 d-flex justify-content-center">
        <div
          class="half-circle"
          title="Jours de joie"
          style="text-align:center;"
        >
          <img
            class="mx-auto"
            style="width: 200px;"
            src="/images/logo.png"
            alt=""
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>
<style scoped>
.half-circle {
  height: 190px;
  width: 380px;
  background-color: white;
  border-bottom-left-radius: 240px;
  border-bottom-right-radius: 240px;
  box-shadow: 1px 15px 15px 0px rgb(226, 226, 226);
}
.bg-container-labulle {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-labulle::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/home/home.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Header from "@/components/shopping/Header.vue";
import Breadcrumb from "@/components/shopping/Breadcrumb.vue";
import Nav from "@/components/shopping/Nav.vue";
import Footer from "@/components/Footer.vue";
import store from '@/store';
export default {
  name: "app",
  components: {
    Header,
    Breadcrumb,
    Footer,
    Nav
  },

  methods: {
    search(searchText, categoryId) {
      let app = this;
      if (categoryId == 0) {
        app.$router.push({
          name: "marketplaceindex",
          query: { search: searchText }
        });
      } else {
        app.$router.push({
          name: "category",
          params: { category_id: categoryId },
          query: { search: searchText }
        });
      }
    }
  },

  mounted() {
    store
      .dispatch("get_customer_info")
      .then(response => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
        }
      });
  }
};
</script>
