import { BASE_URL, requestConfig } from "@/views/wedding-provider/utils";
import axios from 'axios';

export async function getFavoritesAPI(params) {
    const config = requestConfig(params);
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-favorites`, config);
        
        return response.data;
    } catch (error) {
        if (error.response.data) { throw error.response.data }
        throw { message: 'Internal server error', errors: null };
    }
}

export async function updateFavoriteAPI(data, params) {
    const config = requestConfig(params);
    try {
        const response = await axios.put(`${BASE_URL}/api/v1/wedding-provider-favorites`, data, config);
        return response.data;
    } catch (error) {
        if (error.response.data) { throw error.response.data }
        throw { message: 'Internal server error', errors: null };
    }
}