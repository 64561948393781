import { getMediasAPI } from "@/views/wedding-provider/apis/medias";

export const state = () => ({
    isLoading: false,
    errors: {},
    medias: [],
    photos: [],
    videos: [],
})

export const actions = {
    async getMedias({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getMediasAPI(params);
            
            let photocount = []
            let videocount = []
            let test = []
            response.data.forEach(element => {
                
                if (element.type == 'photo') {
                    photocount.push(element)
                } else {
                    videocount.push(element)
                }
                
            });

            console.log('test',test)
            
            commit('setPhotos', photocount);
            commit('setVideos', videocount);
            commit('setMedias', response.data);
            commit('setLoading', false);
        } catch (error) {
            commit('setLoading', false);
            commit('setMedias', []);
            commit('setErrors', error.response.data);
        }
    }
}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setMedias(state, value) {
        state.medias = value
    },
    setPhotos(state, value) {
        state.photos = value
    },
    setVideos(state, value) {
        state.videos = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}