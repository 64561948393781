<template>
  <div>
    <Breadcrumb
      bgClass="bg-image--visibility"
      titre="JOY VISIBILITY"
    ></Breadcrumb>
    <div class="container pt-5">

      <div class="row align-self-center">
        <div class="col-md-6 align-self-center">
          <p class="text-justify" style="font-size:16px;">
            <span class="font-weight-bold">Jours de Joie</span> est l'assistant
            digital qui aide les futurs mariés dans l'organisation de leur
            mariage
            <span class="font-weight-bold">
              (dot, les ceremonies administratives et religieuses).
            </span>
            <br />
            Jours de Joie propose les differentes lignes de l'organisation du
            mariage avec pistes sur elaboration du budget, la gestion des
            faire-parts. <br />
            D'un autre côté Jours de Joie désire mettre en avant les
            prestataires du mariage c'est-à-dire vous ! <br />
            <br />
          </p>
        </div>
        <div class="col-md-6">
          <img
            src="/images/dancing.jpg"
            class="img-fluid"
            width="100%"
            alt=""
          />
        </div>
      </div>
      <h3 class="text-center pt-4 pb-3" style="color:#b71c1c;">
        Pourquoi devenir partenaire de Jours de joie ?
      </h3>
      <div class="text-center">
        <div class="">
          <div class="card">
            <div class="card-body">
              <!-- <h5 class="card-title">Special title treatment</h5> -->
              <!-- <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> -->
              <div>
                <span
                  class="badge badge-pill"
                  style="backgroundColor:#b71c1c; color: white;"
                  >1</span
                >
                Votre prochain client est à la recherche des prestataires
                généralistes et spécialistes du mariage.
              </div>
              <div class="">
                <span
                  class="badge badge-pill"
                  style="backgroundColor:#b71c1c; color: white;"
                  >2</span
                >
                Bénéficier d'une mise en avant et un meilleur référencement
                grâce à votre présence sur notre site.
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-sm-6">
          <div class="card">
            <div
              class="card-body"
              style="padding-left:10px; padding-right:10px;height:130px"
            >
              <!-- <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
              <div>
                <span
                  class="badge badge-pill"
                  style="backgroundColor:#b71c1c; color: white;"
                  >3</span
                >
                Recruter des données précieuses sur vos clients et sur la
                concurrence.
              </div>
              <div>
                <span
                  class="badge badge-pill"
                  style="backgroundColor:#b71c1c; color: white;"
                  >4</span
                >
                Augmenter votre chiffre d'affaire en vous inscrivant sur
                joursdejoie.com
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card">
            <div
              class="card-body"
              style="padding-left:10px; padding-right:10px; height:130px"
            >
              <!-- <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
              <div class="">
                <span
                  class="badge badge-pill"
                  style="backgroundColor:#b71c1c; color: white;"
                  >5</span
                >
                Prenez de l'avance sur la concurrence en diversifiant votre
                circuit de distribution
              </div>
              <div class="">
                <span
                  class="badge badge-pill"
                  style="backgroundColor:#b71c1c; color: white;"
                  >6</span
                >
                Pénétrez un marché sous exploité et comblez les besoins des
                consommateurs
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="wrap  text-center">
        <a href="https://forms.gle/byYwc6KSMaurLVmr5" target="_bank" class="">
          <button class="button">Ouvrir mon compte prestataire</button>
        </a>
      </div>
      <!-- <section class="text-center" sty>
        <a href="https://forms.gle/zeYLwNiZ5dmoyrtZ8" target="_bank" class="btn">
          <span class="btn-content">Ouvrir mon compte prestataire<br></span>
          <span class="icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </a>
      </section> -->
      <br>
      <!-- Banniere -->
      <div class="col-12">
        <img src="/images/1200x300.jpg" alt="" />
      </div>
      <br />
      <h3 style="color:#b71c1c;">
        A qui profite Joy Visibility :
      </h3>
      <br />
      <div>
        Si vous êtes un prestataire du mariage à Abidjan ou partout en Côte d'Ivoire, cette offre de visibilité est la votre. Vous proposez surement les services suivants
      </div>
      <div class="d-flex flex-column flex-md-row ">
        <!-- <ul> -->
        <div class="col-md-3 col-sm-6">
          <li>Décoration</li>
          <li>Tenue de mariage</li>
          <li>Costume de marié</li>
          <li>Coiffure de mariées</li>
          <li>Coaching pour mariage</li>
        </div>
        <div class="col-md-3 col-sm-6">
          <li>Dragées de mariages</li>
          <li>Cadeaux de mariage</li>
          <li>Accessoires de mariées</li>
          <li>Wedding planner</li>
          <li>Compositions fleurales</li>
        </div>
        <div class="col-md-3 col-sm-6">
          <li>Coiffure mariage</li>
          <li>Gâteau de mariage</li>
          <li>Décoration de noces</li>
          <li>Robe de mariée</li>
          <li>Institut de relaxation</li>
        </div>
        <div class="col-md-3 col-sm-6">
          <li>Tenu de mariage traditionnel</li>
          <li>Faire-Part</li>
          <li>Espace hôtelier</li>
          <li>Institut de beauté</li>
          <li>Services traiteur</li>
        </div>
        <!-- </ul> -->
      </div>
      <br />
      <h3 style="color:#b71c1c;">
        Quel est le processus de conception graphique :
      </h3>
      <br />
      <div>
        <img src="/images/process.png" alt="" />
        <!-- <p>- Choix de l'emplacement de la bannière par le client et retour du brief créatif à l’équipe design de Jours de joie,</p>
        <p>- Analyse du brief créatif et conception en 3 jours à compter de la réception du brief (02 Propositions de bannière) </p>
        <p>-Validation </p>
        <p>-Déclinaison en cover et encart de publication Facebook, rédactions d’article</p>
        <p>-Envoi des visuels au client</p>
        <p>-Mise en ligne sur le site  de la bannière, mise en avant sur la Page Facebook de Jours de joie </p> -->
      </div>
      <br />
      <div class="d-flex justify-content-between">
        <div>
          <h4 style="color:#b71c1c;">Conditions de paiement :</h4>
          <p>- 70 % à la commande</p>
          <p>- 30 % à la livraison</p>
          <p>
            <span style="color:#b71c1c;">NB :</span> Validité de l’offre 30
            jours
          </p>
        </div>
        <div>
          <h4 style="color:#b71c1c;">
            Modalité de paiement :
          </h4>
          <div>
            <p>Par Orange Money au 07 09 52 42 63</p>
            <p>Par Mobile Money au 05 75 44 73 73</p>
            <br />
          </div>
        </div>
      </div>
      <br />
      <div class="d-flex">
        <div>
          <p class="h4 text-justify" style="color:#b71c1c;">
            Contactez-nous sans plus attendre, si vous proposez les services suivants à Abidjan et partout en Côte-D'Ivoire:
          </p>
          <p class="col-7">
            -Conception de tenues de mariés, de demoiselles et garçons d’honneur
          </p>
          <p class="col-7">
            -Soins esthétiques de mise en beauté de futures mariées, des
            services de coiffures aux futures mariées, de l’onglerie
          </p>
          <p class="col-7">
            -Location d’espaces de mariage, de la location de voitures, de la
            location de décorations de mariage
          </p>
          <p class="col-7">
            -Services de joaillerie (bagues de fiançailles, alliances, bijoux)
          </p>
          <p class="col-7">-Impressions de Faire-Parts</p>
        </div>
        <div>
          <img src="/images/3d-06.png" width="300" alt="" />
        </div>
      </div>
      <br />
      <h3 style="color:#b71c1c;">
        Quelles sont les informations qui nous aideront pour rendre visible les prestataires du mariage
      </h3>
      <br />
      <div>
        Nous vous envoyons un brief graphique où vous renseignez les
        informations suivantes
      </div>
      <div class="col-6">
        <li>L’emplacement de l’espace publicitaire choisi, et dimensions</li>
        <li>Le Nom de votre entreprise </li>
        <li>Votre slogan </li>
        <li>Votre cible </li>
        <li>Vos offres et services </li>
        <li>Vos tarifs </li>
        <li>Vos services ou produits en photo</li>
        <li>Vos contacts </li>
        <li>Vos réseaux sociaux (site internet)</li>
        <li>
          Et toutes autres informations nous permettant de concevoir votre
          bannière publicitaire et vous rendre visible sur Jours de Joie le
          partenaire qui met en avant les professionnels du mariage!!!
        </li>
      </div>
      <br />
      <!-- Joy visibility -->
      <hr />
      <br />
      <div class="text-left row">
        <img src="/images/computer.png" height="250" alt="" />
        &nbsp; &nbsp;
        <div class="d-flex  flex-column align-self-center">
          <h3 style="color:#b71c1c;">EXTRA VISIBILITY</h3>
          <div>
            <span
              class="badge badge-pill"
              style="backgroundColor:#b71c1c; color: white;"
              >.
            </span>
            Bannière principale site <small>Dimension 1200 x 600</small>
          </div>
          <div>
            <span
              class="badge badge-pill"
              style="backgroundColor:#b71c1c; color: white;"
              >.
            </span>
            Declinaison en cover pour votre page Facebook
          </div>
          <div>
            <span
              class="badge badge-pill"
              style="backgroundColor:#b71c1c; color: white;"
              >.
            </span>
            Declinaison pour publication Facebook
          </div>
          <div>
            <span class="text-danger">*</span>Conception : <br />
            41 500 Fcfa TTC
          </div>
        </div>
      </div>
      <hr />
      <div class="text-left row d-flex flex-row-reverse">
        <img src="/images/classic.png" width="500" alt="" />
        &nbsp; &nbsp;
        <div class="d-flex  flex-column align-self-center">
          <h3 style="color:#b71c1c;">CLASSIC VISIBILITY</h3>
          <div>
            <span
              class="badge badge-pill"
              style="backgroundColor:#b71c1c; color: white;"
              >.
            </span>
            Bannière principale site <small>Dimension 350 x 600</small>
          </div>
          <div>
            <span
              class="badge badge-pill"
              style="backgroundColor:#b71c1c; color: white;"
            >
              .</span
            >
            Declinaison en cover pour votre page Facebook
          </div>
          <div>
            <span
              class="badge badge-pill"
              style="backgroundColor:#b71c1c; color: white;"
              >.
            </span>
            Declinaison pour publication Facebook
          </div>
          <div>
            <span class="text-danger">*</span>Conception : <br />
            29 500 Fcfa TTC
          </div>
        </div>
      </div>
      <div
        class="text-center font-weight-bold font-italic"
        style="color:#b71c1c;"
      >
        Avec Jours de joie, votre Digital Event Planner, c’est un partenariat
        gagnant, gagnant.
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { useMeta } from 'vue-meta'
export default {
  name: "joy-visibility",
  setup () {
    useMeta({
      title: "Joy Visibility",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment faire sa liste d’invités à mon mariage, prestataire de mariage en cote d'ivoire"
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, organisation de mariage a abidjan, combien d’invités à mon mariage, comment faire sa liste d’invités, agencement des invités dans la salle de réception, grand espace évènementiel, carnet nuptial, hôtesses, plan de table de mariage,"
        }
      ]
    })
  },
  components: {
    Breadcrumb
  }
};
</script>

<style scoped>
.btn {
  border-radius: 4px;
  border: 2px solid #b71c1c;
  color: #b71c1c;
  display: inline-block;
  margin: 0 0.25em;
  overflow: hidden;
  padding: 24px 60px 24px 16px;
  position: relative;
  text-decoration: none;
  line-height: 1;
}
.btn .btn-content {
  font-size: 1em;
  line-height: 1.2;
  padding: 0 26px;
  position: relative;
  right: 0;
  transition: right 300ms ease;
  display: block;
  text-align: left;
}
.btn .icon {
  border-left: 1px solid #b71c1c;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transition: all 300ms ease;
  transform: translateY(-50%);
  width: 58px;
  height: 70%;
}
.btn .icon i {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.btn:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #b71c1c;
  opacity: 0;
  transition: opacity 300ms ease;
}
.btn:hover .btn-content {
  right: 100%;
}
.btn:hover .icon {
  border-left: 0;
  font-size: 1.8em;
  width: 100%;
}
.btn:hover:after {
  opacity: 0.2;
}
.btn.btn-alt-color {
  border-color: salmon;
  color: salmon;
}
.btn.btn-alt-color .icon {
  border-left-color: salmon;
}
.btn.btn-alt-color:after {
  background-color: salmon;
}

/* new  */

.wrap col-md-8 text-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  /* min-width: 500px; */
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #ffffff;
  background: #b71c1c;
  background: linear-gradient(90deg, #b71c1c 0%, #E7C466 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px #E7C466;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  }

button::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(440px + 12px);
  min-height: calc(50px + 2px);
  /* border: 6px solid #b71c1c; */
  box-shadow: 0 0 60px #e7c567;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.button:hover, .button:focus {
  color: #ffffff;
  transform: translateY(-6px);
}

button:hover::before, button:focus::before {
  opacity: 1;
}

button::after {
  content: '';
  width: 10px; height: 10px;
  border-radius: 100%;
  border: 6px solid #b71c1c;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

/* button:hover::after, button:focus::after {
  animation: none;
  display: none;
} */

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

</style>
