<template>
    <div v-if="inProgress == false" class="accordion" id="accordionExample" style="padding-top:15px;padding-bottom:15px;">
        <div class="card">
            <div 
                class="card-header text-white" 
                style="background-color: #b71c1c;" 
                :id="'heading' + index" data-toggle="collapse" 
                :data-target="'#collapse' + index" aria-expanded="true" 
                :aria-controls="'collapse' + index"
            >
                <strong>{{ budgetProps.content.toUpperCase() }} </strong>
                <div class="pull-right">
                    <i style="color: #fff;" class="fa"></i>
                </div>
            </div>

            <div :id="'collapse' + index" class="collapse show" :aria-labelledby="'heading' + index" data-parent="#accordionExample">
                <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                        <thead>
                            <th style="color:#b71c1c;text-align:left;">LIBELLES</th>
                            <th style="color:#b71c1c;text-align:right;">MINIMUM</th>
                            <th style="color:#b71c1c;text-align:right;">MAXIMUM</th>
                            <th style="color:#b71c1c;text-align:right;">RÉEL</th>
                        </thead>
                        <tbody>
                            <tr v-for="(child, childIndex) in budgetProps.children" :key="childIndex">
                                <td id="text">
                                    {{ child.content }}
                                    <button 
                                        v-if="child.category_product_id != null" @click="goToCategoryProduct(child.category_product_id,child.pivot.id)" 
                                        class="btn btn-link" data-toggle="tooltip" data-placement="top" 
                                        title="Aller à la catégorie de produit"
                                    >
                                    voir prestataire
                                        <i class="fa fa-external-link" style="color:#b71c1c;"></i>
                                    </button>
                                </td>
                                <td id="min">
                                    <!-- <select class="custom-select pull-right" dir="rtl" style="width:100%;" v-model="budgetsObj[childIndex].min_amount">
                                        <option value="">-- Choisissez un montant</option>
                                        <option 
                                            v-for="(amount, amountKey) in getAmount(amountsObj[childIndex])" 
                                            :key="amountKey"
                                            :value="amount"
                                        >
                                            {{ amount }}
                                        </option>
                                    </select> -->
                                    <v-money-spinner v-model="budgetsObj[childIndex].min_amount" v-bind="config"></v-money-spinner>
                                    <!-- <input type="number" class="form-control" v-model="budgetsObj[childIndex].min_amount"
                                    style="width:100%;text-align:right;"
                                    > -->
                                    <!-- {{ budgetsObj[childIndex].max_amount }} -->
                                    <!-- <money 
                                        class="form-control" style="width:100%;text-align:right;" 
                                        v-model.lazy="budgetsObj[childIndex].min_amount" v-bind="money"
                                    >
                                    </money> -->
                                </td>
                                <td id="max">
                                    <!-- <select class="custom-select pull-right" dir="rtl" style="width:100%;" v-model="budgetsObj[childIndex].max_amount">
                                        <option value="">-- Choisissez un montant</option>
                                        <option 
                                            :value="amount" 
                                            v-for="(amount, amountKey) in getAmount(amountsObj[childIndex])" 
                                            :key="amountKey"
                                        >
                                            {{ amount }}
                                        </option>
                                    </select> -->
                                    <v-money-spinner v-model="budgetsObj[childIndex].max_amount" v-bind="config"></v-money-spinner>
                                     <!-- <input type="number" class="form-control" v-model="budgetsObj[childIndex].max_amount"
                                    style="width:100%;text-align:right;" 
                                    > -->
                                    <!-- {{ budgetsObj[childIndex].max_amount }} -->
                                    <!-- <money 
                                        class="form-control" style="width:100%;text-align:right;" 
                                        v-model.lazy="budgetsObj[childIndex].max_amount" v-bind="money"
                                    >
                                    </money> -->
                                </td>
                                <td id="real">
                                    <v-money-spinner v-model="budgetsObj[childIndex].real_amount" v-bind="config"></v-money-spinner>
                                    <!-- <input type="number" class="form-control" v-model="budgetsObj[childIndex].real_amount"
                                    style="width:100%;text-align:right;"
                                    > -->
                                    <!-- {{ budgetsObj[childIndex].real_amount }} -->
                                    <!-- <money class="form-control" style="width:100%;text-align:right;" v-model.lazy="budgetsObj[childIndex].real_amount" v-bind="money"></money> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold" style="color:#b71c1c;">
                                    <div class="pull-left">
                                        TOTAL {{ budgetProps.content.toUpperCase() }}
                                    </div>
                                    <div class="pull-right">
                                        =
                                    </div>
                                </td>
                                <td class="font-weight-bold" style="text-align:right;color:#b71c1c;"> {{ formatPrice(minAmountSum)}}</td>
                                <td class="font-weight-bold" style="text-align:right;color:#b71c1c;">{{formatPrice(maxAmountSum)  }}</td>
                                <td class="font-weight-bold" style="text-align:right;color:#b71c1c;">{{ formatPrice(realAmountSum)   }}</td>
                            </tr>
                            <tr class="no-print">
                                <td colspan="4">
                                    <div class="pull-right">
                                        <button class="primary-button" @click="updateBudget">CALCULER</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <center v-else>
        <div style="padding:50px;">
            <spinner  />
        </div>
    </center>
</template>

<style scoped>
    [data-toggle="collapse"] .fa:before {
        content: "\f068";
    }

    [data-toggle="collapse"].collapsed .fa:before {
        content: "\f067";
    }
    #text {
        width: 40%;
        /* overflow: hidden;
        display: inline-block;
        white-space: nowrap; */
    }
    #min, #max, #real {
        width: 20%;
        /* overflow: hidden;
        display: inline-block;
        white-space: nowrap; */
    }
    select{
        float: right;
    }
    
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>

<script>
    // import { Money } from 'v-money';
    import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from 'axios';

    export default {
        name: 'BudgetComponent',
        props: ['budgetProps', 'index'],
        filters: {
            uppercase: function(text) {
                return text.toUpperCase();
            }
        },
        components: {
            // Money
        },
        data: function() {
            return {
                budget: {
                    event_id: null,
                    budget_id: null,
                    min_amount: null,
                    max_amount: null,
                    real_amount: null
                },
                budgetsObj: [],
                amountsObj: [],
                inProgress: true,
                money: {
                    decimal: ',',
                    thousands: ' ',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                realAmountSum: 0,
                minAmountSum: 0,
                maxAmountSum: 0,

                TotalrealAmountSum: 0,
                TotalminAmountSum: 0,
                TotalmaxAmountSum: 0,
                config: {
                    spinner: false,
                    step: 1,
                    min: 0,
                    // prefix: " ",
                    suffix: "",
                    minCharacters:0,
                    precision: 3,
                    decimal: ' ',
                    thousands: ' ',
                    template: "bootstrap",
                    masked: true,
                    disableNegative: true,
                    align: "right",
                }
            }
        },
        mounted: function() {
           
            this.prepareBudgetsObj();
        },
        computed: {
            //
        },
        methods: {
            formatPrice(value) {
                let val = (value/1).toFixed(0).replace(' ', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            },
            getAmount: function(array) {
                let amountArray = array.split(';');
                return amountArray;
            },
            prepareBudgetsObj: function() {
                this.inProgress = true;
                this.budgetProps.children.forEach(item => {
                    let budgetObj = {
                        event_id: item.pivot.event_id,
                        budget_id: item.pivot.budget_id,
                        min_amount: item.pivot.min_amount,
                        max_amount: item.pivot.max_amount,
                        real_amount: item.pivot.real_amount
                    }
                    // let amountMin = Number(item.min_amount);
                    // let amountMax = Number(item.max_amount);
                    // let step = Number(item.step);

                    // let position = 1;
                    // let list = null;
                    // for (let amount = amountMin; amount <= amountMax; amount += step) {
                    //     if(position == 1) {
                    //         list = amount;
                    //     } else {
                    //         list = list+';'+amount;
                    //     }
                    //     position++;
                    // }
                    // this.amountsObj.push(list);
                    this.budgetsObj.push(budgetObj);
                });
                this.getAmountSum();
                this.inProgress = false;
            },
            // updateBudget: function() {
            //     this.getAmountSum();
                
            //     this.$emit('update-budget', this.budgetsObj);
            // },
            updatedPercentage(customerId){
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'percentage/' + customerId,{
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.token
                        }
                    })
                    .then((response) => {
                        
                    })
                    .catch((error) => {
                        console.log('Error percentage', error)
                    });
            },
            archivated: function(customerId){
                var app = this
                this.$store.dispatch('archiveAccount', customerId)
                    .then((response) => {
                    })
                    .catch((error) => {
                    });
            },
            updateBudget: function() {
                this.budgetsObj.forEach(element => {
                        element.real_amount= Number(Math.round(element.real_amount.replace(' ','')));
                        element.min_amount = Number(Math.round(element.min_amount.replace(' ','')));
                        element.max_amount= Number(Math.round(element.max_amount.replace(' ','')));
                });

                
                // this.inProgress = true;
                axios.post(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'budgets/events', this.budgetsObj, {
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.token
                        }
                })
                .then((response) => {
                    
                    // this.inProgress = false;
                    this.getAmountSum();
                    this.$createToast(
                        {
                        title: 'Operation reussie !',
                        description: "Informations enregistrées avec succès !  !"
                        },
                        {
                        position: 'top-center',
                        type: 'success',
                        timeout: 10000,
                        transition: 'bounce',
                        })
                    this.updatedPercentage(response.data.customer_id)
                    this.archivated(response.data.customer_id)
                    this.getBudgets(response.data.id);
                })
                .catch((error) => {
                    console.log('error', error.response.data);
                    this.$createToast(
                        {
                        title: 'Attention !',
                        description: "Erreur lors de l'enregistrement des informations !"
                        },
                        {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                        })
                    this.inProgress = false;
                });
            },
            getAmountSum: function() {

                this.realAmountSum = 0;
                this.minAmountSum = 0;
                this.maxAmountSum = 0;
                this.budgetsObj.forEach(element => {

                    this.realAmountSum += Number(element.real_amount);
                    this.minAmountSum += Number(element.min_amount);
                    this.maxAmountSum += Number(element.max_amount);

                    this.TotalrealAmountSum += Number(element.real_amount);
                    this.TotalminAmountSum += Number(element.min_amount);
                    this.TotalmaxAmountSum += Number(element.max_amount);
                    
                });

            },
            goToCategoryProduct: function(categoryProductId,budgetId) {
                window.open('/annuaires-des-prestataires-de-services-mariage-en-cote-divoire/'+ categoryProductId+'/'+budgetId);
            }
        }
    }
</script>