<template>
  <div class="card">
    <h5 class="title">Filtre</h5>

    <div class="filter-group">
      <div class="card-header">
        <a
          href="#"
          data-toggle="collapse"
          data-target="#collapse_1"
          aria-expanded="true"
          class=""
        >
          <div class="col-12 d-flex">
            <h6 class="title">Categories de services</h6>
            <i class="icon-control fa fa-arrow-up"></i>
          </div>
        </a>
      </div>
      <div class="filter-content collapse show" id="collapse_1" style="">
        <div class="card-body">
          <!-- <form class="pb-3">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-light" type="button">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form> -->

          <ul class="list-menu" v-for="(event,index) in events">
            <li>
              <input type="checkbox" :id="'scales'+index" :value="event.id" name="scales" v-model="filter.event_type_id" />
              <label :for="'scales'+index">{{ event.name }}</label>
            </li>
          </ul>
        </div>
        <!-- card-body.// -->
      </div>
    </div>
    <!-- filter-group  .// -->
    <div class="filter-group">
      <div class="card-header">
        <a
          href="#"
          data-toggle="collapse"
          data-target="#collapse_2"
          aria-expanded="true"
          class=""
        >
          <div class="d-flex col-12">
            <h6 class="title">Evenements</h6>
            <i class="icon-control fa fa-chevron-down"></i>
          </div>
        </a>
      </div>
      <div class="filter-content collapse show" id="collapse_2" style="">
        <div class="card-body">
          <ul class="list-menu" v-for="(cat,index) in categories">
            <li>
              <input type="checkbox" :id="'cat'+index" :value="cat.id" name="scales" v-model="filter.service_type_id"/>
              <label :for="'cat'+index">{{ cat.name }}</label>
            </li>
            <!-- <li><a href="#">Watches </a></li>
            <li><a href="#">Cinema </a></li>
            <li><a href="#">Clothes </a></li>
            <li><a href="#">Home items </a></li>
            <li><a href="#">Animals</a></li>
            <li><a href="#">People </a></li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="filter-group">
      <div class="card-header">
        <a
          href="#"
          data-toggle="collapse"
          data-target="#collapse_3"
          aria-expanded="true"
          class=""
        >
          <div class="d-flex col-12">
            <h6 class="title">Prix</h6>
            <i class="icon-control fa fa-chevron-down"></i>
          </div>
        </a>
      </div>
      <div class="filter-content collapse show" id="collapse_3" style="">
        <div class="card-body">
          <!-- <input type="range" class="custom-range" min="0" max="100" name="" /> -->
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Min</label>
              <input class="form-control" placeholder="0" type="number" v-model="filter.min_price" />
            </div>
            <div class="form-group text-right col-md-6">
              <label>Max</label>
              <input class="form-control" placeholder="10000000" type="number" v-model="filter.max_price" />
            </div>
          </div>
          <!-- form-row.// -->
        </div>
        <!-- card-body.// -->
      </div>
      <button class="btn btn-block btn-primary" @click="applyFilter">Appliquer</button>
    </div>
  </div>
</template>
<script>
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";

export default {
  // name: '',
  components: {
    // 'filter-template': FilterTemplate
  },
  data() {
    return {
      events: null,
      categories: null,
      priceRange: null,
      filter:{
        event_type_id:[],
        service_type_id:[],
        min_price:null,
        max_price:null
      }
      // filter: null
    };
  },
  mounted() {
    this.getEvents();
    this.getCategories();

  },
  methods: {
    getEvents() {
      let url = "wedding-provider-service-types";
      this.isLoading = true;
      let app = this;
      this.$http
        .get(PROVIDER.DEV_URL + API_PREFIX + API_VERSION + url)
        .then((response) => {
          this.events = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    getCategories() {
      let url = "get-event-types";
      let app = this;
      this.$http
        .get(PROVIDER.DEV_URL + API_PREFIX + API_VERSION + url)
        .then((response) => {
          this.categories = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    applyFilter(){
        this.$emit('filter-group',{
            event_type_id: JSON.stringify(this.filter.event_type_id),
            service_type_id: JSON.stringify(this.filter.service_type_id),
            min_price:this.filter.min_price,
            max_price:this.filter.max_price
        })
    }
  },
};
</script>
<style></style>
