<template>
    <div v-if="marriedProps">
        <div v-if="(index % 2) == 0">
            <br>
            <div class="married-container">
                <div class="row" v-if="isLoading == false && married">
                    
                    <div class="col-md-4" :style=" image ? '': married.image ? '' : 'background-color: #f2f2f2'" >
                        <div  >
                            <!-- <div class="helper"></div> -->
                            <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop">
                                <div class="helper"></div>
                                <div class=" display-inline align-center" v-if="image" v-bind:class="{ 'image': true }">
                                    <img :src="image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                <label v-else-if="!married.image" class="btn display-inline">
                                        Glissez-Deposer
                                        Choisir une image
                                    <input type="file" name="image" @change="onChange">
                                </label>
                                
                                <div class=" display-inline align-center" v-else v-bind:class="{ 'image': true }">
                                    <img :src="NUPTIAL_NOTEBOOK.DEV_URL+married.image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <img v-if="marriedProps.gender == 'F'" style="width:100%;height:100%;" src="/images/nuptial_notebook/married-1.jpg" alt="">
                        <img v-else style="width:100%;height:100%;" src="/images/nuptial_notebook/married-2.jpg" alt=""> -->
                    </div>
                    <div class="p-3 col-md-8">
                        <div class="col-md-12">
                            <sub-title 
                                :label="marriedProps.name"
                                :gender="marriedProps.gender"
                            />
                        </div>
                        <div class="col-md-12">
                            <Form @submit="updateMarried">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="">Civilité <span class="text-danger">*</span></label>
                                            <input type="alpha_spaces" v-model="married.civility" class="form-control" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            label="Nom" name="last_name" type="alpha_spaces"
                                            :rules="'required'" :isRequired="true"
                                            v-model="married.last_name"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <text-input
                                            label="Prénoms" name="given_names" type="alpha_spaces"
                                            :rules="'required'" :isRequired="true"
                                            v-model="married.given_names"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            label="Email" name="email" type="email"
                                            :rules="'required|email'" :isRequired="true"
                                            v-model="married.email"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6" style="text-align: left;">
                                        <masked-input
                                            label="Nº de téléphone principal" name="phone_number_1" type="numeric"
                                            :rules="'required|min:21|max:22'" :isRequired="true" 
                                            :mask="'+{(000)} 00 00 00 00 00'"
                                            v-model="married.phone_number_1" placeholder="(+225) 01 00 00 00 01"
                                        />
                                    </div>
                                    <div class="col-md-6" style="text-align: left;">
                                        <masked-input
                                            label="Nº de téléphone secondaire" name="phone_number_2" type="numeric"
                                            :rules="'min:21|max:22'" :isRequired="false" 
                                            :mask="'+{(000)} 00 00 00 00 00'"
                                            v-model="married.phone_number_2" placeholder="(+225) 01 00 00 00 01"
                                            
                                        />
                                    </div>
                                </div>
                                <center>
                                    <button class="primary-button" type="submit">ENREGISTRER</button>&nbsp;
                                    <button class="secondary-button">ANNULER</button>
                                </center>
                            </Form>
                        </div>
                    </div>
                </div>
                <div style="padding:50px;" v-else>
                    <center>
                        <spinner  />
                    </center>
                </div>
            </div>
            <br><br>
        </div>
        <div v-else>
            <br>
            <div class="married-container">
                <div class="row" v-if="isLoading == false && married">
                    <div class="p-3 col-md-8">
                        <div class="col-md-12">
                            <sub-title 
                                :label="marriedProps.name"
                                :gender="marriedProps.gender"
                            />
                        </div>
                        <Form @submit="updateMarried">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <!-- <select-input
                                            label="Civilité" name="civility" type="alpha_spaces"
                                            :rules="'required'" :isRequired="true" :options="civilities"
                                            v-model="married.civility" :selectItem="civilities[0]"
                                        /> -->
                                        <div class="form-group">
                                            <label for="">Civilité <span class="text-danger">*</span></label>
                                            <input type="text" v-model="married.civility" class="form-control" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            label="Nom" name="last_name" type="alpha_spaces"
                                            :rules="'required'" :isRequired="true"
                                            v-model="married.last_name"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <text-input
                                            label="Prénoms" name="given_names" type="alpha_spaces"
                                            :rules="'required'" :isRequired="true"
                                            v-model="married.given_names"
                                            
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            label="Email" name="email" type="email"
                                            :rules="'required|email'" :isRequired="true"
                                            v-model="married.email"
                                            
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <masked-input
                                            label="Nº de téléphone principal" name="phone_number_1" type="numeric"
                                            :rules="'required|min:21|max:22'" :isRequired="true" 
                                            :mask="'+{(000)} 00 00 00 00 00'"
                                            v-model="married.phone_number_1" placeholder="(+225) 01 00 00 00 01"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <masked-input
                                            label="Nº de téléphone secondaire" name="phone_number_2" type="numeric"
                                            :rules="'min:21|max:22'" :isRequired="false" 
                                            :mask="'+{(000)} 00 00 00 00 00'"
                                            v-model="married.phone_number_2" placeholder="(+225) 01 00 00 00 01"
                                        />
                                    </div>
                                </div>
                                <center>
                                    <button class="primary-button" type="submit" >ENREGISTRER</button>&nbsp;
                                    <button class="secondary-button">ANNULER</button>
                                </center>
                            </div>
                        </Form>
                    </div>
                    <div class="col-md-4" :style=" image ? '': married.image ? '' : 'background-color: #f2f2f2'">
                        
                        <div >
                            <div class="helper"></div>
                            <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop">
                                <div class="helper"></div>
                                <div class=" display-inline align-center" v-if="image" v-bind:class="{ 'image': true }">
                                    <img :src="image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                <label v-else-if="!married.image" class="btn display-inline">
                                        Choisir une image
                                    <input type="file" name="image" @change="onChange">
                                </label>
                                
                                <div class=" display-inline align-center" v-else v-bind:class="{ 'image': true }">
                                    <img :src="NUPTIAL_NOTEBOOK.DEV_URL+married.image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                
                            </div>
                        </div>
                        <!-- <img v-if="marriedProps.gender == 'F'" style="width:100%;height:100%;" src="/images/nuptial_notebook/married-1.jpg" alt="">
                        <img v-else style="width:100%;height:100%;" src="/images/nuptial_notebook/married-2.jpg" alt=""> -->
                    </div>
                </div>
                <div style="padding:50px;" v-else>
                    <center>
                        <spinner  />
                    </center>
                </div>
            </div>
            <br><br>
        </div>
    </div>
</template>

<style scoped>
    .married-container {
        -webkit-box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        -moz-box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        background: #fff;
    }
</style>
<script setup>
import { Form } from 'vee-validate';

</script>
<script>
    import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from 'axios';
    
    export default {
        name: 'MarriedComponent',
        props: ['marriedProps', 'index'],
        components: {
            Form,
            
            // vueDropzone: vue2Dropzone
        },
        data: function() {
            return {
                NUPTIAL_NOTEBOOK : NUPTIAL_NOTEBOOK,
                civilities: [{
                    id: 'M',
                    name: 'Monsieur'
                }, {
                    id: 'Mlle',
                    name: 'Mademoiselle'
                }],
                married:null,
                isLoading: false,
                image: '',
                imageUpload:null,
            };
        },
        mounted: function() {
            this.married = {
                event_id: this.marriedProps.pivot.event_id,
                married_id: this.marriedProps.pivot.married_id,
                civility: (this.marriedProps.gender == 'F') ? 'Mademoiselle' : 'Monsieur',
                last_name: this.marriedProps.pivot.last_name,
                given_names: this.marriedProps.pivot.given_names,
                email: this.marriedProps.pivot.email,
                phone_number_1: this.marriedProps.pivot.phone_number_1,
                phone_number_2: (this.marriedProps.pivot.phone_number_2 == null) ? this.marriedProps.pivot.phone_number_2 : '',
                image: this.marriedProps.pivot.image
            };
            console.log(this.marriedProps)
        },
        computed: {
            //
        },
        methods: {
            onDrop: function(e) {
                e.stopPropagation();
                e.preventDefault();
                console.log(files[0])
                this.image = files[0]
                this.imageUpload = files[0]
                var files = e.dataTransfer.files;
                this.createFile(files[0]);
            },
            onChange(e) {
                var files = e.target.files;
                console.log(files[0])
                this.image = files[0]
                this.imageUpload = files[0]
                this.createFile(files[0]);
            },
            createFile(file) {
                if (!file.type.match('image.*')) {
                alert('Select an image');
                return;
                }
                var img = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = function(e) {
                  vm.image = e.target.result;
                }
                reader.readAsDataURL(file);
            },
            removeFile() {
                this.married.image = '';
                this.image = '';
            },
            updatedPercentage(customerId){
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'percentage/' + customerId,{
                        headers: {
                            Authorization: "Bearer " + this.$store.state.token
                        }
                    })
                    .then(() => {
                    })
                    .catch((error) => {
                        console.log('Error', error)
                    });
            },
            archivated: function(customerId){
                // var app = this
                this.$store.dispatch('archiveAccount', customerId)
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
            updateMarried: function() {
                            
                this.isLoading = true;
                let marriedForm = new FormData;
                marriedForm.append('event_id', this.married.event_id);
                marriedForm.append('married_id', this.married.married_id);
                marriedForm.append('civility', this.married.civility);
                marriedForm.append('last_name', this.married.last_name);
                marriedForm.append('given_names', this.married.given_names);
                marriedForm.append('email', this.married.email);
                marriedForm.append('phone_number_1', this.married.phone_number_1);
                marriedForm.append('phone_number_2', this.married.phone_number_2);
                marriedForm.append('image', this.imageUpload);

                axios.post(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'marrieds/events', marriedForm, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.token,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Informations enregistrées avec succès !  !"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    })
                    
                    this.isLoading = false;
                    this.updatedPercentage(response.data.customer_id)
                    this.archivated(response.data.customer_id)
                })
                .catch((error) => {
                    console.log('error', error.response.data);
                    this.isLoading = false;
                    this.$createToast(
                    {
                        title: 'Operation échouée!',
                        description: "Erreur lors de l'enregistrement des informations   !"
                    },
                    {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                    })
                    
                });
                    
            },
        }

    }

</script>
<style scoped>

.btn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  /* padding: 15px 35px; */
  /* position: relative; */

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border-radius: 5px;
  text-align: center;
}

.display-inline:hover .hidden {
    display: block !important;
}


input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none ;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {

  border-radius: 2px;
  width: 100%;
}

</style>