<template>
<div class="flex">
  <!-- <i class="text-gray-400"></i> -->
  <!-- <i style='font-size:24px' class="fa">&#xf651;</i> -->
  <svg fill="#b0b0b0" width="30px" height="30px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#b0b0b0"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256 32C114.62 32 0 125.12 0 240c0 49.56 21.41 95.01 57.02 130.74C44.46 421.05 2.7 465.97 2.2 466.5A7.995 7.995 0 0 0 8 480c66.26 0 115.99-31.75 140.6-51.38C181.29 440.93 217.59 448 256 448c141.38 0 256-93.12 256-208S397.38 32 256 32zm24 302.44V352c0 8.84-7.16 16-16 16h-16c-8.84 0-16-7.16-16-16v-17.73c-11.42-1.35-22.28-5.19-31.78-11.46-6.22-4.11-6.82-13.11-1.55-18.38l17.52-17.52c3.74-3.74 9.31-4.24 14.11-2.03 3.18 1.46 6.66 2.22 10.26 2.22h32.78c4.66 0 8.44-3.78 8.44-8.42 0-3.75-2.52-7.08-6.12-8.11l-50.07-14.3c-22.25-6.35-40.01-24.71-42.91-47.67-4.05-32.07 19.03-59.43 49.32-63.05V128c0-8.84 7.16-16 16-16h16c8.84 0 16 7.16 16 16v17.73c11.42 1.35 22.28 5.19 31.78 11.46 6.22 4.11 6.82 13.11 1.55 18.38l-17.52 17.52c-3.74 3.74-9.31 4.24-14.11 2.03a24.516 24.516 0 0 0-10.26-2.22h-32.78c-4.66 0-8.44 3.78-8.44 8.42 0 3.75 2.52 7.08 6.12 8.11l50.07 14.3c22.25 6.36 40.01 24.71 42.91 47.67 4.05 32.06-19.03 59.42-49.32 63.04z"></path></g></svg>
  <div class="flex flex-col text-xs">
    <span class="font-semibold">{{ label }}</span>
    <span v-if="Number(value) >0">{{ formatPrice(value)  }} FCFA</span>
    <span v-else>Non spécifié</span>
  </div>
</div>
</template>

<script>
export default {
  name: "price-item",
  props: ['label', 'value'],
  methods: {
    formatPrice(value) {
                let val = (value/1).toFixed(0).replace(' ', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  }
}
</script>

<style scoped>

</style>