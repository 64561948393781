<template>
  <div class="bg-container-guests">
        <Bar />  
   
    <div class="container p-3" style="padding: 25px;">
      <big-title label="INVITÉS" />
      <br />
      <!-- <p class="text-center">
        Qui sont ceux que vous comptez convier à votre jour de joie ?
        <br />Élaborez facilement votre liste d'invités et mettez-la à jour, que
        ce soit depuis votre téléphone ou votre ordinateur.
      </p> -->
      <p class="text-center">
        C’est l’une des tâches qui laisse perplexe la plupart des futurs
        mariés.<br />
        Le listing des invités, un vrai « casse-tête ». <br />
        De loin l’organisation du mariage ne semble pas aussi compliquée,<br />
        car il faut le dire, fraîchement fiancé, on est encore sur son petit
        nuage !
      </p>

      <div class="text-center">
        <p class="collapse" id="collapseG">
          Mais établir la liste d’invités peut vite nous ramener sur terre, et
          même être source de quelques disputes. <br />
          Plein de questions se posent : <br />
          combien d’invités on veut avoir, comment répartir le nombre d’invités.
          <br />
          D’un autre côté quand on a défini le nombre d’invités il faut recenser
          les informations nécessaires pour les contacter. <br />
          La rubrique “Invités” consiste à élaborer plus facilement votre liste
          d'invités et la mettre à jour,<br />
          que ce soit depuis votre téléphone ou votre ordinateur. <br />
          Ne laissez aucun détail de votre organisation de mariage vous échapper
          ! C’est la clé d’un mariage réussi ! <br />
          <br />
          <strong>Le saviez-vous : </strong><br />
          Vous devez envoyer vos invitations idéalement 3 mois à avant la date
          de votre cérémonie
        </p>
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseG"
          role="button"
          aria-expanded="false"
          aria-controls="collapseG"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <br />
      <div v-if="isLoading == true">
        <spinner  /><br /><br />
      </div>
      <div v-else-if="user.email_verified_at != null && customer.dob != null">
        <div class="guests-container p-3">
          <div class="row">
            <div
              class="col-md-12"
              style="text-align:right;padding-right:30px;padding-bottom:10px;"
            >
              <button class="primary-button" @click="create">
                Ajouter un invité
              </button>
              &nbsp;
              <button
                class="secondary-button"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                Importer un fichier
              </button>

              <div
                class="modal fade pt-5"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered pt-5" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalCenterTitle">
                        IMPORTER UN FICHIER EXCEL (XLS)
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <div
                          class="alert alert-info text-center"
                          v-if="success == true"
                        >
                          <p>Données enregistrés avec succès !</p>
                        </div>
                        <div
                          class="form-group"
                          style="text-align:left;"
                          v-if="isFileImport == false && success == false"
                        >
                          <label for>Fichier *</label>&nbsp;
                          <button
                            class="btn btn-success btn-sm pull-right"
                            @click="downloadTemplate"
                          >
                            Télécharger le Modèle
                            <i style="color:#fff;" class="fa fa-download"></i>
                          </button>
                          <input
                            
                            type="file"
                            class="form-control"
                            ref="guestsFile"
                            @change="onFileChange"
                            name="guestsFile"
                          />
                          <small class="invalid-feedback"></small>
                        </div>
                        <div
                          v-else-if="isFileImport == true && success == false"
                        >
                          <spinner  />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="secondary-button"
                        data-dismiss="modal"
                      >
                        FERMER
                      </button>
                      <button @click="importTemplate" class="primary-button">
                        ENREGISTRER
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="summary == null">
              <div class="alert alert-warning text-center">
                <p>
                  Pensez à correctement renseigner les informations de la
                  rubrique
                  <router-link
                    to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref"
                    >en bref</router-link
                  >.
                </p>
              </div>
            </div>
            <div class="col-md-12" v-else-if="summary != null">
              <div
                class="alert alert-warning text-center"
                v-if="summary == 'Moins de 100' && guestsCount <= 10"
              >
                <p>
                  Juste un peu d'effort pour renseigner la liste de vos invités.
                  Vous avez initialement prévu {{ summary }} invités et vous
                  n'avez saisi que {{ guestsCount }} invités.
                </p>
              </div>

              <div
                class="alert alert-warning text-center"
                v-if="summary == '100 - 200' && guestsCount < 100"
              >
                <p>
                  Juste un peu d'effort pour renseigner la liste de vos invités.
                  Vous avez initialement prévu {{ summary }} invités et vous
                  n'avez saisi que {{ guestsCount }} invités.
                </p>
              </div>
              <div
                class="alert alert-warning text-center"
                v-if="summary == '200 - 300' && guestsCount < 200"
              >
                <p>
                  Juste un peu d'effort pour renseigner la liste de vos invités.
                  Vous avez initialement prévu {{ summary }} invités et vous
                  n'avez saisi que {{ guestsCount }} invités.
                </p>
              </div>
              <div
                class="alert alert-warning text-center"
                v-if="summary == '300 - 400' && guestsCount < 300"
              >
                <p>
                  Juste un peu d'effort pour renseigner la liste de vos invités.
                  Vous avez initialement prévu {{ summary }} invités et vous
                  n'avez saisi que {{ guestsCount }} invités.
                </p>
              </div>
              <div
                class="alert alert-warning text-center"
                v-if="summary == '400 - 500' && guestsCount < 400"
              >
                <p>
                  Juste un peu d'effort pour renseigner la liste de vos invités.
                  Vous avez initialement prévu {{ summary }} invités et vous
                  n'avez saisi que {{ guestsCount }} invités.
                </p>
              </div>
              <div
                class="alert alert-warning text-center"
                v-if="summary == 'Supérieur à 500' && guestsCount < 500"
              >
                <p>
                  Juste un peu d'effort pour renseigner la liste de vos invités.
                  Vous avez initialement prévu {{ summary }} invités et vous
                  n'avez saisi que {{ guestsCount }} invités.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="card-body">
              <div
                class="alert alert-info text-center text-muted"
                v-if="rows.length == 0 && isLoading == false"
              >
                Aucun invité enregistré !
              </div>
              <div class="table-responsive" v-else>
                <div class="alert-success">
                  Confirmé : {{ confirmed }}
                </div>
                <div class="alert-danger">
                  Non Confirmé : {{ notConfirmed }}
                </div>
                
                <!-- <mdb-container responsive v-if="!isLoading">
                  <mdb-datatable responsive :data="data" hover bordered />
                </mdb-container>
                <div v-else>
                  <spinner  />
                </div> -->
                <DataTable  class="display" width="100%">
                    <thead>
                        <tr>
                            <th>Civilité</th>
                            <th>Nom</th>
                            <th>Prenom</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Status</th>
                            <th>Presence</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(guest,index) in rows" :key="index">
                        <td >{{ guest.civility }}</td>
                        <td >{{ guest.last_name }}</td>
                        <td>{{ guest.given_names }}</td>
                        <td>{{ guest.email }}</td>
                        <td>{{ guest.phone_number_1 }}</td>
                        <td>{{ guest.status }}</td>
                        <td v-if="Number(guest.has_confirmed)"><span class="badge badge-success">Confirmé</span></td>
                        <td v-else><span class="badge badge-secondary">pas encore confirmé</span></td>
                        <td>
                          <a :href="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-invites-pour-le-mariage/' +
                        guest.actions
                        " class="btn btn-link btn-sm" data-toggle="tooltip" data-placement="top" title="Modifier"><i class="fa fa-edit"></i></a>
                        </td>
                      </tr>
                    </tbody>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
      <center>
        <button class="default-button" @click="goToPrevious">PRÉCEDENT</button
        >&nbsp;
        <button class="default-button" @click="goToNext">SUIVANT</button>
      </center>
    </div>
  </div>
</template>
<script setup>
import DataTablesCore from 'datatables.net';
import DataTable from 'datatables.net-vue3';

DataTable.use(DataTablesCore);

const data = [
  [1, 2],
  [3, 4],
];
</script>
<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import { API_PREFIX, API_VERSION, AUTH, NUPTIAL_NOTEBOOK } from "@/config";
import axios from "axios";
import { mdbContainer, mdbDatatable } from "mdbvue";
import { useMeta } from 'vue-meta';
export default {
  name: "GuestsShow",
  setup () {
    useMeta({
      title:
      "Carnet Nuptial - Invités - Établissez en toute quiétude, la liste des invités de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment faire sa liste d’invités à mon mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, organisation de mariage, combien d’invités à mon mariage, predstataire de mariage, comment faire sa liste d’invités, agencement des invités dans la salle de réception, grand espace évènementiel, carnet nuptial, hôtesses, plan de table de mariage,",
        },
      ],
    })
  },

  components: {
    Bar,
    mdbDatatable,
    mdbContainer,
  },
  data() {
    return {
      showModal:false,
      isFileImport: false,
      guests: null,
      guestsCount: null,
      isLoading: false,
      customer: {dob:null},
      user: JSON.parse(this.$store.state.user),
      success: false,
      summary: null,
      textFullDisplay: false,
      confirmed:0,
      notConfirmed:0,
      columns: [
        { label: "Civilité", field: "civility", sort: "desc" },
        { label: "Nom ", field: "last_name", sort: "desc" },
        { label: "Prenoms", field: "given_names", sort: "desc" },
        { label: "Email", field: "email", sort: "desc" },
        { label: "Contact", field: "phone_number_1", sort: "desc" },
        { label: "Statut", field: "status", sort: "desc" },
        {
          label: "Presence",
          field: "has_confirmed",
          sort: "desc",
          format: (value) =>
            value == 1
              ? '<span class="badge badge-success">Confirmée</span>'
              : '<span class="badge badge-secondary">Pas encore confirmée</span>',
        },
        {
          label: "Actions",
          field: "actions",
          sort: "desc",
        },
      ],
      rows: [],
    };
  },
  watchers: {
    //
  },
  computed: {
    data() {
      return {
        columns: this.columns,
        rows: this.rows,
      };
    },
  },
  mounted: function() {
    var app = this;
    this.getCustomerInfo()
      .then((customer) => {
        app.eventId = customer.event.id;
        this.getGuests();
        this.getGuestsCount(app.eventId);
        this.getSummaries();
      })
      .catch(() => {});
    this.show();
  },
  methods: {
    renderShowButton(value) {
      return <i class="fa fa-edit">{{ value }}</i>;
    },
    hasConfirmed(value, id) {
      return new Promise((resolve, reject) => {
        let data = {
          event_guest_id: id,
          has_confirmed: value,
        };
        axios
          .post(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "guests-hasconfirmed-update",
            data,
            { headers: { Authorization: "Bearer " + this.$store.state.token } }
          )
          .then((response) => {
            resolve(response.data);
            
            this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Informations ajouté avec succès !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
              })
          })
          .catch((error) => {
            reject(error);
            
            this.$createToast(
              {
              title: 'Attention !',
              description: "Erreur lors de l'enregistrement de l'information  !"
              },
              {
              position: 'top-center',
              type: 'error',
              timeout: 10000,
              transition: 'bounce',
              })
          });
      });
    },
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            this.isLoading = false;
            reject(error);
          });
      });
    },
    goToPrevious: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage"
      );
    },
    goToNext: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
      );
    },
    create: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/creation-invites-pour-le-mariage/" +
          this.customer.event.id
      );
    },
    edit: function(guestPivotId) {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-invites-pour-le-mariage/" +
          guestPivotId
      );
    },
    getGuests: function() {
      let app = this;
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests-by-event",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          response.data.map((guest) => {
            app.rows.push({
              status: guest.name,
              civility: guest.pivot.civility,
              last_name: guest.pivot.last_name,
              given_names: guest.pivot.given_names,
              email: guest.pivot.email,
              phone_number_1: guest.pivot.phone_number_1,
              phone_number_2: guest.pivot.phone_number_2,
              has_confirmed: guest.pivot.has_confirmed,
              actions:guest.pivot.id 
            })
            if (Number(guest.pivot.has_confirmed)) {
              app.confirmed++
            } else {
              app.notConfirmed++
            }
          }
            
          );
          app.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getGuestsCount: function(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests-by-event-get/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.guestsCount = response.data.length;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getSummaries() {
      this.isLoading = true;
      let data = {
        label: "Nombre d'invités prévus",
      };
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "summaries-by-label",
          data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.summary = response.data.pivot.response;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    downloadTemplate: function() {
      window.open("/files/JOURS_DE_JOIE_GUESTS_TEMPLATE.xls", "_blank");
    },
    onFileChange: function() {
      this.guestsFile = this.$refs.guestsFile.files[0];
    },
    importTemplate: function() {
      // this.$validator.validateAll().then((result) => {
      //   if (result) {
          this.isFileImport = true;
          let file = new FormData();
          file.append("guests_file", this.guestsFile);

          axios
            .post(
              NUPTIAL_NOTEBOOK.DEV_URL +
                API_PREFIX +
                API_VERSION +
                "guests-events-import",
              file,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.isFileImport = false;
              this.success = response.data.success;
              this.$router.go();
            })
            .catch((error) => {
              this.isFileImport = false;
              if (error.response.status === 422) {
                  this.$createToast(
                  {
                  title: 'Attention !',
                  description: "Oups... Une erreur a été détectée dans le fichier. Veuillez le remplir correctement tous les champs s.v.p."
                  },
                  {
                  position: 'top-center',
                  type: 'error',
                  timeout: 10000,
                  transition: 'bounce',
                  })
              }
            });
      //   }
      // });
    },
    deleteGuest: function(item) {
      this.$swal({
        title: "Confirmation",
        text: "Êtes-vous sûre de vouloir supprimer cet invité ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, je le souhaite",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.value) {
          axios
            .get(
              NUPTIAL_NOTEBOOK.DEV_URL +
                API_PREFIX +
                API_VERSION +
                "guests-events-delete/" +
                item.id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(() => {
              this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Invité supprimé avec succès !"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    }
                )
              this.getCustomerInfo()
                .then((customer) => {
                  this.getGuests(customer.event.id);
                })
                .catch(() => {});
            })
            .catch(() => {
            });
        }
      });
    },
    resendMailAccountVerification: function(customerEmail) {
      this.isLoading = true;
      axios
        .get(
          AUTH.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "send-email-verification/" +
            customerEmail,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          
          this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Mail envoyé avec succès !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
              }
          )
          this.isLoading = false;
        })
        .catch(() => {
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'envoie de mail !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>

<style scoped>
.guests-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
}
.icon {
  color: #fff;
}

.bg-container-guests {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-guests .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-guests::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/guests.png");
  background-size: cover;
  opacity: 0.1;
}
@import 'datatables.net-dt';
</style>
