import { getFavoritesAPI, updateFavoriteAPI } from "@/views/wedding-provider/apis";

export const state = () => ({
    isLoading: false,
    errors: {},
    errorMessage: null,
    favorites: [],
    favoritesListe: [],
})

export const actions = {
    async getFavorites({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getFavoritesAPI(params);
            commit('setFavorites', response);
            commit('setLoading', false);
        } catch (error) {
            commit('setLoading', false);
            commit('setFavorites', []);
            commit('setErrors', error.errors);
            throw error.errors
        }
    },
    async updateFavorite({ commit }, data, params = {}) {
        commit('setLoading', true);
        try {
            await updateFavoriteAPI(data, params);
            commit('setLoading', false);
        } catch (error) {
            commit('setLoading', false);
            commit('setErrors', error.errors);
            commit('setErrorMessage', error.message);
            throw error.errors
        }
    },
}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setErrorMessage(state, value) {
        state.errorMessage = value
    },
    setFavorites(state, value) {
        state.favorites = value.data
        state.favoritesListe = value.otherData
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}