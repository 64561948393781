<template>
  <div>
    <Breadcrumb bgClass="bg-image--visibility" titre=""></Breadcrumb>
    <div class="container pt-5">
      <div class="row align-self-center">
        <div class="col-md-6 align-self-center">
          <p class="text-justify" style="font-size: 16px">
            <span class="font-weight-bold">Jours de Joie</span> est l'assistant
            digital qui aide à organiser tous les événements heureux
            <span class="font-weight-bold">
              (anniversaires, mariages, baptêmes , etc). </span
            >pour particuliers et entreprises. Il est un guide idéal pour toutes
            les étapes de l'organisation d'un évènement heureux : de
            l’élaboration du budget à la gestion des invitations, tout en vous
            proposant des prestataires de qualité.

            <br />
            Jours de joie est également une plateforme de mise en relation entre
            les personnes qui souhaitent organiser un évènement heureux et les
            prestataires intervenant de ce domaine.<br />
            Jours de joie, c'est une véritable plateforme de mise en lumière du
            savoir faire des prestataires.<br />
            <br />
          </p>
        </div>
        <div class="col-md-6">
          <img src="/images/image.png" class="img-fluid" width="100%" alt="" />
        </div>
      </div>
      <h3 class="text-center pt-4 pb-3" style="color: #b71c1c">
        Pourquoi devenir partenaire de Jours de joie ?
      </h3>
      <div class="">
        <div class="">
          <div class="card">
            <div class="card-body">
              <!-- <h5 class="card-title">Special title treatment</h5> -->
              <!-- <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> -->
              <div>
                <span
                  class="badge badge-pill"
                  style="background-color: #b71c1c; color: white"
                  >1</span
                >
                Pénétrez un marché sous exploité et comblez les besoins des
                consommateurs.
              </div>
              <div class="">
                <span
                  class="badge badge-pill"
                  style="background-color: #b71c1c; color: white"
                  >2</span
                >
                Bénéficiez d'une mise en avant et un meilleur référencement
                grâce à votre présence sur notre plateforme (Site et
                Application).
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-sm-6">
          <div class="card">
            <div
              class="card-body"
              style="padding-left: 10px; padding-right: 10px; height: 130px"
            >
              <!-- <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
              <div>
                <span
                  class="badge badge-pill"
                  style="background-color: #b71c1c; color: white"
                  >3</span
                >
                Trouvez votre prochain client qui est à la recherche de
                prestataires de qualité.
              </div>
              <div>
                <span
                  class="badge badge-pill"
                  style="background-color: #b71c1c; color: white"
                  >4</span
                >
                Être en relation directement avec vos clients sans vous
                déplacer.
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6" id="ouverturecompte">
          <div class="card">
            <div
              class="card-body"
              style="padding-left: 10px; padding-right: 10px; height: 130px"
            >
              <!-- <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
              <div class="">
                <span
                  class="badge badge-pill"
                  style="background-color: #b71c1c; color: white"
                  >5</span
                >
                Recevoir des opportunités d'affaires qualifié de la part de
                Jours de joie.
              </div>
              <div class="">
                <span
                  class="badge badge-pill"
                  style="background-color: #b71c1c; color: white"
                  >6</span
                >
                Augmentez votre chiffre d'affaires.
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="wrap text-center" id="ouverturecompte">
        <!-- <a href="https://forms.gle/zeYLwNiZ5dmoyrtZ8" target="_bank" class="">
          <button class="button">Ouvrir mon compte prestataire</button>
        </a> -->
        <a href="https://forms.gle/byYwc6KSMaurLVmr5" target="_bank" class="">
          <button class="button">Ouvrir mon compte prestataire</button>
        </a>
      </div>
      <!-- <section class="text-center" sty>
        <a href="https://forms.gle/zeYLwNiZ5dmoyrtZ8" target="_bank" class="btn">
          <span class="btn-content">Ouvrir mon compte prestataire<br></span>
          <span class="icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </a>
      </section> -->
      <br />

      <!-- Banniere -->
      <div class="col-12">
        <img src="/images/1200x300.jpg" alt="" />
      </div>
      <br />
      <h3 style="color: #b71c1c">
        A quelle catégorie de prestataire s'adresse Jours de Joie ?
      </h3>
      <br />
      <div>
        Jours de joie est une véritable opportunité à saisir pour les
        prestataires d'évènement heureux à Abidjan ou partout en Côte
        d'Ivoire. Elle permet de mettre en relation ces prestataires avec des
        propsects et ou clients qualifiés. Il s'agit concrètement de
        prestataires offrant les services suivants :
      </div>
      <div class="d-flex flex-column flex-md-row">
        <!-- <ul> -->
        <div class="col-md-3 col-sm-6">
          <li>Décoration</li>
          <li>Tenue de mariage</li>
          <li>Costume de marié</li>
          <li>Coiffure de mariées</li>
          <li>Coaching pour mariage</li>
        </div>
        <div class="col-md-3 col-sm-6">
          <li>Dragées de mariages</li>
          <li>Cadeaux de mariage</li>
          <li>Accessoires de mariées</li>
          <li>Wedding planner</li>
          <li>Compositions fleurales</li>
        </div>
        <div class="col-md-3 col-sm-6">
          <li>Coiffure mariage</li>
          <li>Gâteau de mariage</li>
          <li>Décoration de noces</li>
          <li>Robe de mariée</li>
          <li>Institut de relaxation</li>
        </div>
        <div class="col-md-3 col-sm-6">
          <li>Tenue de mariage traditionnel</li>
          <li>Faire-Part</li>
          <li>Espace hôtelier</li>
          <li>Institut de beauté</li>
          <li>Services traiteur</li>
        </div>
        <!-- </ul> -->
      </div>
      <br />
      <h3 style="color: #b71c1c">
        Quel est le processus de mise en ligne de votre boutique (Shop) ?
      </h3>
      <div class="container">
        <!-- <h4>Timeline Style : Demo-4</h4> -->
        <div class="row">
          <div class="col-md-12">
            <div class="main-timeline4">
              <div class="timeline">
                <a href="#ouverturecompte" class="timeline-content">
                  <span class="year">1</span>
                  <div class="inner-content">
                    <h3 class="title">Client</h3>
                    <p class="description">
                      Vous faites une demande d'ouverture de compte en cliquant
                      sur le bouton ci-dessus
                      <strong>
                        &lt;&lt; Ouvrir mon compte prestataire >>
                      </strong>
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <span class="year">2</span>
                  <div class="inner-content">
                    <h3 class="title">Jours de joie</h3>
                    <p class="description">
                      Jours de joie reçoit votre demande et crée votre compte
                      prestataire après verification des informations
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <span class="year">3</span>
                  <div class="inner-content">
                    <h3 class="title">Client</h3>
                    <p class="description">
                      Initialisez votre boutique en ligne en entrant dans Joy
                      shop, l'interface de Jours de Joie dédiée aux
                      prestataires. Grâce à cette interface vous indiquerez les
                      informations descriptives de votre boutique en ligne :
                      <em>
                        votre catégorie de service ; votre localisation , vos
                        tarifs, de belles illustartions de vos réalisations
                        (photos/Vidéos).</em
                      >
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <span class="year">4</span>
                  <div class="inner-content">
                    <h3 class="title">Joursdejoie</h3>
                    <p class="description">
                      Jours de joie active votre boutique
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <span class="year">5</span>
                  <div class="inner-content">
                    <h3 class="title">Client</h3>
                    <p class="description">
                      Vous payez votre abonnement et vous êtes automatiquement
                      visible sur l'annuaire des prestataires de jours de joie
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="wrap text-center" id="appjoursdejoie">
          <!-- <a href="https://forms.gle/zeYLwNiZ5dmoyrtZ8" target="_bank" class="">
            <button class="button">Ouvrir mon compte prestataire</button>
          </a> -->

          <!-- <router-link
            class=""
            to="/telecharger-application-mobile-joursdejoie"
          > -->
          <button @click="downloadJoyshop" class="button">
            TELECHARGER L'APPLICATION JOY SHOP
          </button>
          <!-- </router-link> -->
        </div>
      </div>

      <br />

      <!-- <div class="d-flex justify-content-between">
        <div>
          <h4 style="color:#b71c1c;">Conditions de paiement :</h4>
          <p>- 70 % à la commande</p>
          <p>- 30 % à la livraison</p>
          <p>
            <span style="color:#b71c1c;">NB :</span> Validité de l’offre 30
            jours
          </p>
        </div>
 
      </div> -->
      <br />
      <div class="row">
        <div class="col-md-8 col-sm-12">
          <p class="h4 text-justify" style="color: #b71c1c">
            Contactez-nous si vous souhaiter booster votre visibilité et obtenir
            plus de clients (Email: info@joursdejoie.com,Téléphone:
            05-75-44-06-06):
          </p>
          <p class="">
            -Conception de tenues de mariés, de demoiselles et garçons d’honneur
          </p>
          <p class="">
            -Soins esthétiques de mise en beauté , des services de coiffures,
            d’onglerie aux futures mariés
          </p>
          <p class="">
            -Location d’espaces de mariage, de la location de voitures, de
            décorations de mariage
          </p>
          <p class="">
            -Services de joaillerie (bagues de fiançailles, alliances, bijoux,
            etc...)
          </p>
          <p class="">-Impressions de Faire-Parts</p>
          <p class="">-Etc...</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="/images/annuaire.png" alt="" />
        </div>
      </div>
      <br />
      <h3 style="color: #b71c1c">
        Quelles sont les informations dont nous aurons besoin pour booster votre
        visibilité
      </h3>
      <br />
      <div></div>
      <div class="">
        <li>Le nom de votre entreprise</li>
        <li>Vos offres et services</li>
        <li>Vos tarifs</li>
        <li>Vos services ou produits en photo</li>
        <li>Vos contacts</li>
        <li>Vos réseaux sociaux ou site internet</li>
        <li>
          Et toute autres informations permettant de concevoir votre bannière
          publicitaire et vous rendre visible sur Jours de joie, le partenaire
          qui met en avant les prestataires d'évènement heureux.
        </li>
      </div>
      <br />
      <!-- Joy Shop -->
      <hr />
      <br />

      <div
        class="text-center font-weight-bold font-italic"
        style="color: #b71c1c"
      >
        Avec Jours de joie, votre Digital Event Planner, c’est un partenariat
        gagnant, gagnant.
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { useMeta } from "vue-meta";
export default {
  name: "joy-visibility",
  setup() {
    useMeta({
      title: "Joy Shop",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Comment faire sa liste d’invités à mon mariage,organisation de mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, organisation de mariage a abidjan, combien d’invités à mon mariage, comment faire sa liste d’invités, agencement des invités dans la salle de réception, grand espace évènementiel, carnet nuptial, hôtesses, plan de table de mariage, prestataire de mariage",
        },
      ],
    });
  },
  components: {
    Breadcrumb,
  },
  methods: {
    downloadJoyshop() {
      this.getMobileOperatingSystem();
    },
    getMobileOperatingSystem: function () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        console.log("Windows Phone");
      }

      if (/android/i.test(userAgent)) {
        window.open("https://bit.ly/3WryleG", "_blank");
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open("https://bit.ly/3Ya8adL", "_blank");
      }

      console.log("unknown");
    },
  },
};
</script>

<style scoped>
.btn {
  border-radius: 4px;
  border: 2px solid #b71c1c;
  color: #b71c1c;
  display: inline-block;
  margin: 0 0.25em;
  overflow: hidden;
  padding: 24px 60px 24px 16px;
  position: relative;
  text-decoration: none;
  line-height: 1;
}
.btn .btn-content {
  font-size: 1em;
  line-height: 1.2;
  padding: 0 26px;
  position: relative;
  right: 0;
  transition: right 300ms ease;
  display: block;
  text-align: left;
}
.btn .icon {
  border-left: 1px solid #b71c1c;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transition: all 300ms ease;
  transform: translateY(-50%);
  width: 58px;
  height: 70%;
}
.btn .icon i {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.btn:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #b71c1c;
  opacity: 0;
  transition: opacity 300ms ease;
}
.btn:hover .btn-content {
  right: 100%;
}
.btn:hover .icon {
  border-left: 0;
  font-size: 1.8em;
  width: 100%;
}
.btn:hover:after {
  opacity: 0.2;
}
.btn.btn-alt-color {
  border-color: salmon;
  color: salmon;
}
.btn.btn-alt-color .icon {
  border-left-color: salmon;
}
.btn.btn-alt-color:after {
  background-color: salmon;
}

/* new  */

.wrap col-md-8 text-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  /* min-width: 500px; */
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #ffffff;
  background: #b71c1c;
  background: linear-gradient(90deg, #b71c1c 0%, #e7c466 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px #e7c466;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(440px + 12px);
  min-height: calc(50px + 2px);
  /* border: 6px solid #b71c1c; */
  box-shadow: 0 0 60px #e7c567;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.button:hover,
.button:focus {
  color: #ffffff;
  transform: translateY(-6px);
}

button:hover::before,
button:focus::before {
  opacity: 1;
}

button::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 6px solid #b71c1c;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

/* button:hover::after, button:focus::after {
  animation: none;
  display: none;
} */

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

/* Timeline */
.main-timeline4 {
  overflow: hidden;
  position: relative;
}
.main-timeline4:before {
  content: "";
  width: 5px;
  height: 75%;
  background: #333;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.main-timeline4 .timeline-content:before,
.main-timeline4 .timeline:before {
  top: 50%;
  transform: translateY(-50%);
  content: "";
}
.main-timeline4 .timeline {
  width: 50%;
  padding-left: 100px;
  float: right;
  position: relative;
}
.main-timeline4 .timeline:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 5px solid #333;
  position: absolute;
  left: -10px;
}
.main-timeline4 .timeline-content {
  display: block;
  padding-left: 150px;
  position: relative;
}
.main-timeline4 .timeline-content:before {
  width: 90px;
  height: 10px;
  border-top: 7px dotted #333;
  position: absolute;
  left: -92px;
}
.main-timeline4 .year {
  display: inline-block;
  width: 120px;
  height: 120px;
  line-height: 100px;
  border-radius: 50%;
  border: 10px solid #383838;
  font-size: 30px;
  color: #383838;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}
.main-timeline4 .year:before {
  content: "";
  border-left: 20px solid #383838;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  bottom: -13px;
  right: 0;
  transform: rotate(45deg);
}
.main-timeline4 .inner-content {
  padding: 40px 0;
}
.main-timeline4 .title {
  font-size: 24px;
  font-weight: 600;
  color: #383838;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.main-timeline4 .description {
  font-size: 14px;
  color: #6f6f6f;
  margin: 0 0 5px;
  text-align: justify;
}
.main-timeline4 .timeline:nth-child(2n) {
  padding: 0 100px 0 0;
}
.main-timeline4 .timeline:nth-child(2n) .timeline-content:before,
.main-timeline4 .timeline:nth-child(2n) .year,
.main-timeline4 .timeline:nth-child(2n):before {
  left: auto;
  right: -10px;
}
.main-timeline4 .timeline:nth-child(2n) .timeline-content {
  padding: 0 150px 0 0;
}
.main-timeline4 .timeline:nth-child(2n) .timeline-content:before {
  right: -92px;
}
.main-timeline4 .timeline:nth-child(2n) .year {
  right: 0;
}
.main-timeline4 .timeline:nth-child(2n) .year:before {
  right: auto;
  left: 0;
  border-left: none;
  border-right: 20px solid #f54957;
  transform: rotate(-45deg);
}
.main-timeline4 .timeline:nth-child(2) {
  margin-top: 110px;
}
.main-timeline4 .timeline:nth-child(odd) {
  margin: -110px 0 0;
}
.main-timeline4 .timeline:nth-child(even) {
  margin-bottom: 80px;
}
.main-timeline4 .timeline:first-child,
.main-timeline4 .timeline:last-child:nth-child(even) {
  margin: 0;
}
.main-timeline4 .timeline:nth-child(2n) .year {
  border-color: #b71c1c;
  color: #b71c1c;
}
.main-timeline4 .timeline:nth-child(2) .year:before {
  border-right-color: #b71c1c;
}
.main-timeline4 .timeline:nth-child(2n) .title {
  color: #b71c1c;
}
.main-timeline4 .timeline:nth-child(3n) .year {
  border-color: #383838;
  color: #383838;
}
.main-timeline4 .timeline:nth-child(3) .year:before {
  border-left-color: #383838;
}
.main-timeline4 .timeline:nth-child(3n) .title {
  color: #383838;
}
.main-timeline4 .timeline:nth-child(4n) .year {
  border-color: #b71c1c;
  color: #b71c1c;
}
.main-timeline4 .timeline:nth-child(4) .year:before {
  border-right-color: #b71c1c;
}
.main-timeline4 .timeline:nth-child(4n) .title {
  color: #b71c1c;
}
@media only screen and (max-width: 1200px) {
  .main-timeline4 .year {
    top: 50%;
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 990px) {
  .main-timeline4 .timeline {
    padding-left: 75px;
  }
  .main-timeline4 .timeline:nth-child(2n) {
    padding: 0 75px 0 0;
  }
  .main-timeline4 .timeline-content {
    padding-left: 130px;
  }
  .main-timeline4 .timeline:nth-child(2n) .timeline-content {
    padding: 0 130px 0 0;
  }
  .main-timeline4 .timeline-content:before {
    width: 68px;
    left: -68px;
  }
  .main-timeline4 .timeline:nth-child(2n) .timeline-content:before {
    right: -68px;
  }
}
@media only screen and (max-width: 767px) {
  .main-timeline4 {
    overflow: visible;
  }
  .main-timeline4:before {
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(0);
  }
  .main-timeline4 .timeline:before,
  .main-timeline4 .timeline:nth-child(2n):before {
    top: 60px;
    left: -9px;
    transform: translateX(0);
  }
  .main-timeline4 .timeline,
  .main-timeline4 .timeline:nth-child(even),
  .main-timeline4 .timeline:nth-child(odd) {
    width: 100%;
    float: none;
    text-align: center;
    padding: 0;
    margin: 0 0 10px;
  }
  .main-timeline4 .timeline-content,
  .main-timeline4 .timeline:nth-child(2n) .timeline-content {
    padding: 0;
  }
  .main-timeline4 .timeline-content:before,
  .main-timeline4 .timeline:nth-child(2n) .timeline-content:before {
    display: none;
  }
  .main-timeline4 .timeline:nth-child(2n) .year,
  .main-timeline4 .year {
    position: relative;
    transform: translateY(0);
  }
  .main-timeline4 .timeline:nth-child(2n) .year:before,
  .main-timeline4 .year:before {
    border: none;
    border-right: 20px solid #383838;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 50%;
    left: -23px;
    bottom: auto;
    right: auto;
    transform: rotate(0);
  }
  .main-timeline4 .timeline:nth-child(2n) .year:before {
    border-right-color: #b71c1c;
  }
  .main-timeline4 .timeline:nth-child(3n) .year:before {
    border-right-color: #383838;
  }
  .main-timeline4 .timeline:nth-child(4n) .year:before {
    border-right-color: #b71c1c;
  }
  .main-timeline4 .inner-content {
    padding: 10px;
  }
}
</style>
