<template>
    <div>
            <Bar />  
   
        <div class="container pr-5 pl-5" style="padding: 20px;">
            <big-title label="Mes évènements" />
        
            <br />
      <!-- <p class="text-center">
        Les choses se précisent ! Comme son nom l’indique, la rubrique «
        Planning » vous aide à planifier <br />
        les actions importantes de votre mariage. Mettez à jour votre liste de
        tâches, plus rien ne vous échappera !
      </p> -->
      <p class="text-center">
        Bientôt le grand jour. La date de votre mariage est proche.<br /> 
        Ce merveilleux projet auquel vous rêvez depuis longtemps va finalement se réaliser. <br /> 
        Les mois précédents vous avez passé votre temps à réfléchir à son organisation et à sa planification. <br />
        Vous avez élaboré votre budget de mariage, dressé la liste de vos invités et vous leur avez même envoyé des cartons d’invitations. <br />
        Vous vous attendez à être entourés des personnes qui vous sont chères pour célébrer avec elles ce moment de bonheur. <br>
      </p> <br>

      <div class="text-center">
        <div class="collapse"  id="collapseP">
            <p >
                A quels prestataires avez-vous décidé de confiez la réalisation de votre mariage de rêve ? <br>
                Qui est votre Wedding/Event Planner ? Avec qui allez-vous commander vos robes et costumes de mariage ? <br> 
                Qui a confectionné les magnifiques alliances que vous porterez à vie ? Quel service traiteur égayera les papilles de vos convives ? <br>
                Qui exécutera le splendide gâteau de mariage auquel vous avez toujours rêvé… <br />
            </p> <br>
            <p  >
                Avec JOY SHOP de joursdejoie.com, la question ne se pose plus. <br />
                Parcourez JOY SHOP, notre annuaire des professionnels des évènements heureux et <br /> 
                sélectionner les prestataires qui répondront au mieux à vos attentes (qualité/prix).
                
            
            </p> <br>
            <p  >
                Mettez des cœurs sur vos prestataires favoris et vous verrez leurs boutiques s’afficher dans votre carnet nuptial. <br />
                Waouh ! JOY SHOP de joursdejoie.com c’est trop Top...
                
            
            </p>
        </div>
        
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseP"
          role="button"
          aria-expanded="false"
          aria-controls="collapseP"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
            <div class="" v-if="!isLoading" >
                
                <div class="row" v-if="eventListe.length > 0">
                    <!-- <div class="col-3" >
                        <div class="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"  :class="index == click ? 'active' : '' " :id="'list-'+object+'-list'" v-for="(object,index) in favoritesObject" :key="index" data-toggle="list" @click="click = index" :href="'#list-'+object" role="tab" :style=" index == click ? 'background-color: #b71c1c;border: #b71c1c;' : ' ' " :aria-controls="object">{{object}}</a>
                        </div>
                    </div> -->
                    
                    <div class="col-12">
                        <div class="tab-content" id="nav-tabContent">
                            <!-- <div class="tab-pane fade " :id="'list-'+values" v-for="(values,index) in eventListe" role="tabpanel" :aria-labelledby="'list-'+values+'-list'"> -->
                                <div class="row" v-if="!loading">
                                    <router-link class="button button4 col-md-3 text-center"  to="/creer-des-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire">Creer un évènement &nbsp; <i class="fa fa-2x fa-plus-circle"></i></router-link> 
                                    <div class="card col-md-3" v-for="(value,index) in eventListe" >
                                        <img class="card-img-top w-full object-cover rounded-t-lg  h-[15rem]" :src="value.image ? (value.image) : '/images/logo.png'" alt="Image d'évènement">
                                        <div class="card-body d-flex align-content-between flex-wrap ">
                                            <h5 class="card-title" style="margin-bottom:1px"> {{ value.name }} </h5>
                                            <div class="col-md-12 pl-0">{{ value.description }} </div>
                                            <div class="col-md-12 pl-0" >
                                                <div class="d-flex justify-content-between" style="height:15px;margin-bottom: 5px;">
                                                    <div class="card-text badge-info badge">{{value.event_type.name}}</div> <br>
                                                    <div class="card-text badge-primary badge" v-if="value.select != false" style="">En cours</div> <br>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <button  class="btn btn-primary" @click="chooseEvent(value.id)">Sélectionner</button>
                                                    <button  class="btn btn-secondary" data-toggle="modal" data-target=".bd-example-modal-lg" @click="modifyEvent(value)">Modifier</button>
                                                </div>
                                            </div>
                                        </div> 
                                    </div> &nbsp;&nbsp;
                                </div>
                                <div v-else class=" container">
                                    <div class=" text-center">
                                        <moonloader :size="'35px'"/>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>
                        
                    </div>
                    
                    <!-- <div class="col-8"> -->
                        
                    <!-- </div> -->
                    <!-- <router-link  class="btn btn-primary button button4 col-2" to="/creer-des-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire">
                        Creer un évènement
                    </router-link > -->
                    
                </div>
                <div v-else class="alert alert-primary text-center" role="alert">
                    Vous n'avez pas d'évènement
                </div>
            </div>
            <div v-else class=" container">
                <div class=" text-center">
                    <moonloader :size="'35px'"/>
                </div>
            </div>
        </div>

        <div v-if="eventSelected!=null" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="padding-top: 15%;" >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Modifier l'évènement</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="dismissEventSelected" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row col-md-12">
                        <div class="col-md-8">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- <label for="">Type d'évènement <span class="text-danger">*</span></label>
                                    <input type="alpha_spaces" v-model="married.civility" class="form-control"> -->
                                    <select-input
                                        label="Évènement" name="évènement" type="text"
                                        :rules="'required'" :isRequired="true" :options="eventTypes"
                                        v-model="eventSelected.event_type_id"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <text-input
                                    label="Titre de l'évènement" name="Titre" type="alpha_spaces"
                                    :rules="'required'" :isRequired="true" maxlength="27"
                                    v-model="eventSelected.name" 
                                     @input="validationInputTitleEvent"
                                />
                                <small class="form-text text-muted">Caractères maximum: 27</small>
                            </div>
                            <div class="col-md-12">
                                    <textarea-input
                                        label="Description" name="Description" type="alpha_spaces"
                                        :rules="'required'" :isRequired="true"
                                        v-model="eventSelected.description"     
                                        @input="validationInputDescriptionEvent"
                                    />
                                    <small class="form-text text-muted">Caractères maximum: 70</small>

                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div >
                            <div class="helper"></div>
                            <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop">
                            <div class="helper"></div>
                                <div class=" display-inline align-center" v-if="image" v-bind:class="{ 'image': true }">
                                    <img :src="image" alt="" class="img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                <label v-else-if="!eventSelected.image" class="btn display-inline" style="  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  /* padding: 15px 35px; */
  /* position: relative; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border-radius: 5px;
  text-align: center;">
                                        Choisir une image
                                    <input type="file" name="image" @change="onChange">
                                </label>
                                
                                <div class=" display-inline align-center" v-else v-bind:class="{ 'image': true }">
                                    <img :src="eventSelected.image" alt="" class="event-image img h-full w-full object-cover" />
                                    <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="dismissEventSelected">Fermer</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveChanges">Sauvegarder</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from 'axios';
import { mapActions, mapState } from "vuex";
export default {
    components: {
        Bar,
    },
    data() {
        return {
            storage_url : NUPTIAL_NOTEBOOK,
            favoritesObject:[],
            favoritesValues:[],
            click:0,
            textFullDisplay: false,
            loading:false,
            event:{},
            eventSelected:null,
            eventTypes:null,
            image:'',
            imageUpload:null,
        }
    },
    computed:{
        ...mapState('events', {
            isLoading: 'isLoading',
            eventListe: 'eventListe',
        }),
        
    },
    mounted() {
        this.favoritesDisplay();
        this.getEventType();
    },
    methods: {
        ...mapActions('events', ['getAllEvents']),

       async favoritesDisplay(){
        await this.getAllEvents();
        //    this.favoritesObject = Object.keys(this.favoritesListe);
        //    this.favoritesValues = Object.values(this.favoritesListe);
        },onChange(e) {
                var files = e.target.files;
                console.log('file '+files[0].size)
                if(files[0].size>1001028){
                    this.$createToast(
                        {
                            title: 'Image trop lourde !',
                            
                        },
                        {
                            position: 'top-center',
                            type: 'warning',
                            timeout: 10000,
                            transition: 'bounce',
                        })

                }else{
                    this.image = files[0]
                
                this.imageUpload = files[0]
                this.createFile(files[0]);
                this.eventSelected.image=this.imageUpload;

                }

        },
        removeSingleBackslash(str) {
            // Use regular expression to replace '\\' with '\'
            return str.replace(/\\(?=\\)/g, '\\');
        },
        dismissEventSelected(){
            this.eventSelected = null;
            this.image = null;
            this.imageUpload = null;

        },getEventType(){
                this.isLoading = true;
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL +
                        API_PREFIX +
                        API_VERSION +
                        "get-event-type",
                    )
                    .then((response) => {
                        console.log("Event Type : ",response.data)
                        this.eventTypes = response.data.data
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log("error", error.response.data);
                        this.isLoading = false;
                    });
            },
        createFile(file) {
                if (!file.type.match('image.*')) {
                alert('Select an image');
                return;
                }
                var img = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = function(e) {
                  vm.image = e.target.result;
                }
                reader.readAsDataURL(file);
            },
            removeFile() {
                this.event.image = '';
                this.image = '';
            },
        modifyEvent:function(eventSelected){
            console.log('eventSelected ',eventSelected);
            this.eventSelected = null;
            this.eventSelected = eventSelected;
        },
        removeFile:function(event){
            console.log('event-image ',);
            this.eventSelected.image = null;
            
        },
        saveChanges:function(){
            // this.loading = true;

            let eventForm = new FormData;
                eventForm.append('event_type_id', this.eventSelected.event_type_id);
                eventForm.append('name', this.eventSelected.name);
                eventForm.append('description', this.eventSelected.description);
                eventForm.append('image', this.imageUpload);

            axios.post(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'event/'+this.eventSelected.id, eventForm, {
                headers: {
                    Authorization: 'Bearer '+ this.$store.state.token,
                }
            })
            .then((response) => {
                console.log(response.data)
                if (response.data.success) {
                    this.favoritesDisplay();
                    this.$createToast(
                        {
                            title: 'Operation reussie !',
                            description: "Evenement crée avec succès !"
                        },
                        {
                            position: 'top-center',
                            type: 'success',
                            timeout: 10000,
                            transition: 'bounce',
                        })
                    // this.loading = false;
                    this.eventSelected = null;
                    this.image=null;
                    this.imageUpload=null;
                    
                } else {
                    this.$createToast(
                        {
                            title: 'Operation échouée !',
                        },
                        {
                            position: 'top-center',
                            type: 'error',
                            timeout: 10000,
                            transition: 'bounce',
                        })
                    // this.loading = false;
                    this.eventSelected = null;
                }
            });
        },
        validationInputTitleEvent:function(event){
                console.log(event)
                event.target.value
                if(event.target.value.length > 27){
                    // event.target.classList.add('is-invalid');
                    event.target.value = event.target.value.slice(0, -1);
                }
                // else{
                //     event.target.classList.remove('is-invalid');

                // }
            },validationInputDescriptionEvent:function(event){
                console.log(event)
                if(event.target.value.length > 70){
                    // event.target.classList.add('is-invalid');
                    event.target.value = event.target.value.slice(0, -1);

                }
                // else{
                //     event.target.classList.remove('is-invalid');

                // }
            },
        chooseEvent(EventId){
            this.loading = true;
                axios.put(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'choose-event/'+EventId, this.event, {
                    headers: {
                         
                        Authorization: 'Bearer ' + this.$store.state.token,
                    }
                })
                .then((response) => {
                    console.log(response.data)
                    if (response.data.success) {
                        this.$createToast(
                        {
                            title: 'Operation reussie !',
                            description: "Evenement choisi avec succès !"
                        },
                        {
                            position: 'top-center',
                            type: 'success',
                            timeout: 10000,
                            transition: 'bounce',
                        })
                        this.$router.push("/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref");
                    }                    
                    this.loading = false;
                })
                .catch((error) => {
                    console.log('error', error.response.data);
                    this.loading = false;
                    this.$createToast(
                    {
                        title: 'Operation échouée!',
                        description: "Erreur lors de l'enregistrement des informations   !"
                    },
                    {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                    })
                });
        }
    },
}
</script>
<style scoped>

.card-selected{
    box-shadow: 0px 5px 10px red;
    margin-bottom: 10px;

}

.button4 {
  background-color: rgb(223, 223, 223);
  color: black;
  border: 2px solid #e7e7e7;
}

.button {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card{
    padding: 0px;
}

.display-inline:hover .hidden {
    display: block !important;
}


input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none ;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {

  border-radius: 2px;
  width: 100%;
}

.display-inline:hover .hidden {
    display: block !important;
}


input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none ;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {

  border-radius: 2px;
  width: 100%;
}



</style>

