<template>
  <div class="pagination">
    <button v-if="pagination.currentPage > 1" class="pagination-item" aria-label="Previous" @click.prevent="onChangePage(pagination.currentPage - 1)">
      <span aria-hidden="true">«</span>
    </button>
    <button
      v-for="(page, key) in pages" :key="key"
      class="pagination-item"
      :disabled="page.isDisabled"
      :class="{ active: pagination.currentPage === page.name }"
      @click.prevent="onChangePage(page.name)"
    >
      {{ page.name }}
    </button>
    <button v-if="pagination.currentPage < this.pagination.lastPage" class="pagination-item" aria-label="Previous" @click.prevent="onChangePage(pagination.currentPage + 1)">
      <span aria-hidden="true">»</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      maxVisible: 4,
    }
  },
  computed: {
    startPage() {
      if (this.pagination.currentPage === 1) {
        return 1;
      }
      if (this.pagination.currentPage === this.pagination.lastPage) {
        return Math.max(this.pagination.lastPage - this.maxVisible + 1, 1);
      }
      return this.pagination.currentPage - 1;
    },
    pages() {
      const pagesArray = [];

      for (let i = this.startPage; i <= this.endPage; i++ ) {
        pagesArray.push({
          name: i,
          isDisabled: i === this.pagination.currentPage
        });
      }
      return pagesArray;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisible - 1, this.pagination.lastPage);
    },
  },
  mounted() {
    //
  },
  methods : {
    onChangePage(page) {

      this.$emit('paginate', page);
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-height: 35px;
}

.pagination-item {
  background-color: white;
  color: rgb(183 28 28 / 100%);
  padding: 0.25rem 0.75rem;
  margin: 0 0.5rem;
}

.pagination-item.active, .pagination-item:hover {
  text-decoration: none;
  border: 1px solid rgb(183 28 28 / 100%);
  border-radius: 0.25rem;
}
</style>
