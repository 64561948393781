<template>
	<div class="container-fluid">
		<div class="header-wrap">
			<nav class="col navbar navbar-expand-md justify-content-center">
					<ul class="nav nav-pills mb-3 justify-content-center font-weight-bold" id="pills-tab" role="tablist">
						<li class="nav-item">
							<router-link 
								class="nav-link" id="pills-marrieds-tab" 
								:class="($router.currentRoute.value.path == '/mes-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire') ? 'active' : ''"
								data-toggle="pill" :to="'/mes-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire'" 
								role="tab" aria-controls="pills-marrieds" 
								aria-selected="true"
							>
								Mes Évènements
							</router-link>
						</li>
						<li class="nav-item">
							<router-link 
								class="nav-link" id="pills-marrieds-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances'" 
								role="tab" aria-controls="pills-marrieds" 
								aria-selected="true"
							>
							Le(s) célébré(s)
							</router-link>
						</li>
						<li class="nav-item" >
							<router-link 
								class="nav-link" id="pills-summaries-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref'" 
								role="tab" aria-controls="pills-summaries" 
								aria-selected="false"
							>
								En Bref
							</router-link>
						</li>
						<li class="nav-item" >
							<router-link 
								class="nav-link" id="pills-restricted-committees-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/comite-restreint') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/comite-restreint'" 
								role="tab" aria-controls="pills-restricted-committees" 
								aria-selected="false"
							>
								Comité restreint
							</router-link>
						</li>
						<li class="nav-item">
							<router-link 
								class="nav-link" id="pills-budgets-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/budgets') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/budgets'" 
								role="tab" aria-controls="pills-budgets" 
								aria-selected="false"
							>
								Budget
							</router-link>
						</li>
						<li class="nav-item" >
							<router-link 
								class="nav-link" id="pills-plannings-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/planning-de-mariage') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/planning-de-mariage'" 
								role="tab" aria-controls="pills-plannings" 
								aria-selected="false"
							>
								Planning
							</router-link>
						</li>
						<li class="nav-item" >
							<router-link 
								class="nav-link" id="pills-dates-places-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage'" 
								role="tab" aria-controls="pills-dates-places" 
								aria-selected="false"
							>
								Dates et Lieux
							</router-link>
						</li>
						<li class="nav-item">
							<router-link 
								class="nav-link" id="pills-guests-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage'" 
								role="tab" aria-controls="pills-guests" 
								aria-selected="false"
							>
								Invités
							</router-link>
						</li>
						<!-- <li class="nav-item">
							<router-link 
								class="nav-link" id="pills-communication-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage'" 
								role="tab" aria-controls="pills-communication" 
								aria-selected="false"
							>
								Joy Cards
							</router-link>
						</li> -->
						<li class="nav-item">
							<router-link 
								class="nav-link" id="pills-shop-favorites-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-favoris') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-favoris'" 
								role="tab" aria-controls="pills-shop-favorites" 
								aria-selected="false"
							>
								Boutiques Favoris
							</router-link>
						</li>
						<li class="nav-item">
							<router-link 
								class="nav-link" id="pills-shop-favorites-tab" 
								:class="($router.currentRoute.value.path == '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-de-mariage-contacte') ? 'active' : ''"
								data-toggle="pill" :to="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-de-mariage-contacte'" 
								role="tab" aria-controls="pills-shop-favorites" 
								aria-selected="false"
							>
								Mes demandes de prestations
							</router-link>
						</li>
					</ul>
			</nav>
		</div>
	</div>
</template>

<style scoped>
 	#collapsibleNavbar1 li:hover .nav-link {
		background-color: #b71c1c;
		color:white;
		font-family: 'Century Gothic', sans-serif;
		font-size: 16px;
		width: 100%;
	}

	.nav-link.active {
		color: #fff;
		background-color: #b71c1c;
	}

	.nav {
		font-size: 16px;
		/* border: 0.1px solid #e6e4e5; */
	}

	.nav a {
		color: #b71c1c;
		margin: 0px;
	}
</style>

<script>
	export default {
		name: 'bar',
		props:['customer'],
		data: function() {
			return {
				user: JSON.parse(this.$store.state.user),
				event:null
			}
		},
		mounted: function() {
			console.log('Username : ',this.user);
			console.log(this.$router.currentRoute.value.path);
			// console.log('Customer ',this.customer);

		},methods:{
		}
	}
</script>
