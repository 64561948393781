<template>
  <div class="bg-container-plannings">
        <Bar />  
    <div class="container-fluid pr-5 pl-5" style="padding: 20px;">
      <big-title label="PLANNING" />
      <br />
      <!-- <p class="text-center">
        Les choses se précisent ! Comme son nom l’indique, la rubrique «
        Planning » vous aide à planifier <br />
        les actions importantes de votre mariage. Mettez à jour votre liste de
        tâches, plus rien ne vous échappera !
      </p> -->
      <p class="text-center">
        Comme tous les projets, le mariage demande d’établir un planning<br />
        Vous marier sera le plus beau jour de votre vie, <br />
        une journée inoubliable sans fausse note. <br />
        Que ce soit un mariage à la mairie ou un mariage religieux,
      </p>

      <div class="text-center">
        <p class="collapse" id="collapseP">
          un planning de mariage est l’outil précieux pour organiser votre
          mariage à la perfection <br />
          et ainsi diminuer au maximum les surprises de dernière minutes. <br />
          Le planning comme son nom l’indique, vous aide à planifier votre
          cérémonie de mariage. <br />
          Avec le planning de mariage by Jours de Joie, <br />les actions
          importantes de votre mariage seront mises à jour dans votre carnet
          nuptial sur une liste de tâches. <br />
          Ce qui vous donnera une vue globale de votre organisation, et vous
          permettra de garder le contrôle. <br />Vous maitrisez la situation, ne
          vous arrêtez pas en si bon chemin ! <br /><br />
          <strong>Le saviez-vous :</strong><br />
          Une bonne planification de mariage se fait au moins Un an à l’avance !
        </p>
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseP"
          role="button"
          aria-expanded="false"
          aria-controls="collapseP"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <br />
      <div v-if="isLoading == true">
        <spinner  /><br /><br />
      </div>
      <div
        class=""
        v-else-if="user.email_verified_at != null && customer.dob != null"
      >
        <div style="background-color:#fff;margin:20px;">
          <div
            class="row"
            v-if="isLoading == false && customer != null && plannings != null"
          >
            <div class="col-md-12" style="padding-bottom: 20px;">
              <div v-if="progressPercent <= 25">
                <div class="progress" style="height: 20px;">
                  <div
                    class="progress-bar progress-bar-striped bg-danger"
                    role="progressbar"
                    :style="'width:' + progressPercent + '%;'"
                    :aria-valuenow="progressPercent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="text-center">
                  Vous êtes à
                  <strong style="color:#b71c1c;">{{
                    progressPercent + "%"
                  }}</strong>
                  Prenez courage 🙏🏾 !!! Bonne planification = Bon Mariage
                </div>
              </div>

              <div v-if="progressPercent > 25 && progressPercent <= 50">
                <div class="progress" style="height: 20px;">
                  <div
                    class="progress-bar progress-bar-striped bg-warning"
                    role="progressbar"
                    :style="'width:' + progressPercent + '%;'"
                    :aria-valuenow="progressPercent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="text-center">
                  Vous êtes à
                  <strong style="color:#b71c1c;">{{
                    progressPercent + "%"
                  }}</strong>
                  d'efforts tenez bon 💪🏾💪🏾💪🏾 !!!
                </div>
              </div>

              <div v-if="progressPercent > 50 && progressPercent <= 75">
                <div class="progress" style="height: 20px;">
                  <div
                    v-if="progressPercent > 50 && progressPercent <= 75"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    :style="
                      'width:' + progressPercent + '%;background-color:orange;'
                    "
                    :aria-valuenow="progressPercent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="text-center">
                  Youpiiie
                  <strong style="color:#b71c1c;">{{
                    progressPercent + "%"
                  }}</strong>
                  d'efforts de planifications fournie 🙌🏾 !!!
                </div>
              </div>

              <div v-if="progressPercent > 75">
                <div class="progress" style="height: 20px;">
                  <div
                    class="progress-bar progress-bar-striped bg-success"
                    role="progressbar"
                    :style="'width:' + progressPercent + '%;'"
                    :aria-valuenow="progressPercent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="text-center">
                  Bravo vous êtes un AS de la plannification 😍 !!!
                  <strong style="color:#b71c1c;">{{
                    progressPercent + "%"
                  }}</strong>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(item, index) in plannings"
                  :key="index"
                  style="background-color: #fff;text-align: center;"
                >
                  <label :for="'planning' + index">
                    <img
                      :src="NUPTIAL_NOTEBOOK + item.image"
                      alt=""
                      srcset=""
                    />
                    <input
                      @change="onPlanningChange(index)"
                      :value="index"
                      type="radio"
                      name="planning"
                      :id="'planning' + index"
                      style="padding-top: -10px;"
                    />&nbsp;
                    <label style="font-size: 10px;" :for="'planning' + index">{{
                      item.content
                    }}</label>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div v-if="isPlanningLoading == false">
                <div
                  v-if="currentPlanning == null"
                  class="alert alert-danger text-center"
                >
                  <p>
                    Il n'y a pas de parametre pour cet type d'évènement
                  </p>
                </div>
                <div v-else v-for="(item, index) in plannings" :key="index">
                  <planning-component
                    v-show="currentPlanningKey == index"
                    :planningProps="item"
                    :index="index"
                    @update-planning="updatePlanning"
                  />
                </div>
              </div>
              <div class="row no-print" v-else>
                <div class="col-md-12">
                  <spinner  />
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
          <div class="row no-print" v-else>
            <div class="col-md-12">
              <spinner  />
              <br /><br />
            </div>
          </div>
          <div class="row noScreen">
            <div class="col-md-12">
              <p style="text-align:center;">
                Généré depuis
                <a style="color:#b71c1c;" href="#">Joursdejoie.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
      <center>
        <button class="default-button no-print" @click="goToPrevious">
          PRÉCÉDENT</button
        >&nbsp;
        <button class="default-button no-print" @click="goToNext">
          SUIVANT
        </button>
      </center>
    </div>
  </div>
</template>

<style>
@media screen {
  .noScreen {
    display: none;
  }

  .noPrint {
  }
}
@media print {
  .noScreen {
  }

  .noPrint {
    display: none;
  }
}

.bg-container-plannings {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-plannings .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-plannings::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/plannings.png");
  background-size: cover;
  opacity: 0.2;
}
</style>

<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import PlanningComponent from "@/components/nuptial-notebook/PlanningComponent";
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import store from "@/store";
import axios from "axios";
import { useMeta } from 'vue-meta';
export default {
  name: "CustomerPlannings",
  setup () {
    useMeta({
      title:
        "Carnet Nuptial - Planning - Gérer toutes les étapes de l'organisation de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Planifier son mariage en Côte d'Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, comment planifier son mariage, calendrier, Organiser son mariage,prestataire de mariage comment choisir la date de son mariage, carnet nuptial, outil de planification, wedding planner, inspiration de mariage",
        },
      ],
    })
  },

  components: {
    PlanningComponent,
    Bar,
  },
  data: function() {
    return {
      showModal:false,
      isLoading: false,
      NUPTIAL_NOTEBOOK: NUPTIAL_NOTEBOOK.DEV_URL,
      plannings: null,
      planningForms: null,
      user: JSON.parse(store.state.user),
      customer: {dob:null},
      progressPercent: 0,
      currentPlanningKey: null,
      currentPlanning: null,
      isPlanningLoading: false,
      textFullDisplay: false,
    };
  },
  mounted: function() {
    this.getCustomerInfo()
      .then((customer) => {
        console.log('CUSTOMER CURRENT ID ',customer.current_event.id);
        this.getPlannings(customer.current_event.id)
          .then((plannings) => {
            this.getProgressBarData(plannings);
          })
          .catch((error2) => {
            console.log("Planning error", error2);
          });
      })
      .catch((error1) => {
        console.log("Mounted error", error1);
      });
    this.show();
  },
  methods: {
    print: function() {
      window.print();
    },
    goToPlanningSummary: function() {
      this.$router.push("/nuptial-notebook/plannings/summary");
    },
    getProgressBarData: function(plannings) {
      let totalDoneTasks = 0;
      let totalChildrenLength = 0;

      plannings.forEach((item) => {
        totalDoneTasks += item.doneTasks;
        totalChildrenLength += item.children.length;
      });

      this.progressPercent = (totalDoneTasks * 100) / totalChildrenLength;
      this.progressPercent = Number(this.progressPercent).toFixed(0);
    },
    goToPrevious: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/budgets"
      );
    },
    goToNext: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage"
      );
    },
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            // if (!this.customer.user.email_verified_at) {
            //     this.$router.push('/inscription-a-terminer/'+2);
            // }
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getPlannings: function(eventId) {
      return new Promise((resolve, reject) => {
        this.isPlanningLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "plannings-by-event/" +
              eventId,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            this.isPlanningLoading = false;
            this.plannings = response.data;
            resolve(this.plannings);
            
          })
          .catch((error) => {
            console.log("error", error.response.data);
            this.isPlanningLoading = false;
            reject(error);
          });
      });
    },
    onPlanningChange: function(index) {
      this.currentPlanning = this.plannings[index];
      this.currentPlanningKey = index;
    },
    updatedPercentage(customerId) {
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "percentage/" +
            customerId,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log("Error percentage", error);
        });
    },
    archivated: function(customerId) {
      var app = this;
      store
        .dispatch("archiveAccount", customerId)
        .then((response) => {})
        .catch((error) => {});
    },
    updatePlanning: function(planning) {
      
      this.isPlanningLoading = true;
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "plannings/events",
          planning,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          
          this.isPlanningLoading = false;
          this.updatedPercentage(response.data.customer_id);
          this.archivated(response.data.customer_id);
          this.$createToast(
                                    {
                                    title: 'Operation reussie !',
                                    description: "Informations enregistrées avec succès !  !"
                                    },
                                    {
                                    position: 'top-center',
                                    type: 'success',
                                    timeout: 10000,
                                    transition: 'bounce',
                                    });
          this.getPlannings(this.customer.event.id).then((plannings) => {
            this.getProgressBarData(plannings);
          });
        })
        .catch((error) => {
          this.isPlanningLoading = false;
          
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'enregistrement des informations !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>
