<template>
    <div>
        <!-- {{ objectImage }} -->
        <div v-if="objectImage">
            <div  style="z-index:100000" class="modal fade" :id="'exampleModalCenter'+ objectImage.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            
                            <img  :src="url + objectImage.image" :alt="objectImage.name">
                        </div>
                        <div class="modal-footer" style="padding:0px">
                            <button type="button" style="padding:0px; margin:2px;" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0);" data-toggle="modal" :data-target="'#colorPicker' + index" class="btn btn-sm btn-link">
            <span v-if="objectImage == 'loading'">
                Veuillez choisir une valeur <i class="fa fa-edit"></i>
            </span>
            <span v-else-if="objectImage == null">
                Veuillez choisir une valeur <i class="fa fa-edit"></i>
            </span>
            <span v-else>
                <div class="text-center">
                    <img :src="url + objectImage.image" style="height: 50px;" class="rounded float-left" :alt="objectImage.name">
                    <br><br><br>
                    {{ objectImage.name }} <i class="fa fa-edit"></i>
                </div>
            </span>
        </a>
        <div class="modal fade" :id="'colorPicker' + index" tabindex="-1" role="dialog" aria-labelledby="colorPickerTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>{{ this.headerLabel }}</h4>
                    </div>
                    <div class="modal-body">
                        <select @change="onSelectChange($event)" :value="modelValue" @input="$emit('update:model-value', $event.target.value)" v-if="list != null" id="" class="form-control">
                            <option value="">---</option>
                            <option v-for="(item, key) in list.items" :key="key" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                        <br>
                        <div class="text-center">
                            <p> <small>cliquez pour agrandir l'image</small> </p>
                            <a type="button" v-if="objectImage != null && inProgress == false" data-toggle="modal" :data-target="'#exampleModalCenter'+objectImage.id">
                                <img :src="url + objectImage.image" style="max-height: 200px;text-align: center;" >
                            </a>
                        </div>
                        <div class="alert alert-danger" v-if="inProgress == true">
                            <p class="text-center">
                                Chargement en cours ...
                            </p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-button" data-dismiss="modal">VALIDER</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from '@/config';
import store from '@/store';
import axios from 'axios';

export default {
    name: 'imageListInput',
    props: ['modelValue', 'index', 'imageListId', 'headerLabel'],
    data () {
        return {
            objectImage: null,
            objectImageZoom:null,
            list: null,
            url: NUPTIAL_NOTEBOOK.DEV_URL,
            inProgress: false
        }
    },
    mounted() {
       
        this.getItem(this.modelValue)
        this.getList()
    },
    methods: {
        getItem(val) {
            if(val) {
                this.objectImage = 'loading'
                this.inProgress = true
                return new Promise((resolve, reject) => {
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'items/' + val, {
                        headers: {
                            'Authorization': 'Bearer ' + store.state.token
                        }
                    })
                    .then((response) => {
                        
                        this.objectImage = response.data
                        this.inProgress = false
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                        console.log(error)
                        this.inProgress = false
                    });
                })
            }
        },
        onSelectChange(event) {
            this.getItem(event.target.value)
        },
        getList: function() {
            return new Promise((resolve, reject) => {
            this.inProgress = true;
            axios.get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'image-list/' + this.imageListId, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.token
                    }
                })
                .then((response) => {
                    resolve(response.data)
                    this.list = response.data
                    this.inProgress = false
                })
                .catch((error) => {
                    reject(error)
                    this.inProgress = false
                    console.log(error)
                })
            })
        },
    }
}
</script>