import { BASE_URL } from "@/views/wedding-provider/utils";
import axios from 'axios';

export async function getAddressesAPI(params) {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-addresses`, { params });
        
        return response.data;
    } catch (error) {
        console.log("===> getAddressAPI error", error)
    }
}