<template>
<div  class="flex flex-col">
  <div class="flex items-center">
    <div class="flex pr-2">
      <img :src="'https://ui-avatars.com/api/?name='+comment.customer.last_name+comment.customer.given_names" class="h-12 w-auto" />
    </div>
    <div class="flex flex-col">
      <div class="text-sm">{{ 'Par ' + comment.customer.last_name + ' ' + comment.customer.given_names }}</div>
      <div class="text-xs text-gray-400">{{ dateFormatter(comment.created_at) }}</div>
      <div v-if="user && user.id === comment.customer.id && !isConfirmed">
        <button @click="onConfirmed" class="text-xs text-gray-400 hover:text-gray-500">supprimer</button>
      </div>
      <div v-if="user && user.id === comment.customer.id && isConfirmed">
        <span class="text-xs text-gray-400">
          êtes-vous sûre de vouloir supprimer ce commentaire ?
        </span>
        <button @click="onDelete" class="text-xs text-red-800 hover:text-red-900">
          Oui
        </button>&nbsp;
        <button @click="onCancel" class="text-xs text-gray-400 hover:text-gray-500">
          Annuler
        </button>
      </div>
    </div>
  </div>

  <div class="flex text-xs text-gray-400">
    <div class="flex">
      <review-stars-component :count="0" :stars="getStars(comment.global_mark)" :isShownMode="true"></review-stars-component>
    </div>
    <div class="flex px-1">|</div>
    <div class="flex" v-if="comment.discovery_channel === 'by_jdj'">
      J'ai connu ce prestataire sur JOURS DE JOIE
    </div>
    <div class="flex" v-if="comment.discovery_channel === 'used_service'">
      Nous avons utilisé ce service pour notre mariage
    </div>
    <div class="flex" v-if="comment.discovery_channel === 'potential_provider'">
      Nous l'envisageons comme prestataire potentiel
    </div>
    <div class="flex" v-if="comment.discovery_channel === 'guest_in_wedding'">
      J'ai été invité·e à un mariage qui a fait appel à ses services
    </div>
    <div class="flex" v-if="comment.discovery_channel === 'professional'">
      Je suis un professionnel qui connaît ce prestataire
    </div>
  </div>
  <div>{{ comment.description }}</div>
</div>
</template>

<script>
import {dateFormatter} from "@/views/wedding-provider/utils";
import {mapState} from "vuex";
import {COMPONENT_STAR_TYPES} from "@/views/wedding-provider/types";
export default {
  name: "comment-item-component",
  data: () => ({
    isConfirmed: false
  }),
  props: {
    comment: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
  },
  methods: {
    dateFormatter: (date) => dateFormatter(date),
    onDelete() {
      this.$emit('comment-deleted', this.comment.id);
    },
    onConfirmed() { this.isConfirmed = true },
    onCancel() { this.isConfirmed = false },
    getStars(currentMark) {
      
      const stars = [];
      let roundMark = 0;
      if (currentMark) {
        roundMark = Number(currentMark);
      }
      for (let i = 1; i <= 5; i++) {
        if (i <= roundMark) {
          stars.push({ type: COMPONENT_STAR_TYPES.FULL });
        } else {
          stars.push({ type: COMPONENT_STAR_TYPES.EMPTY });
        }
      }
      return stars;
    },
  }
}
</script>
