<template>
    <div >
        <div class="container" >
            <br>
            <div class="married-container">
                <div class="row" v-if="isLoading == false">
                    <div class="p-3 col-md-12">
                        <div class="col-md-12">
                            <sub-title 
                                :label="'Event'"
                                :gender="''"
                            />
                        </div>
                        <div class="col-md-12">
                            <Form @submit="createEvent">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <!-- <label for="">Type d'évènement <span class="text-danger">*</span></label>
                                                <input type="alpha_spaces" v-model="married.civility" class="form-control"> -->
                                                <select-input
                                                    label="Évènement" name="évènement" type="text"
                                                    :rules="'required'" :isRequired="true" :options="eventTypes"
                                                    v-model="event.event_type_id" 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6">
                                            <text-input
                                                label="Titre de l'évènement" name="Titre" type="alpha_spaces"
                                                :rules="'required'" :isRequired="true" maxlength="27"
                                                v-model="event.name"
                                                @input="validationInputTitleEvent"
                                            />
                                            <small class="form-text text-muted">Caractères maximum: 27</small>

                                        </div>

                                        <div class="col-md-12">
                                            <textarea-input
                                                label="Description" name="Description" type="alpha_spaces"
                                                :rules="'required'" :isRequired="true"
                                                v-model="event.description" 
                                                @input="validationInputDescriptionEvent"
                                            />
                                            <small class="form-text text-muted">Caractères maximum: 70</small>

                                    </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <div >
                                        <div class="helper"></div>
                                        <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop">
                                        <div class="helper"></div>
                                            <div class=" display-inline align-center" v-if="image" v-bind:class="{ 'image': true }">
                                                <img :src="image" alt="" class="img h-full w-full object-cover" />
                                                <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                            </div>
                                            <label v-else-if="!event.image" class="btn display-inline">
                                                    Choisir une image
                                                <input type="file" name="image" @change="onChange">
                                            </label>
                                            
                                            <div class=" display-inline align-center" v-else v-bind:class="{ 'image': true }">
                                                <img :src="NUPTIAL_NOTEBOOK.DEV_URL+event.image" alt="" class="img h-full w-full object-cover" />
                                                <button class="btn hidden" @click="removeFile">Supprimer la photo</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <center>
                                    <button class="primary-button" type="submit">ENREGISTRER</button>&nbsp;
                                    <button class="secondary-button" type="reset" @click="$router.go(-1)">ANNULER</button>
                                </center>
                            </Form>
                        </div>
                    </div>
                </div>
                <div style="padding:50px;" v-else>
                    <center>
                        <spinner  />
                    </center>
                </div>
            </div>
            <br><br>
        </div>
    </div>
</template>

<style scoped>
    .married-container {
        -webkit-box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        -moz-box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        box-shadow: 0px 0px 5px 1px rgba(85,85,85,0.3);
        background: #fff;
    }
</style>
<script setup>
import { Form } from 'vee-validate';

</script>
<script>
    import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from 'axios';
    
    export default {
        name: 'MarriedComponent',
        props: ['marriedProps', 'index'],
        components: {
            Form,
            // vueDropzone: vue2Dropzone
        },
        data: function() {
            return {
                NUPTIAL_NOTEBOOK : NUPTIAL_NOTEBOOK,
                eventTypes: [{
                    id: 'M',
                    name: 'Monsieur'
                }, {
                    id: 'Mlle',
                    name: 'Mademoiselle'
                }],
                event:{},
                isLoading: false,
                image:'',
                imageUpload:null,
            };
        },
        mounted: function() {
            this.getEventType()
        },
        computed: {
            //
        },
        methods: {
            onChange(e) {
                var files = e.target.files;
                console.log(files[0])
                this.image = files[0]
                this.imageUpload = files[0]
                this.createFile(files[0]);
            },
            createFile(file) {
                if (!file.type.match('image.*')) {
                alert('Select an image');
                return;
                }
                var img = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = function(e) {
                  vm.image = e.target.result;
                }
                reader.readAsDataURL(file);
            },
            removeFile() {
                this.event.image = '';
                this.image = '';
            },
            getEventType(){
                this.isLoading = true;
                axios.get(NUPTIAL_NOTEBOOK.DEV_URL +
                        API_PREFIX +
                        API_VERSION +
                        "get-event-type",
                    )
                    .then((response) => {
                        console.log("Event Type : ",response.data)
                        this.eventTypes = response.data.data
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log("error", error.response.data);
                        this.isLoading = false;
                    });
            },
            validationInputTitleEvent:function(event){
                console.log(event)
                event.target.value
                if(event.target.value.length > 27){
                    // event.target.classList.add('is-invalid');
                    event.target.value = event.target.value.slice(0, -1);
                }
                // else{
                //     event.target.classList.remove('is-invalid');

                // }
            },validationInputDescriptionEvent:function(event){
                console.log(event)
                if(event.target.value.length > 70){
                    // event.target.classList.add('is-invalid');
                    event.target.value = event.target.value.slice(0, -1);

                }
                // else{
                //     event.target.classList.remove('is-invalid');

                // }
            },

            createEvent: function() {
                console.log(this.event)
                this.isLoading = true;

                let eventForm = new FormData;
                eventForm.append('event_type_id', this.event.event_type_id);
                eventForm.append('name', this.event.name);
                eventForm.append('description', this.event.description);
                eventForm.append('image', this.imageUpload);
                axios.post(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + 'events', eventForm, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.token,
                    }
                })
                .then((response) => {
                    console.log(response.data)
                    if (response.data.success) {
                        this.$createToast(
                        {
                            title: 'Operation reussie !',
                            description: "Evenement crée avec succès !"
                        },
                        {
                            position: 'top-center',
                            type: 'success',
                            timeout: 10000,
                            transition: 'bounce',
                        })

                        this.$router.push("/mes-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire");
                    }
                    
                    
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log('error', error.response.data);
                    this.isLoading = false;
                    this.$createToast(
                    {
                        title: 'Operation échouée!',
                        description: "Erreur lors de l'enregistrement des informations   !"
                    },
                    {
                        position: 'top-center',
                        type: 'error',
                        timeout: 10000,
                        transition: 'bounce',
                    })                    
                });
                    
            },
        }

    }

</script>
<style scoped>

.btn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  /* padding: 15px 35px; */
  /* position: relative; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border-radius: 5px;
  text-align: center;
}

.display-inline:hover .hidden {
    display: block !important;
}


input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none ;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {

  border-radius: 2px;
  width: 100%;
}

.display-inline:hover .hidden {
    display: block !important;
}


input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none ;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {

  border-radius: 2px;
  width: 100%;
}


</style>