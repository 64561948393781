<template>
  <div class="bg-container-marrieds">
        <Bar />  
    <div class="container p-3" style="padding: 25px;">
      <big-title label="LE(S) CELEBRE(S)" />
      <br />
      <!-- <p class="text-center">
        Qui sont les heureux tourtereaux ? À cette première étape du carnet
        nuptial, <br />
        veuillez renseigner toutes les informations relatives à chacun des
        futurs mariés.
      </p>
      <br /> -->
      <p class="text-center">
        Qui sont les heureux tourtereaux ?<br />
        À cette première étape dans l’organisation de votre mariage, <br />
        nous vous invitons à renseigner toutes les informations vous concernant
        vous et <br />
        votre futur(e) conjoint(e) dans la rubrique éponyme du carnet
        nuptial.<br />
      </p>
      <div class="text-center">
        <p class="collapse" id="collapseTMO">
          <strong
            >Il a enfin fait sa demande ! A présent vous êtes fiancés,
            félicitations.</strong
          >
          <br />
          <strong>A propos, qu’est-ce que se fiancer ?</strong><br />
          Se fiancer est une preuve d'engagement qui annonce officiellement une
          union à venir. <br />
          C'est également donner sa parole en passant d'une relation privée à la
          promesse publique de se marier.<br /><br />
          <strong>Le saviez-vous : </strong><br />
          Au Royaume-Uni et en Allemagne, les deux fiancés peuvent porter une
          bague de fiançailles à la main gauche. <br />
          Au Brésil, en Égypte et au Liban les fiancés se remettent mutuellement
          les futures alliances de leur mariage,<br />
          chacune gravée intérieurement au prénom du futur époux ou de la future
          épouse. <br />
          Chacun des deux la porte alors à l'annulaire de la main droite
          jusqu'au jour de leur mariage, <br />
          en signe de leur amour et de leur engagement.
        </p>
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseTMO"
          role="button"
          aria-expanded="false"
          aria-controls="collapseTMO"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <br />
      <div v-if="isLoading == true">
        <spinner  /><br /><br />
      </div>
      <div
        class=""
        v-else-if="user.email_verified_at !== null && customer.dob != null"
      >
      <div class="col-md-12 alert alert-danger text-center"  v-if="marrieds.length == 0">
            Il n'y a pas de parametre pour cet type d'évènement
          </div>
        <div v-if="marrieds != null && customer != null">
          <div v-for="(married, key) in marrieds" :key="key">
            
            <married-component
              :marriedProps="married"
              @update-married="updateMarried"
              :index="key"
            />
          </div>
        </div>
        <div v-else>
          <spinner  /><br /><br />
        </div>
      </div>
      <div class="text-center" v-else></div>
      <center>
        <button class="default-button" @click="goToNextSummaries">
          SUIVANT
        </button>
      </center>
    </div>
    <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
    <!-- <modal
      style="margin-top:3%;"
      :scrollable="true"
      height="auto"
      name="hello"
      :resizable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="text-center h3 py-3">
        Activation de compte
      </div>
      <hr />
      <div class="text-center py-2">
        <img src="/images/auth-alert.png" alt="" width="50%" /> <br />
      </div>
      <div class="text-center py-2" style="font-size: 16px;">
        Veuillez consulter votre adresse mail ({{ user.email }})<br />
        pour activer votre compte
      </div>
      <div class="text-center py-2">
        <button
          class="primary-button"
          @click="resendMailAccountVerification(user.email)"
        >
          Renvoyer le mail
        </button>
      </div>
    </modal> -->
  </div>
</template>

<style scoped>
.bg-container-marrieds {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-marrieds .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-marrieds::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/the-married-ones.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import MarriedComponent from "@/components/nuptial-notebook/MarriedComponent";
import { API_PREFIX, API_VERSION, AUTH, NUPTIAL_NOTEBOOK } from "@/config";
import axios from "axios";
import { Form } from 'vee-validate';
// import spinner from '@/components/common/ui/spinner'
import store from "@/store";
import { useMeta } from 'vue-meta';

export default {
  name: "CustomerMarriedOnes",
  setup () {
    useMeta({
      title:
      "Carnet Nuptial - Fiancés - Renseigner les informations des futurs mariés. C'est le début d'une aventure",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Planifier son mariage en Côte d'Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, comment planifier son mariage, fiançailles, organiser son mariage organisation de mariage en Côte d’Ivoire, prestataire de mariage, prestataire de service, carnet nuptial, outil de planification, wedding planner, inspiration de mariage",
        },
      ],
    })
  },

  components: {
    Form,
    MarriedComponent,
    // spinner,
    Bar,
    
  },
  data: function() {
    return {
      showModal: false,
      isLoading: false,
      marrieds: null,
      marriedForms: null,
      user: JSON.parse(store.state.user),
      customer: {dob:null},
      textFullDisplay: false,
    };
  },
  mounted: function() {
    this.show();
    this.getCustomerInfo()
      .then((customer) => {
        this.getMarrieds(customer.current_event.id);
      })
      .catch(() => {});
  },
  methods: {
    onSubmit(values) {
      
    },
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            
            this.customer = response.data;
            // if (!this.customer.user.email_verified_at) {
            //   this.$router.push('/inscription-a-terminer/'+2);
            // }
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    goToNextSummaries: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref"
      );
    },
    getMarrieds: function(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "marrieds-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          
          this.isLoading = false;
          this.marrieds = response.data;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    updateMarried: function(married) {
      this.isLoading = true;
      console.log(married)
      let marriedForm = new FormData();
      marriedForm.append("event_id", married.event_id);
      marriedForm.append("married_id", married.married_id);
      marriedForm.append("civility", married.civility);
      marriedForm.append("last_name", married.last_name);
      marriedForm.append("given_names", married.given_names);
      marriedForm.append("email", married.email);
      marriedForm.append("phone_number_1", married.phone_number_1);
      marriedForm.append("phone_number_2", married.phone_number_2);
      marriedForm.append("image", null);

      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "marrieds/events",
          marriedForm,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.$createToast(
                                    {
                                    title: 'Operation reussie !',
                                    description: "Informations enregistrées avec succès !  !"
                                    },
                                    {
                                    position: 'top-center',
                                    type: 'success',
                                    timeout: 10000,
                                    transition: 'bounce',
                                    })
          this.getMarrieds(this.customer.event.id);
        })
        .catch((error) => {
          console.log("error", error.response.data);
          
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'enregistrement des informations !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    resendMailAccountVerification: function(customerEmail) {
      this.isLoading = true;
      axios
        .get(
          AUTH.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "send-email-verification/" +
            customerEmail,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          this.$createToast(
          {
          title: 'Attention !',
          description: "Mail envoyé avec succès ! !"
          },
          {
          position: 'top-center',
          type: 'success',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'envoie de mail !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>
