<template>
  <div class="bg-container-wedding-card">
    <div>
      <!-- <Bar /> -->
      <div class="container p-3" style="padding: 25px;">
        <big-title label="JOY CARDS" />
        <br />
        <p class="text-center">
          Plus besoin de vous déplacer pour distribuer les cartons d’invitations
          et de remerciement.
          <br />Mettez-en plein la vue à vos convives en économisant votre temps
          ou votre argent grâce aux Joy Cards, les faire-parts numériques.
        </p>
        <br />
        <div
          class="faire-parts-container p-3"
          v-if="isLoading == false && message != null"
        >
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <sub-title :label="'DETAILS JOY CARD'" :gender="'F'" />
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-sm">
              <thead>
                <th class="text-center">
                  Succès
                </th>
                <th class="text-center">
                  Erreur
                </th>
                <th class="text-center">
                  Total
                </th>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <span class="badge badge-success p-2">
                      {{ message.success }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="badge badge-danger p-2">
                      {{ message.error }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="badge badge-dark p-2">
                      {{ message.total }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Informations du message</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                >Cible</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="row pt-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Canal : </label>
                    <div class="badge badge-primary p-2">
                      {{ message.channel !== null ? message.channel.name : "" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Catégorie</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="
                        message.category != null ? message.category.name : ''
                      "
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6" v-if="message.model != null">
                  <div class="form-group">
                    <label for="">Modèle</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="message.model != null ? message.model.name : ''"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Expéditeur</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="message.from"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6" v-if="message.subject != null">
                  <div class="form-group">
                    <label for="">Sujet</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="message.subject"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-12" v-if="message.cards.length > 0">
                  <div class="form-group">
                    <label for="">Card</label>
                    <img
                      :src="
                        COMMUNICATION +
                          message.cards[0].file
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Contenu du message</label>
                    <div
                      style="border: 1px;border-style: solid;padding: 10px;text-align:center;"
                      v-html="message.body"
                    ></div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <i>Envoyé le {{ message.created_at }}</i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="row pt-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Cible : </label>&nbsp;
                    <div
                      class="badge badge-primary p-2"
                      v-for="(guest, key) in message.guests"
                      :key="key"
                    >
                      {{ guest.name }}
                    </div>
                    &nbsp;
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-sm table-bordered table-hover">
                  <thead>
                    <th>Nom</th>
                    <th>Prénoms</th>
                    <th>N° de téléphone</th>
                    <th>Email</th>
                    <th>Cible</th>
                    <th>Statut</th>
                    <th>Actions</th>
                  </thead>
                  <tbody>
                    <tr v-for="(guest, key) in message.eventGuests" :key="key">
                      <td>{{ guest.last_name }}</td>
                      <td>{{ guest.given_names }}</td>
                      <td>{{ guest.phone_number_1 }}</td>
                      <td>{{ guest.email }}</td>
                      <td>{{ guest.guestName }}</td>
                      <td v-if="guest.pivot.status == 1">Envoyé</td>
                      <td v-else>Non envoyé</td>
                      <td>
                        <button
                          v-if="guest.pivot.status == 0"
                          class="btn btn-link btn-sm"
                          title="Cliquez pour renvoyer"
                          @click="sendMessage(guest.id, guest.guest_id)"
                        >
                          <i class="fa fa-refresh"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faire-parts-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
}

.bg-container-wedding-card {
  width: 100%;
  position: relative;
}

.bg-container-wedding-card .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-wedding-card::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/wedding-cards.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import {
    API_PREFIX,
    API_VERSION,
    COMMUNICATION,
    NUPTIAL_NOTEBOOK,
} from "@/config";
import axios from "axios";
import moment from "moment";
import { useMeta } from 'vue-meta';
export default {
  name: "carnet-nuptial-details-carte-d-invitation",
  setup () {
    useMeta({
      title: "Joy Cards, Faire-Parts Numériques de Mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Commander des faire-parts en Côte d’Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, commander des faire-parts en Côte d’Ivoire, choix un prestataire de mariage , organiser son mariage, prestataire de mariage, comment choisir le design de ses faire-parts, envoyer ses faire-parts par mail, faire-parts digital, invitation de mariage",
        },
      ],
    })
  },
  data() {
    return {
      showModal:false,
      message: null,
      isLoading: false,
      guests: [],
      guestTypes: [],
      isGuestsLoading: false,
      COMMUNICATION: COMMUNICATION.DEV_URL
    };
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    getMessage() {
      let messageId = this.$route.params.id;
      this.isLoading = true;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "messages/" +
            messageId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
         
          response.data.created_at = moment(response.data.created_at).format(
            "DD/MM/YYYY à HH:mm"
          );
          this.isLoading = false;
          this.getGuests(response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    getGuests(data) {
      let app = this;
      axios
        .get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "guests", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          app.guests = response.data;
          data.eventGuests.forEach((element) => {
            app.guests.forEach((element1) => {
              if (element.guest_id == element1.id) {
                element.guestName = element1.name;
              }
            });
          });
          this.message = data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    sendMessage: function(id, guestId) {
      let app = this;
      app.$Progress.start();
      app.isLoading = true;
      let targetIds = [];
      targetIds.push(id);
      targetIds.join(";");
      app.createdMessage = {
        channel_id: app.message.channel_id,
        label: app.message.label,
        message_category_id: app.message.message_category_id,
        message_model_id: app.message.message_model_id,
        message_design_id: app.message.message_design_id,
        from: app.message.from,
        subject: app.message.subject,
        body: app.message.body,
        resend: 1,
        guest_id: guestId,
        target: String(targetIds),
      };
      axios
        .post(
          COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "messages",
          app.createdMessage,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          app.isLoading = false;
          app.$Progress.finish();
          app.$toasted.success("Message envoyé avec succès !");
          app.$router.push(
            "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
          );
        })
        .catch((error) => {
          console.log(error.response.data);
          app.isLoading = false;
          app.$Progress.fail();
          app.$toasted.error("Erreur détectée lors de l'envoie du message !");
        });
    },
  },
};
</script>
