import { createRouter, createWebHistory } from 'vue-router';

// Auth and login
import store from "@/store";
import Login from "../views/auth/login.vue";
import PasswordReset from "../views/auth/passwordReset.vue";
import passwordRecovery from "../views/auth/recoverPassword.vue";
import Register from "../views/auth/register.vue";
// home
import Home from "../views/home.vue";
import Welcome from "../views/welcome.vue";
//NotFound
import CGU from "@/views/cgu.vue";
import FAQ from "@/views/faq.vue";
import NotFound from "@/views/notfound.vue";
import PRIVACY from "@/views/privacy.vue";
//contacts

// Profil
import AccountInfoActivate from "@/views/account/info/activate.vue";
import AccountInfoEdit from "@/views/account/info/edit.vue";
import AccountInfoShow from "@/views/account/info/show.vue";

import Contacts from "@/views/contacts/index.vue";

//Town hall
import TownHallFolder from "../views/town-hall/folder.vue";
import TownHallIndex from "../views/town-hall/index.vue";
import TownHallShow from "../views/town-hall/show.vue";
//joy visibility
import joyVisibility from "../views/joyvisibility.vue";
import OnBoarding from "../views/onboarding.vue";

//Joycard
// import JoyCardSending from "@/components/communication/Sending.vue";

// import nuptialNotebookHome from '@/views/nuptial-notebook/home.vue';

 //EVENT 
import nuptialNotebookEventsCreate from "@/views/nuptial-notebook/Events/create.vue";
import nuptialNotebookEvents from "@/views/nuptial-notebook/Events/index.vue";
// Nuptial NoteBook
import nuptialNotebookBudgets from "@/views/nuptial-notebook/budgets/show.vue";
import CustomerNuptialNotebookDatesPlacesEdit from "@/views/nuptial-notebook/dates-places/edit.vue";
import nuptialNotebookDatesPlaces from "@/views/nuptial-notebook/dates-places/show.vue";
import CustomerNuptialNotebookGuestsCreate from "@/views/nuptial-notebook/guests/create.vue";
import CustomerNuptialNotebookGuestsEdit from "@/views/nuptial-notebook/guests/edit.vue";
import nuptialNotebookGuests from "@/views/nuptial-notebook/guests/show.vue";
import nuptialNotebookPlannings from "@/views/nuptial-notebook/plannings/show.vue";
import nuptialNotebookRestrictedCommittees from "@/views/nuptial-notebook/restricted-committees/show.vue";
import nuptialNotebookSummaries from "@/views/nuptial-notebook/summaries/show.vue";
import nuptialNotebookTheMarriedOnes from "@/views/nuptial-notebook/the-married-ones/show.vue";
// Mo
import DownloadAppView from '@/views/DownloadAppView.vue';
import nuptialNotebookJoyCardsCreate from "@/views/nuptial-notebook/joy-cards/create.vue";
import nuptialNotebookJoyCardsIndex from "@/views/nuptial-notebook/joy-cards/index.vue";
import nuptialNotebookJoyCardsRSVP from "@/views/nuptial-notebook/joy-cards/rsvp.vue";
import nuptialNotebookJoyCardsShow from "@/views/nuptial-notebook/joy-cards/show.vue";
import nuptialShopRequest from "@/views/nuptial-notebook/request/index.vue";
import nuptialShopFavoriteIndex from "@/views/nuptial-notebook/shop-favorites/index.vue";
import weddingCardShow from "@/views/nuptial-notebook/wedding-card/show.vue";
// PROVIDER
import CallbackPage from '@/views/wedding-provider/pages/callback';
import ProviderDetail from "@/views/wedding-provider/pages/provider-detail";
import ProviderIndex from "@/views/wedding-provider/pages/provider-index";
//Package
import WeddingProviderPackageIndex from '@/views/wedding-provider/pages/index-package.vue';
import WeddingProviderPackageDetail from '@/views/wedding-provider/pages/description-package.vue'
const routes = [{
        path: "/",
        name: "accueil",
        component: Home,
        meta: {
            layout: "mairie"
        }
    },
    {
        path: "/organisation-de-mariage-cote-d-ivoire",
        name: "bienvenue",
        component: Welcome,
        meta: {
          layout: "mairie"
        }
      },{
        path: "/telecharger-application-mobile-joursdejoie",
        name: "download-apps",
        component: DownloadAppView,
        meta: {
            layout: "no-title-page",
            requiredAuth: false
        }
      },
    //   {
    //     path: "/app-joursdejoie",
    //     name: "bienvenue",
    //     component: Welcome,
    //     meta: {
    //       layout: "mairie"
    //     }
    //   },
    {
        path: '/:pathMatch(.*)*',
        name: "notfound",
        component: NotFound,
        meta: {
            layout: "error404",
            requiredAuth: false
        }
    },
    {
        path: "/faq-organisation-de-mariage-cote-d-ivoire",
        name: "FAQ",
        component: FAQ,
        meta: {
            layout: "mairie"
        }
    },
    {
        path: "/cgu-organisation-de-mariage-cote-d-ivoire",
        name: "CGU",
        component: CGU,
        meta: {
            layout: "mairie"
        }
    },
    {
        path: "/privacy",
        name: "PRIVACY",
        component: PRIVACY,
        meta: {
            layout: "mairie"
        }
    },
    {
        path: "/infos-compte-de-mariage-cote-d-ivoire",
        name: "infos-compte",
        component: AccountInfoShow,
        meta: {
          layout: "mairie",
          requiredAuth: true
        }
    },
    {
        path: "/modification-infos-compte-de-mariage-cote-d-ivoire",
        name: "modification-infos-compte",
        component: AccountInfoEdit,
        meta: {
            layout: "mairie",
            requiredAuth: true
        }
    },
    {
        path:
            "/activation-compte-de-mariage-cote-d-ivoire/:emailVerificationToken?/:email?",
        name: "activation-compte",
        component: AccountInfoActivate,
        meta: {
            layout: "mairie",
            requiredAuth: false
        }
    },
    // {
    //     path: "/envoi-de-faire-part-digital-joycard/:idMessage/:idTransaction",
    //     name: "JoyCardSending",
    //     component: JoyCardSending,
    //     meta: {
    //         layout: "nuptial-notebook"
    //     }
    // },
    // Login
    {
        path: "/connexion",
        name: "connexion",
        component: Login,
        meta: {
            layout: "auth",
            requiredAuth: false
        }
    },
    {
        path: "/inscription",
        name: "inscription",
        component: Register,
        meta: {
            layout: "auth",
            requiredAuth: false
        }
    },
    {
        path: "/mot-de-passe-oublie",
        name: "mot-de-passe-oublie",
        component: passwordRecovery,
        meta: {
            layout: "auth",
            requiredAuth: false
        }
    },
    {
        path: "/restauration-du-mot-de-passe/:token/:email",
        name: "restauration-du-mot-de-passe",
        component: PasswordReset,
        meta: {
            layout: "auth",
            requiredAuth: false
        }
    },
    //contacts
    {
        path: "/contacts-de-l-entreprise-d-organisation-de-mariage-cote-d-ivoire",
        name: "contacts",
        component: Contacts,
        meta: {
            layout: "mairie"
        }
    },
    {
        path: "/joy-visibility",
        name: "joyVisibility",
        component: joyVisibility,
        meta: {
            layout: "joyvisibility",
            requiredAuth: false
        }
    },
    {
        path: "/onboarding",
        name: "OnBoarding",
        component: OnBoarding,
        meta: {
            layout: "joyvisibility",
            requiredAuth: false
        }
    },
    //MAIRIE
    {
        name: "mairies",
        path: "/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire",
        component: TownHallIndex,
        meta: {
            layout: "mairie"
        }
    },
    {
        name: "details-mairie",
        path: "/details-mairie-pour-organiser-son-mariage-cote-d-ivoire/:id",
        component: TownHallShow,
        meta: {
            layout: "mairie"
        }
    },
    {
        name: "dossier-mairie",
        path: "/dossier-mairie-pour-organiser-son-mariage-cote-d-ivoire/:id",
        component: TownHallFolder,
        meta: {
            layout: "mairie"
        }
    },
    //Event 
    {
        path: "/mes-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire",
        name: "mes-evenements",
        component: nuptialNotebookEvents,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/creer-des-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire",
        name: "creer-mes-evenements",
        component: nuptialNotebookEventsCreate,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    //nuptial-notebook
    
    {
        path: "/mes-evenements-pour-organiser-son-mariage-et-evenement-en-cote-d-ivoire",
        name: "mes-evenements",
        component: nuptialNotebookEvents,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances",
        name: "carnet-nuptial-fiances",
        component: nuptialNotebookTheMarriedOnes,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref",
        name: "carnet-nuptial-en-bref",
        component: nuptialNotebookSummaries,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/comite-restreint",
        name: "carnet-nuptial-comite-restreint",
        component: nuptialNotebookRestrictedCommittees,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/budgets",
        name: "carnet-nuptial-budgets",
        component: nuptialNotebookBudgets,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/planning-de-mariage",
        name: "carnet-nuptial-plannings",
        component: nuptialNotebookPlannings,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage",
        name: "carnet-nuptial-dates-et-lieux",
        component: nuptialNotebookDatesPlaces,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-dates-et-lieux-de-mariage/:eventId/:datePlaceId",
        name: "carnet-nuptial-modification-dates-et-lieux",
        component: CustomerNuptialNotebookDatesPlacesEdit,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage",
        name: "carnet-nuptial-invites",
        component: nuptialNotebookGuests,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/creation-invites-pour-le-mariage/:eventId",
        name: "carnet-nuptial-creation-invites",
        component: CustomerNuptialNotebookGuestsCreate,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-invites-pour-le-mariage/:eventGuestId",
        name: "carnet-nuptial-modification-invites",
        component: CustomerNuptialNotebookGuestsEdit,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },

    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage",
        name: "carnet-nuptial-carte-d-invitation",
        component: nuptialNotebookJoyCardsIndex,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage/:id",
        name: "carnet-nuptial-carte-d-invitation-detail",
        component: nuptialNotebookJoyCardsShow,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-favoris",
        name: "carnet-nuptial-boutiques-favorites",
        component: nuptialShopFavoriteIndex,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-de-mariage-contacte",
        name: "carnet-nuptial-boutiques-contacte",
        component: nuptialShopRequest,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/nouvelle-carte-d-invitation-mariage",
        name: "carnet-nuptial-nouvelle-carte-d-invitation",
        component: nuptialNotebookJoyCardsCreate,
        meta: {
            layout: "nuptial-notebook",
            requiredAuth: true
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/confirmation-de-presence/:eventGuestId",
        name: "carnet-nuptial-confirmation-de-presence",
        component: nuptialNotebookJoyCardsRSVP,
        meta: {
            layout: "mairie",
            requiredAuth: false
        }
    },
    {
        path: "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/:weddingCardId",
        name: "carnet-nuptial-apercu-carte-d-invitation",
        component: weddingCardShow,
        meta: {
            layout: "wedding-card",
            requiredAuth: false
        }
    },
    // PRESTATAIRES
    {
        path: '/annuaires-des-prestataires-de-services-mariage-en-cote-divoire',
        component: ProviderIndex,
        name: 'prestataires',
        meta: {
            layout: "provider",
            requiredAuth: false
        }
    },
    {
        path: '/annuaires-des-prestataires-de-services-mariage-en-cote-divoire/:service_type_id/:budgetId',
        component: ProviderIndex,
        name: 'budget-via-prestataires',
        meta: {
            layout: "provider",
            requiredAuth: false
        }
    },
    {
        name: 'providerDetail',
        path: '/prestataires-de-mariage-details/:id',
        component: ProviderDetail,
        meta: {
            layout: "provider",
            requiredAuth: false
        }
    },
    {
        path: '/callback',
        component: CallbackPage,
    },
    //NOUVEAU PACKAGE DES PRESTATAIRES
        {
        path: '/description-package-des-prestataires-de-services-en-cote-divoire/:id',
        component: WeddingProviderPackageDetail,
        name: 'description-packages',
        meta: {
            layout: "provider",
            requiredAuth: false
        }
    },
    {
        path: '/package-des-prestataires-de-services-mariage-en-cote-divoire',
        component: WeddingProviderPackageIndex,
        name: 'packages',
        meta: {
            layout: "provider",
            requiredAuth: false
        }
    },
    // {
    //     path: '/description-package-des-prestataires-de-services-mariage-en-cote-divoire',
    //     component: WeddingProviderPackageDetail,
    //     name: 'packages',
    //     meta: {
    //         layout: "provider",
    //         requiredAuth: false
    //     }
    // },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
      },
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiredAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next("/connexion");
    } else {
        next();
    }
});
export default router