<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="channel-choice-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Envoyez votre Message Via" gender="F" />
          </div>
          <div class="col-md-12">
            <div class="alert alert-info" v-if="remainingMessages == null">
              <div class="text-center">Veuillez s'il vous plaît choisir le canal d'envoi !</div>
            </div>
          </div>
          <div class="row" v-if="channels != null && customerChannels != null">
           
            
            <div class="col-md-4 text-center" v-for="(channel, key) in channels" :key="key">
              <div class="form-group">
                <label :for="'channel'+key">
                  <img :src="channel.image" style="width:70%;" alt srcset />
                  <br />
                  <br />
                  <input
                    @change="onChannelChange(channel)"
                    :value="channel"
                    type="radio"
                    name="channel"
                    :id="'channel'+key"
                  />&nbsp;
                  <label :for="'channel'+key" style="font-size:16px;">{{ channel.name }}</label>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="channels == null || customerChannels == null" class="col-md-12" >
            <div class="col-md-12 text-center">
              <moonloader/>
            </div>
            <!-- <p class="text-center">Chargement en cours ...</p> -->
          </div>
          <div class="col-md-12">
            <button
              class="primary-button pull-right"
              @click="goToMessageBuilder"
              
            >
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button>
            <!-- <button class="primary-button pull-right" v-else disabled>
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModeChoiceComponent",
  data(){
    return {
      base_url:'http://127.0.0.1:8000/',
      prefix:'api/',
      loading:false,
    }
  },
  mounted(){
    this.getModeCard();
  },
  methods:{
    getModeCard(){
      let app = this;
      app.loading=true;
      console.log('URL ',this.base_url+this.prefix+'getModeCard')
      let response = this.$http.get(this.base_url+this.prefix+'getModeCard').then((response) => {
        app.loading=false;
      }).catch ((e)=>{
        app.loading=false;
        console.log(e);
      })
    }
  }
};
</script>
<style></style>
