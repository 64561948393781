<template>
    <div class="form-group" v-if="name">
        <label class="control-label" v-if="label">
            {{ label }}
            <span v-show="isRequired" class="text-danger"> * </span>
        </label>
        <!-- <input
            :value="value" @input="$emit('input', $event.target.value)"
            class="form-control" :class="{' is-invalid' : errors.first(name) }"
            v-mask="mask" :data-vv-as="label" :placeholder="placeholder"
            :name="name" v-validate="rules" :readonly="(isReadonly) ? isReadonly : false"
        />
        <small class="invalid-feedback">{{ errors.first(name)}}</small> -->
        <Field :type="(type) ? type : 'text'"  :value="modelValue" class="form-control" @input="$emit('update:modelValue', $event.target.value)"  
               :name="label"  :rules="rules"   :readonly="(isReadonly) ? isReadonly : false"  :placeholder="placeholder" v-imask="maskIn"
        />
        <div style="color:red">
            <ErrorMessage   :name="label" />
        </div>
        <!-- <Field :type="(type) ? type : 'text'" :v-model="value" :name="name" v-slot="{ value,name ,rules}">
          
            <input   :value="value" 
                class="form-control"  :name="name"  :rules="rules"   :readonly="(isReadonly) ? isReadonly : false"
                :placeholder="placeholder" >
            <div style="color:red">
                <ErrorMessage   :name="name" />
            </div>
        </Field> -->
    </div>
</template>

<style scoped>
    ::-webkit-input-placeholder {
        font-style: italic;
    }
    :-moz-placeholder {
        font-style: italic;  
    }
    ::-moz-placeholder {
        font-style: italic;  
    }
    :-ms-input-placeholder {  
        font-style: italic; 
    }
</style>

<script>
import { ErrorMessage,Form, Field } from 'vee-validate'
import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { IMaskDirective } from 'vue-imask';
Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

    export default {
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        name: 'maskedInput',
        // inject: ['$validator'],
        props: ['label', 'name', 'modelValue', 'rules', 'isRequired', 'mask', 'placeholder', 'isReadonly'],
        data: function() {
            return {
            maskIn: {
                mask: this.mask,
                lazy: true
            },
            };
        },
        directives: {
            imask: IMaskDirective
        },  
    }
</script>
