<template>
    <div class="form-group" v-if="name">
        <label class="control-label">{{ label }} <span v-show="isRequired" class="text-danger">*</span></label>
        <!-- <select
            :value="value" @input="$emit('input', $event.target.value)"
            class="custom-select form-control" :class="{' is-invalid' : errors.first(name) }"
            v-validate="rules" :name="name" :data-vv-as="label"
        > -->

            
            <!-- <option :value="option.id" v-for="(option, key) in options" :key="key" v-else>{{ option.name }}</option> -->
        <!-- </select> -->
        <Field :name="name" as="select" class="custom-select form-control" :rules="rules" :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"  >
            <option value="">--</option>
            <option :value="option.id" v-for="(option, key) in options" :key="key">{{ option.name }}</option>
        </Field>
        <div style="color:red">
          <ErrorMessage   :name="name" />
        </div>   
        <!-- <small class="invalid-feedback">{{ errors.first(name) }}</small> -->
    </div>
</template>

<script>
import {  Field , ErrorMessage} from 'vee-validate';
    export default {
        components: {
            Field,
            ErrorMessage
        },
        name: 'selectInput',
        // inject: ['$validator'],
        props: ['label', 'name', 'modelValue', 'rules', 'isRequired', 'options', 'selectItem']
    }
</script>
