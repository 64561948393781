<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <!-- <button type="button" class="btn btn-primary" >
          Launch demo modal
      </button> -->
      <!-- Modal -->
      <div class="modal fade pt--100" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center" v-if="nextStep == 0">
                  <h5> Selectionnez vos destinataires</h5>
            </div>
            <div class="modal-body text-center" v-else>
                <h3>Votre nombre de {{message.channel.name}} est insuffisant pour envoyer ce message.</h3> 
                <p>Veuillez contacter notre service commercial au (+225) 74 50 16 02</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="ToBeContinue">fermer</button>
            </div>
          </div>
        </div>
      </div>
      <div class="target-choice-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Choisissez vos destinataires" gender="F" />
          </div>
        </div>
        <div class="row" style="position:relative;z-index:1;">
          <div
            :style="'position:absolute;z-index:-1;top:-50%;bottom:0;left:85%;right:0;background: url(/images/communication/wedding.png) no-repeat;background-size:150px;background-repeat:no-repeat;opacity:0.2;'"
          ></div>
          <div class="col-md-6">
            <div class="alert alert-info" v-if="targets.length > 0">
              <center v-if="peopleCount != null">Nombre total de destinataire: {{ peopleCount }}</center>
              <center v-else>Chargement en cours ...</center>
            </div>
            <div class="alert alert-info" v-else>
              <center>Aucune cible sélectionnée !</center>
            </div>
          </div>
          <div class="col-md-6">
            <div class="alert alert-danger" v-if="customerChannels != null">
              <center
                v-if="customerChannel != null"
              >Nombre de message restant: {{ customerChannel.pivot.remaining_messages }}</center>
              <center v-else>Chargement en cours ...</center>
            </div>
          </div>
          <div class="col-md-12" v-if="peopleCount != null && customerChannel != null">
            <div
              class="alert alert-danger"
              v-if="customerChannel.pivot.remaining_messages < ( Number(message.messageNumber) * tabsend.length )"
            >
              <center>
                Crédit de communication insuffisant. Cela pourra engendrer des frais supplementaires
                
              </center>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="message && message.channel && message.channel.id == 3 && peopleCount !== null"
          >
            <div class="alert alert-danger text-center">
              Le nombre total de message à envoyer est :
              <span
                class="badge badge-secondary"
              >{{ message.messageNumber + ' message(s)' + ' x ' + tabsend.length + ' invité(s) = ' + ( Number(message.messageNumber) * tabsend.length ) + ' message(s)' }}</span>
            </div>
          </div>
          <div
            class="col-md-12"
            style="padding-top:25px;padding-bottom:25px;"
            v-if="guests != null"
          >
            <multiselect
              :multiple="true"
              v-model="targets"
              :close-on-select="true"
              :group-select="true"
              placeholder="-- Veuillez selectionner la cible svp --"
              deselectGroupLabel="Cliquez pour tout deselectionner"
              select-group-label="Cliquez pour tout selectionner"
              selectedLabel="selectionné" 
              selectLabel="Cliquez pour selectionner" 
              deselectLabel="Cliquez pour supprimer"
              :options="guests"
              @select="getPeopleCount"
              group-label="name"
              group-values="groupe"
              label="name"
              track-by="id"
            ></multiselect>
          </div>
          <div class="col-md-12" v-if="targets.length > 0" >
              <ul class="nav nav-tabs" id="myTab" role="tablist" >
                <li v-for="(target,index) in targets" :key="index" @click="setCurrentKey(index,target.id,target.name)" class="nav-item">
                  <a class="nav-link" id="home-tab" :class=" index === 0 ? ' active' : ''" data-toggle="tab" :href="'#'+target.id" role="tab" aria-controls="home" aria-selected="true">{{target.name}}</a>
                </li>
              </ul>
              
              <div class="tab-content" id="myTabContent" >
                <br>
                <div v-for="(list,listIndex) in targetsContent" :key="listIndex" class="tab-pane fade pr-5 form-check" :class="currentKey === listIndex ? 'show active' : ''" :id="list.id" role="tabpanel" aria-labelledby="home-tab">
                  
                  <div v-if="list">
                    <input type="checkbox" @click="selectAll(list.name,false)" v-model="list.status" :name="list.name" :id="list.name"/> 
                    <label v-if="list.name" :for="list.name"> Tout selectionner </label>
                  </div>
                  <br>
                  <div v-for="(guestSelect,keyGuest) in guestSelects" :key="keyGuest"  class="form-check">
                    <div v-if="guestSelect.name === list.name">
                      <input type="checkbox"  v-model="tabsend" @click="deselectOne(guestSelect.name,guestSelect.pivot.id)"  :value="guestSelect.pivot.id" :id="guestSelect.pivot.id"/>
                      <label  :for="guestSelect.pivot.id"> {{guestSelect.pivot.last_name}} {{guestSelect.pivot.given_names}} </label>
                    </div>
                    
                  </div>
                </div>
              </div>
          </div>
          <div class="col-md-12" v-if="message">
            <button
              class="secondary-button pull-left"
              v-if="message.channel.id == 3"
              @click="returnToMessageBuilder"
            >
              <i class="fa fa-chevron-left" style="color:#fff;"></i> PRÉCEDENT
            </button>
            <button class="secondary-button pull-left" v-else @click="returnToDesignMaker">
              <i class="fa fa-chevron-left" style="color:#fff;"></i> PRÉCEDENT
            </button>
            <div
              v-if=" targets !== 'undefined' && targets.length > 0 && peopleCount != null && customerChannel != null"
            >
              <button
                class="primary-button pull-right"
                @click="goToMessageSummary"
              >
                SUIVANT
                <i class="fa fa-chevron-right" style="color:#fff;"></i>
              </button>
              
            </div>

            <button class="primary-button pull-right" v-else disabled>
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.target-choice-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em; left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted red;
}

/* hover style just for information */
label:hover:before {
  border: 2px solid #4778d9!important;
}


</style>

<script>
import {
    API_PREFIX,
    API_VERSION,
    COMMUNICATION,
    NUPTIAL_NOTEBOOK
} from "@/config";
import axios from "axios";
import { defineComponent } from 'vue';
import multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default defineComponent({
  name: "TargetChoiceComponent",
  components: {
    multiselect
  },
  props: ["message"],
  data: function() {
    return {
      guests:[
        {
            name: "Tout selectionner",
            groupe:[]
        }
      ],
      targets: [],
      targetsContent:[],
      peopleCount: 0,
      customerChannels: null,
      customerChannel: null,
      guestSelects:[],
      tabsend:[],
      tabGuest:[],
      verif:true,
      allSelected: false,
      currentKey: '',
      loading:false,
      nextStep: 0,
    };
  },
  computed: {
    // à chaque fois que le message change, cette fonction s'exécutera
    message: function() {

      if (this.message != null && this.message.channel != null) {
        this.getChannels();
      }
    }
  },
  mounted: function() {
    
   
    this.getPeoples();
  },
  methods: {
    ToBeContinue: function(){
      this.nextStep = 1
    },
    getPeoples: function() {
      let app = this;
      axios
        .get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "guests", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token
          }
        })
        .then(function(response) {
          app.guests[0].groupe = response.data;

        })
        .catch(function(error) {
          console.log(error);
        });
    },
    returnToDesignMaker() {
      this.$emit("return-to-design-maker");
    },
    returnToMessageBuilder() {
      this.$emit("return-to-message-builder");
    },
    goToMessageSummary() {
      this.message.targets = this.targets;
      this.message.peopleCount = this.peopleCount;
      this.message.tab = this.tabsend;
      this.message.customerChannel = this.customerChannel;
      this.$emit("go-to-message-summary", this.message,this.targets);
    },
    setCurrentKey(index,id,name){
      
      let app = this
      app.loading = true
      this.currentKey = index
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "get-single-guest/"+id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(function(response) {
              
              if (response.data.length > 0) {
                if (!app.tabGuest.includes(response.data[0].name)) { 
                    app.tabGuest.push(name)
                    response.data.forEach(element => {
                      var index = app.guestSelects.findIndex( s => s.pivot.id == element.pivot.id  )
                      
                      if (index === -1) {
                        app.guestSelects.push(element);
                      }
                    });
                    app.selectAll(response.data[0].name,true)
                } 
              }
            
           app.loading = false
        })
        .catch(function(error) {
          app.loading = false
          console.log(error);
        });
    },
    getPeopleCount() {
      this.loading = true
      
      this.targetsContent= this.targets
      let targetsIds = [];
      let targetsName = []
      this.peopleCount = null; 
      this.targets.forEach((item,index) => {
        this.setCurrentKey(0,item.id,item.name)
        targetsIds.push({
          guestId: item.id
        });
        targetsName.push(item.name)
      });
      for (let index = 0; index < this.tabGuest.length; index++) {
        if (!targetsName.includes(this.tabGuest[index])) {
          const indexChose = this.tabGuest.indexOf(this.tabGuest[index]);
          this.tabGuest.splice(indexChose, 1);
        }
      }
      if (this.targets.length != 0  ) {
      this.guestSelects.forEach(element1 => {
        const indexChose = this.tabsend.indexOf(element1.pivot.id);
          if (this.tabsend.includes(element1.pivot.id) && !targetsName.includes(element1.name)) {
              this.tabsend.splice(indexChose, 1);
          }
      });
      }else{
        this.tabsend =[]
      }
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests-people-count",
          targetsIds,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(response => {
         
          this.peopleCount = response.data;
          this.getChannels()
          this.loading = false
        })
        .catch(error => {
          // console.log(error.response.data);
          this.loading = false
        });
    },
    getChannels() {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(function(response) {
          app.customerChannels = response.data;
          
          if (app.message) {
            app.customerChannels.forEach(item => {
            if (item.id == app.message.channel.id) {
                app.customerChannel = item;
              }
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    deselectOne: function(name,id){
            const indexChose = this.tabsend.indexOf(id);
              this.targets.forEach(element => {
                  if (indexChose > -1 && element.name == name) {
                      element.status = false
                  } 
                   else if(element.name === name) {
                     var count = 0
                     this.guestSelects.forEach(element1 => {
                       
                       if (!this.tabsend.includes(element1.pivot.id) && element1.name === name ) {
                          count++
                       }
                     });
                     if (count <= 1) {
                       element.status = true
                     }

                  }
              });
              this.message.tab = this.tabsend;
         
    },
    selectAll: function(name,check) {
      
        this.targets.forEach(element => {
              if (element.name == name) {
                if (check === true) {
                  element.status = false
                }
                  
                  if (element.status == false || element.status == undefined) {
                    for (let index = 0; index < this.guestSelects.length; index++) {
                      
                        const indexChose = this.tabsend.indexOf(this.guestSelects[index].pivot.id);
                      if (indexChose == -1 && name === this.guestSelects[index].name) {
                        this.tabsend.push(this.guestSelects[index].pivot.id)
                      }
                    }
                    element.status = true
                  }else{
                    for (let index = 0; index < this.guestSelects.length; index++) {
                        const indexChose = this.tabsend.indexOf(this.guestSelects[index].pivot.id);
                      if (indexChose > -1 && name === this.guestSelects[index].name) {
                        this.tabsend.splice(indexChose, 1);
                      }
                    }
                  }
              }
        });
        
    }
  }
});
</script>