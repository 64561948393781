<template>
  <div class="container">
    <div class="row row-cols-3" v-if="packages!=null">
      <div  class="col-md-4" v-for="pp in packages">
        <CardPackageItem :pack = "pp"/>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import CardPackageItem from './card-package-item.vue';
export default {
  props: {
    packages: Array,
  },
  data: () => {
    return {};
  },
  mounted(){
    console.log("mounted ",this.packages);
  },
  components:{
    CardPackageItem 
  }
};
</script>
