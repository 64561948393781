<template>
  <div class="w-full">
    <div class="flex pt-20">
      <BannerPackage  :is-display-search-inputs="false" />
    </div>
    <main class="main" v-if="package != null">
      <div class="page-content mt-2">
        <div class="container">
          <div class="product-details-top">
            <div class="row">
              <div class="col-md-6">
                <div class="product-gallery product-gallery-vertical">
                  <div class="row">
                    <figure class="product-main-image p-3">
                      <img class="h-[25rem] w-full"
                        id="product-zoom"
                        :src="package.image"
                        alt="product image"
                      />
                    </figure>
                    <!-- End .product-main-image -->

                    <!-- End .product-image-gallery -->
                  </div>
                  <!-- End .row -->
                </div>
                <!-- End .product-gallery -->
              </div>
              <!-- End .col-md-6 -->

              <div class="col-md-6">
                <div class="product-details">
                  <h3 class="product-title">
                    {{ package.name }} |<span class="badge badge-light"> {{package.shop.provider.name}} </span>
                  </h3>
                  <!-- End .product-title -->

                  <div class="product-price mt-2">
                    <span class="badge badge-danger text-red text-size-att"
                      >{{ package.price }} FCFA</span
                    >
                  </div>
                  <!-- End .product-price -->

                  <div class="product-content mt-2">
                    <div class="text-size-att text-grey">Description</div>
                    <span style="padding-left: 10px">{{
                      package.description
                    }}</span>
                  </div>
                  <!-- End .product-content -->

                  <div class="d-flex">
                    <div class="py-0.5 text-base">
                      <span
                        class="border border-success text-green px-1 rounded-md p mr-1 text-wrap"
                      >
                        {{ package.event_type[0].name }}
                      </span>
                    </div>
                    <div class="py-0.5 text-base text-ellipsis">
                      <span
                        class="border border-info text-green px-1 rounded-md p mr-1 text-wrap"
                      >
                        {{ package.service_type.name }}
                      </span>
                    </div>
                  </div>

                  <div class="details-filter-row details-row-size mt-2">
                    <div class="text-size-att text-grey">Caractéristiques</div>
                    <!-- End .product-nav -->
                    <ul style="padding-left: 10px">
                      <li v-for="ff in package.features">-{{ ff.value }}</li>
                    </ul>
                  </div>
                  <!-- End .details-filter-row -->

                  <!-- End .product-details-footer -->
                </div>
                <div class="col-md-12">
                <div class=" mt-5">
                  <button
                    @click="onClick()"
                    class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
                  >
                    Contacter le prestataire
                  </button>
                </div>
              </div>
                <!-- End .product-details -->
              </div>
              <!-- End .col-md-6 -->
              
            </div>
            <!-- End .row -->
          </div>
          <!-- End .product-details-top -->

          <!-- End .product-details-tab -->

          <!-- End .owl-carousel -->
        </div>
        <!-- End .container -->
      </div>
      <!-- End .page-content -->
    </main>
    <div v-else><spinner /></div>
    <!-- End .main -->
  </div>
  <!-- End .page-wrapper -->
</template>
<script>
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";
import { mapActions, mapState } from "vuex";
import BannerPackage from "../components/banner-package.vue";

export default {
  data() {
    return {
      message: "Hello Vue.js!",
      package: null,
    };
  },
  computed: {
    // ...mapState('packages',{package:'package'})
        ...mapState("packages", { package: "package" }),

  },components:{
    BannerPackage
  },
  async beforeMount() {},
  async mounted() {
    await this.getPackage();

    console.log("Package ", this.package);
  },
  methods: {
        ...mapActions("packages", ["updatePackage"]),

    getPackage() {
      this.isLoading = true;
      let app = this;
      let id = this.$route.params.id;
      this.$http
        .get(
          PROVIDER.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "get-package-wedding-providers/" +
            id
        )
        .then((response) => {
          app.package = response.data.data;
          if (this.package.image != null || this.package.image.length > 0) {
            this.package.image =
              PROVIDER.DEV_URL + "storage" + this.package.image;
          } else {
            this.package.image = "../../assets/images/logo.png";
          }
          app.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    onClick() {
      this.$ua.trackEvent(
        "Provider",
        "Click",
        this.package.shop.provider.name,
        null
      );
      console.log("CONNECTED ? ", this.$store.getters.isLoggedIn);
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        this.$router.push("/prestataires-de-mariage-details/" + this.package.shop.id);
        this.updatePackage(this.package);
      }
    },
  },
};
</script>
<style scoped>
.text-size-att {
  font-size: 1.4rem;
}
</style>
