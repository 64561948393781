<template>
  <div class="py-2 flex flex-col w-full text-sm px-1">
    <button class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer" @click="toggleDisplay">
      <span class="text-left text-sm">Avis</span>&nbsp;
      <i :class="isDisplay ? 'fa-angle-up' : 'fa-angle-down'" class="text-gray-600 fa"></i>
      <!-- <font-awesome-icon class="text-gray-600" :icon="isDisplay ? 'fa-angle-up' : 'fa-angle-down'"></font-awesome-icon> -->
    </button>
    <div class="py-2 flex flex-col" v-show="isDisplay">
      <div class="flex justify-between items-center">
        <div class="flex" v-if="mark >= 0">
          <review-stars-component
              componentId="mark"
              :is-shown-mode="false"
              @on-mark-selected="onMarkSelected"
              :stars="getStars(mark)"
          ></review-stars-component>
          <span class="px-2">
            {{ `(${mark})` }}
          </span>
        </div>
        <div class="flex items-center" v-if="mark">
          <button class="px-1 py-1 text-red-800 text-xs" @click="onResetBaby">
            <!-- <font-awesome-icon icon="fa-close"></font-awesome-icon> -->
            <i  class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { COMPONENT_STAR_TYPES } from '@/views/wedding-provider/types'
export default {
  name: "FilterItemsReviewComponent",
  components: {
    // ReviewStarsComponent
  },
  data() {
    return {
      isDisplay: this.isOpen,
      mark: 0,
      stars: [],
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    mark(val) {
      this.$emit('review-filter-selected', Number(val));
      this.stars = this.getStars(val)
      return val;
    }
  },
  mounted() {
    this.stars = this.getStars(this.mark)
  },
  methods: {
    onMarkSelected(value) {
      const { mark } = value;
      this.mark = Number(mark);
      this.stars = this.getStars(mark)
    },
    toggleDisplay() {
      this.isDisplay = !this.isDisplay
    },
    onResetBaby() {
      this.mark = 0;
      this.stars = this.getStars(0)
    },
    onReviewFilterSelected() {
      if(this.mark) {
        this.$emit('review-filter-selected', Number(this.mark));
      } else {
        this.onResetBaby();
      }
    },
    getStars(mark) {
      const stars = [];
      let roundMark = 0;
      if (mark) {
        roundMark = Number(mark);
      }
      for (let i = 1; i <= 5; i++) {
        if (i <= roundMark) {
          stars.push({ type: COMPONENT_STAR_TYPES.FULL });
        } else {
          stars.push({ type: COMPONENT_STAR_TYPES.EMPTY });
        }
      }
      return stars;
    },
  }
}
</script>
