import { BASE_URL } from "@/views/wedding-provider/utils";
import axios from 'axios';

export async function getMediasAPI(params) {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-medias-details`, { params });
        
        return response.data;
    } catch (error) {
        console.log("===> getMediasAPI error", error)
    }
}