<template>
  <!-- <div class="d-flex justify-content-center container mt-5" v-if="pack!=undefined">
      <div class="card p-3 bg-white card-item">
        <div class="about-product text-center mt-2">
          <img :src="pack.image"  style="width:500px;height:200px;object-fit: cover;" class="rounded" />
          <div>
            <h4>{{ pack.name }}</h4>
            <h6 class="mt-0 text-black-50">{{ pack.shop.provider.name }}</h6>
          </div>
        </div>
        <div class="stats mt-2">
          <div class="d-flex justify-content-center p-price">
            <div class="button-60">{{ pack.event_type[0].name }}</div>
          </div>
          <div class="d-flex justify-content-center p-price mt-1">
            <div class="button-60" >{{ pack.service_type.name }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between total font-weight-bold mt-4">
          <span>Total</span><span>{{ pack.price }} FCFA</span>
        </div>
        <div>
          <div class="d-flex justify-content-end">
            <button @click="addToCart(pack)" class="col-12 btn btn-primary">Voir</button>
          </div>
        </div>
      </div>
    </div> -->
  <div class="relative bg-white shadow-lg rounded-lg h-fit mx-2 mt-5" v-if="pack!=null">
    <div class="shadow-lg">
      <img
        :class="{
          'rounded-t-lg mx-auto h-[15rem]': true,
          'w-full rounded-tl-lg rounded-bl-lg': true,
        }"
        class="h-full w-full object-cover"
        :src="pack.image"
      />
    </div>
    <div class="py-2 px-2" :class="{ 'flex flex-col w-2/3': isList }">
      <div class="pt-1 pb-0.5">
        <div class="font-semibold text-ellipsis text-capitalize text-center">
          {{ pack.name }}
          <div v-if="pack.shop.provider !=undefined">
            <span
              class="border border-green text-green px-3 rounded-md py-1 mr-4"
            >
              <i class="fa fa-shopping-bag text-warning"></i>
              {{ pack.shop.provider.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="py-0.5 text-xs text-center">
        <span
          class="border border-green text-green px-1 rounded-md p mr-1 text-wrap"
        >
          {{ pack.event_type[0].name }}
        </span>
      </div>
      <div class="py-0.5 text-xs text-ellipsis text-center">
        {{ pack.service_type.name }}
      </div>
      <div class="d-flex justify-content-between total font-weight-bold mt-1">
        <span>Prix</span><span>{{ pack.price }} FCFA</span>
      </div>
      <div class="py-0.5">
        <button
          @click="goDetail()"
          class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
        >
          Voir
        </button>
      </div>

      <div class="py-0.5">
        <button
          @click="onClick(pack.shop.id)"
          class="w-full bg-red-800 text-white py-2 rounded-lg hover:bg-red-900"
        >
          Contacter
        </button>
      </div>
    </div>
  </div>
  <spinner v-else></spinner>
</template>
<style>
.card-item {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* CSS */
.button-60 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 0.375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(0.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  /* -webkit-user-select: none; */
  /* touch-action: manipulation; */
  vertical-align: top;
  white-space: nowrap;
}

.button-60:active {
  border-color: #4a4a4a;
  outline: 0;
}

.button-60:focus {
  border-color: #485fc7;
  outline: 0;
}

.button-60:hover {
  border-color: #b5b5b5;
}

.button-60:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, 0.25) 0 0 0 0.125em;
}
</style>
<script>
import { PROVIDER } from "@/config";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: ["pack"],
  data() {
    return {};
  },
  computed: {
    ...mapState("packages", { package: "package" }),
  },
  mounted() {
    console.log("CardItemPackage ", this.pack);
    if (this.pack.image != null || this.pack.image.length > 0) {
      this.pack.image = PROVIDER.DEV_URL + "storage" + this.pack.image;
    } else {
      this.pack.image = "../../assets/images/logo.png";
    }
  },
  methods: {
    ...mapActions("packages", ["updatePackage"]),
    onClick(providerId) {
      this.$ua.trackEvent(
        "Provider",
        "Click",
        this.pack.shop.provider.name,
        null
      );
      console.log("CONNECTED ? ", this.$store.getters.isLoggedIn);
      if (this.$store.getters.isLoggedIn == false) {
        this.$router.push("/connexion");
      } else {
        this.$router.push("/prestataires-de-mariage-details/" + providerId);
        this.updatePackage(this.pack);
      }
    },
    goDetail() {
      this.$router.push(
        "/description-package-des-prestataires-de-services-en-cote-divoire/"+this.pack.id
      );
    },
  },
};
</script>
