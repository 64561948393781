<template>
    <div>
            <Bar />  
        <div class="container-fluid pr-5 pl-5" style="padding: 20px;">
            <big-title label="Boutiques Favoris" />
        
            <br />
      <!-- <p class="text-center">
        Les choses se précisent ! Comme son nom l’indique, la rubrique «
        Planning » vous aide à planifier <br />
        les actions importantes de votre mariage. Mettez à jour votre liste de
        tâches, plus rien ne vous échappera !
      </p> -->
      <p class="text-center">
        Bientôt le grand jour. La date de votre mariage est proche.<br /> 
        Ce merveilleux projet auquel vous rêvez depuis longtemps va finalement se réaliser. <br /> 
        Les mois précédents vous avez passé votre temps à réfléchir à son organisation et à sa planification. <br />
        Vous avez élaboré votre budget de mariage, dressé la liste de vos invités et vous leur avez même envoyé des cartons d’invitations. <br />
        Vous vous attendez à être entourés des personnes qui vous sont chères pour célébrer avec elles ce moment de bonheur. <br>
      </p> <br>

      <div class="text-center">
        <div class="collapse"  id="collapseP">
            <p >
                A quels prestataires avez-vous décidé de confiez la réalisation de votre mariage de rêve ? <br>
                Qui est votre Wedding/Event Planner ? Avec qui allez-vous commander vos robes et costumes de mariage ? <br> 
                Qui a confectionné les magnifiques alliances que vous porterez à vie ? Quel service traiteur égayera les papilles de vos convives ? <br>
                Qui exécutera le splendide gâteau de mariage auquel vous avez toujours rêvé… <br />
            </p> <br>
            <p  >
                Avec JOY SHOP de joursdejoie.com, la question ne se pose plus. <br />
                Parcourez JOY SHOP, notre annuaire des professionnels des évènements heureux et <br /> 
                sélectionner les prestataires qui répondront au mieux à vos attentes (qualité/prix).
                
            
            </p> <br>
            <p  >
                Mettez des cœurs sur vos prestataires favoris et vous verrez leurs boutiques s’afficher dans votre carnet nuptial. <br />
                Waouh ! JOY SHOP de joursdejoie.com c’est trop Top...
                
            
            </p>
        </div>
        
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseP"
          role="button"
          aria-expanded="false"
          aria-controls="collapseP"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
            <div class="" v-if="!isLoading" >
                
                <div class="row" v-if="favoritesObject.length > 0">
                    <div class="col-3" >
                        <div class="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"  :class="index == click ? 'active' : '' " :id="'list-'+object+'-list'" v-for="(object,index) in favoritesObject" :key="index" data-toggle="list" @click="click = index" :href="'#list-'+object" role="tab" :style=" index == click ? 'background-color: #b71c1c;border: #b71c1c;' : ' ' " :aria-controls="object">{{object}}</a>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade " :class="index == click ? ' show active' : '' " :id="'list-'+values" v-for="(values,index) in favoritesValues" role="tabpanel" :aria-labelledby="'list-'+values+'-list'">
                                <div class="row" >
                                    <div class="card" v-for="(value,index) in values"  style="width: 18rem;">
                                        <img class="card-img-top h-full w-full object-cover rounded-t-lg mx-auto h-[15rem]" :src="storage_url+value.path" alt="Image d'évènement">
                                        <div class="card-body">
                                            <h5 class="card-title"> {{ value.name }} </h5>
                                            <p class="card-text">{{value.short_description}}</p>
                                            <a :href="'/prestataires-de-mariage-details/'+value.id" class="btn btn-primary">Voir la boutique</a>
                                        </div> &nbsp;
                                    </div> &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="alert alert-primary text-center" role="alert">
                    Vous n'avez pas de boutique favoris
                </div>
            </div>
            <div v-else class=" container">
                <div class=" text-center">
                    <moonloader :size="'35px'"/>
                </div>
               
            </div>
            
        </div>
    </div>
</template>
<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import { STORAGE_BASE_URL } from "@/views/wedding-provider/utils";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        Bar,
    },
    data() {
        return {
            storage_url : STORAGE_BASE_URL,
            favoritesObject:[],
            favoritesValues:[],
            click:0,
            textFullDisplay: false,
        }
    },
    computed:{
        ...mapState('favorites', {
            isLoading: 'isLoading',
            favoritesListe: 'favoritesListe',
        }),
        
    },
    mounted() {
        this.favoritesDisplay()
    },
    methods: {
        ...mapActions('favorites', ['getFavorites']),

       async favoritesDisplay(){
           await this.getFavorites()
           this.favoritesObject = Object.keys(this.favoritesListe);
           this.favoritesValues = Object.values(this.favoritesListe);
        }
    },
}
</script>