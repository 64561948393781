<template>
  <div class="container p-3" style="padding: 25px;">
    <big-title label="INVITÉS" />
    <br />
    <div v-if="isLoading == false && guests != null">
      <br />
      <div class="guests-container p-3">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <sub-title :label="'INFORMATIONS DE L\'INVITÉ'" :gender="'F'" />
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <select-input
              label="Civilité"
              :name="'civility'"
              type="text"
              :rules="'required'"
              :isRequired="true"
              :options="civilities"
              v-model="eventGuest.civility"
            />
          </div>
          <div class="col-md-3">
            <text-input
              label="Nom"
              :name="'last_name'"
              type="text"
              :rules="'required'"
              :isRequired="true"
              v-model="eventGuest.last_name"
            />
          </div>
          <div class="col-md-6">
            <text-input
              label="Prénoms"
              :name="'given_names'"
              type="text"
              :rules="'required'"
              :isRequired="true"
              v-model="eventGuest.given_names"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label for="">Statut <span class="text-danger">*</span></label>
            <select
              class="custom-select form-control"
              
              v-validate="'required'"
              :data-vv-as="'statut'"
              v-model="eventGuest.guest_id"
              name="status"
            >
              <option value="">--</option>
              <option
                :value="item.id"
                v-for="(item, key) in guests"
                :key="key"
                >{{ item.name }}</option
              >
            </select>
            <!-- <small class="invalid-feedback">{{ errors.first("status") }}</small> -->
          </div>
          <div class="col-md-3">
            <text-input
              label="Email"
              :name="'email'"
              type="email"
              :rules="'email'"
              :isRequired="false"
              v-model="eventGuest.email"
            />
          </div>
          <div class="col-md-3">
            <masked-input
              label="Téléphone principal"
              :name="'phone_number_1'"
              type="text"
              :rules="'required|min:18|max:22'"
              :isRequired="true"
              v-model="eventGuest.phone_number_1"
              placeholder="(+225) 00 00 00 00"
              :mask="'+{(000)} 00 00 00 00 00'"
            />
          </div>
          <div class="col-md-3">
            <masked-input
              label="Téléphone secondaire"
              :name="'phone_number_2'"
              type="text"
              :rules="'min:18|max:22'"
              :isRequired="false"
              v-model="eventGuest.phone_number_2"
              placeholder="(+225) 00 00 00 00"
              :mask="'+{(000)} 00 00 00 00 00'"
            />
          </div>
        </div>
        <toggle-button
          v-model="eventGuest.has_confirmed"
          :width="180"
          :height="20"
          :font-size="12"
          :labels="{
            checked: 'Confirmée',
            unchecked: 'Pas encore confirmée',
          }"
          :color="{
            checked: '#B71C1C',
            unchecked: '#9E9E9E',
            disabled: '#CCCCCC',
          }"
        />
      </div>
      <br />
      <center>
        <button class="default-button" @click="goToBack">RETOUR</button>&nbsp;
        <button class="primary-button" @click="update">MODIFIER</button>&nbsp;
        <button class="secondary-button" @click="deleteGuest(eventGuest.id)">
          SUPPRIMER
        </button>
      </center>
    </div>
    <div v-else>
      <spinner size="medium" line-fg-color="#b71c1c" />
    </div>
  </div>
</template>

<script>
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from "axios";
import { useMeta } from 'vue-meta';
export default {
  name: "GuestEdit",
  setup () {
    useMeta({
      title:
        "Modification d’un invité - Carnet Nuptial - Invités - Établissez en toute quiétude, la liste des invités de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment faire sa liste d’invités à mon mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, combien d’invités à mon mariage, liste de cadeau, comment faire sa liste d’invités, agencement des invités dans la salle de réception, grand espace évènementiel, carnet nuptial, hôtesses, plan de table de mariage,",
        },
      ],
    })
  },
  data: function() {
    return {
      eventGuest: {
        id: this.$route.params.eventGuestId,
        event_id: null,
        civility: null,
        last_name: null,
        given_names: null,
        email: null,
        phone_number_1: null,
        phone_number_2: null,
        has_confirmed: false,
      },
      guests: null,
      isLoading: false,
      civilities: [
        {
          id: "Monsieur",
          name: "Monsieur",
        },
        {
          id: "Madame",
          name: "Madame",
        },
        {
          id: "Mademoiselle",
          name: "Mademoiselle",
        },
      ],
    };
  },
  mounted: function() {
    this.getEventGuest();
    this.getGuests();
  },
  methods: {
    getEventGuest: function() {
      this.isLoading = true;
      let eventGuestId = this.$route.params.eventGuestId;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests/events/" +
            eventGuestId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.eventGuest = {
            id: response.data.id,
            event_id: response.data.event_id,
            guest_id: response.data.guest_id,
            civility: response.data.civility,
            last_name: response.data.last_name,
            given_names: response.data.given_names,
            email: response.data.email,
            phone_number_1: response.data.phone_number_1,
            phone_number_2: response.data.phone_number_2,
            has_confirmed:
              Number(response.data.has_confirmed) == 1 ? true : false,
          };
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getGuests: function() {
      this.isLoading = true;
      axios
        .get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "guests", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.guests = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    update: function() {
      // this.$validator.validateAll().then((result) => {
      //   if (result) {
          this.isLoading = true;
          let eventGuestUpdated = {
            id: this.eventGuest.id,
            event_id: this.eventGuest.event_id,
            guest_id: this.eventGuest.guest_id,
            civility: this.eventGuest.civility,
            last_name: this.eventGuest.last_name,
            given_names: this.eventGuest.given_names,
            email: this.eventGuest.email,
            phone_number_1: this.eventGuest.phone_number_1,
            phone_number_2: this.eventGuest.phone_number_2,
            has_confirmed: this.eventGuest.has_confirmed == true ? 1 : 0,
          };
          axios
            .post(
              NUPTIAL_NOTEBOOK.DEV_URL +
                API_PREFIX +
                API_VERSION +
                "guests-events-update",
              eventGuestUpdated,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.isLoading = false;
              
              this.updatedPercentage(response.data.event.customer_id);
              this.archivated(response.data.event.customer_id);
              this.$router.push(
                "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
              );
              this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Informations ajouté avec succès !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
              })
            })
            .catch((error) => {
              console.log(error.response.data);
              this.isLoading = false;
              
              this.$createToast(
                  {
                  title: 'Attention !',
                  description: "Erreur lors de la modification des informations !"
                  },
                  {
                  position: 'top-center',
                  type: 'error',
                  timeout: 10000,
                  transition: 'bounce',
                  })
            });
      //   }
      // });
    },
    goToBack: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
      );
    },
    updatedPercentage(customerId) {
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "percentage/" +
            customerId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log("Error percentage", error);
        });
    },
    archivated: function(customerId) {
      var app = this;
      this.$store
        .dispatch("archiveAccount", customerId)
        .then((response) => {})
        .catch((error) => {});
    },
    deleteGuest(guestId) {
      this.$swal({
        title: "Confirmation",
        text: "Êtes-vous sûre de vouloir supprimer cet invité ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, je le souhaite",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.value) {
          axios
            .get(
              NUPTIAL_NOTEBOOK.DEV_URL +
                API_PREFIX +
                API_VERSION +
                "guests-events-delete/" +
                guestId,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Invité supprimé avec succès !"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    }
                )
              this.updatedPercentage(response.data.event.customer_id);
              this.archivated(response.data.event.customer_id);
              this.$router.push(
                "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.guests-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
.icon {
  color: #fff;
}
</style>
